export const ROUTE_PATHS = {
  COMMONS: {
    CREATE: "new",
    EDIT: ":id/:actionType",
  },
  ANNOUNCEMENTS: "/announcements",
  POP_UPS: "/pop-ups",
  BANNERS: "/banners",
  CROSS_SELL_GROUPS: "/cross-sell-groups",
  TABLES: "/tables",
  AREAS: "/areas",
};

export const ROUTE_ACTION_TYPES = {
  EDIT: "edit",
  DUPLICATE: "duplicate",
};
