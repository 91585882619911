export default {
  app: "restaurant-web",
  common: {
    loading: "Loading...",
    ellipsis: "...",
    slowLoad: {
      0: "Heating the wok...",
      1: "Chopping the garlic...",
      2: "Slicing the onions...",
      3: "Crying from the sliced onions...",
      4: "Preparing the seasoning...",
      5: "Cooking the rice...",
      6: "Baking the bread...",
      7: "Head chef says not good enough...",
    },
    empty: "Nothing here yet...",
    orBreak: "OR",
    search: {
      placeholder: "Search by name",
      pendingInput: "What are you looking for?",
      minimumCharacters: "Please enter {{minimum}} or more characters",
      searching: "Searching...",
    },
    comingSoon: "Coming soon",
    contactAccountManagerHeader: "Self service feature coming soon",
    contactAccountManagerDescription:
      "Please contact us at <a class='font-semibold' href='mailto:team@atlas.kitchen'>team@atlas.kitchen</a> to make changes.",
    required: "Required",
    optional: "Optional",
    notApplicable: "N/A",
    beta: "Beta",
    formValidationErrors: "There are some validation errors on the form",
    tableColumns: {
      duplicate: "",
      edit: "",
      view: "",
      select: "",
      remove: "",
      actions: "",
    },
    tableValues: {
      duplicate: "Duplicate",
      edit: "Edit",
      view: "View",
      select: "Select",
      remove: "Remove",
      cancel: "Cancel",
    },
    paginator: {
      label:
        "Showing <b>{{rangeStart}} to {{rangeEnd}}</b> of <b>{{total}}</b> results",
    },
    unauthorised: {
      title: "Unauthorised",
      description: "You do not have access to use this feature.",
    },
    disconnected: "Your internet connection is unstable or disconnected.",
    loanShark:
      "We couldn't process your last payment. Please update your payment method or process payment, otherwise you may lose access.",
    loanSharkBilling: "Go to billing",
    delete: "Delete",
    deleteConfirmation: {
      header: "Are you sure?",
      description:
        "Do you really want to delete {{objectToDeleteLabel}}? This action is not reversible.",
    },
    confirm: "Confirm",
    add: "Add",
    create: "Create",
    edit: "Edit",
    view: "View",
    update: "Update",
    remove: "Remove",
    cancel: "Cancel",
    save: "Save",
    select: "Select",
    close: "Close",
    change: "Change",
    charge: "Charge",
    continue: "Continue",
    duplicate: "Duplicate",
    exit: "Exit",
    all: "All",
    done: "Done",
    download: "Download",
    optionsCountSelected: "{{count}} selected",
    grabFoodCharacterLimit:
      "GrabFood has a character limit of {{limit}} for this field, which will automatically be truncated if exceeded.",
    routeNotFoundHeader: "Are you lost?",
    routeNotFoundContent:
      "The page you are looking for does not seem to exist.",
    genericErrorHeader: "Houston, we have a problem.",
    genericErrorContent:
      "An unexpected error has occurred. Please let our team know if this issue persists.",
    outdatedVersionErrorContent:
      "You are loading an outdated version of Atlas.",
    outdatedVersionErrorAction:
      "Please reload app to update to the latest version.",
    back: "Back",
    backHome: "Take me back home",
    actionsTitle: "Actions",
    unsavedChangesConfirmation: {
      title: "Are you sure you want to exit now?",
      body: "Looks like you haven't saved your changes. Leaving this screen now will not save your changes.",
      okText: "Leave now",
      cancelText: "Back to editing",
    },
    commonErrorMessage: "Something went wrong, please try again later.",
    yes: "Yes",
    no: "No",
    fulfilmentTypes: {
      all: "Delivery, pickup, & dine-in",
      delivery: "Delivery",
      pickup: "Pickup",
      dine_in: "Dine-in",
    },
    dayOfWeek: {
      0: "Sunday",
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
    },
    outlet: "Outlet",
    selectOutletPlaceholder: "Select an outlet",
    permanentlyDelete: "Permanently delete",
    tags: {
      guest: "Guest",
      registered: "Member",
      readOnly: "Read-only",
    },
    autoFill: "Auto-fill",
    autoFillDescription: "Try quickly filling the page with Auto-fill.",
    autoFillComplete: "Auto-fill complete!",
    promptLabel: "Prompt",
  },
  header: {
    openSidebarSr: "Open sidebar",
    hideSidebarSr: "Hide sidebar",
    showSidebarSr: "Show sidebar",
    exitPosSr: "Exit POS",
    reloadPosSr: "Reload POS",
    unpairPosSr: "Unpair primary POS",
  },
  merchantChangedModal: {
    title: "We detected a change in merchant. Please reload to continue.",
    reload: "Reload",
  },
  sidebar: {
    closeSidebarSr: "Close sidebar",
    merchantSelector: {
      header: "Merchant(s)",
      changeMerchant: "Change merchant",
      noMerchantsFound: "No merchant found",
      current: "Current",
      archived: "Archived",
    },
    form: {
      labels: {
        search: "Search...",
      },
    },
    selectedMerchant: {
      header: "Recent merchants",
    },
    outletSelector: {
      all: "All outlets",
      outletLabel: "Outlet: {{outlet}}",
      noOutletSelected: "No outlet selected",
      header: "Outlet(s)",
    },
  },
  navigation: {
    tags: {
      beta: "BETA",
      legacy: "LEGACY",
      new: "NEW",
      atlasOnly: "Atlas only",
    },
    operations: {
      label: "",
      pointOfSale: "Point of Sale",
      stations: "Stations",
      unpairPrimaryPos: "Unpair primary POS",
      reloadApp: "Reload app",
      fulfilment: {
        label: "Fulfilment",
        today: "Today's orders",
        preorder: "Upcoming orders",
      },
      stocks: "Stocks",
      orders: {
        label: "All orders",
      },
      reports: {
        label: "Reports",
      },
      salesReport: {
        label: "Sales reports",
      },
      zReport: {
        label: "Daily summary reports",
      },
      adjustmentReport: {
        label: "Adjustment reports",
      },
      logisticsReport: {
        label: "Logistics reports",
      },
      logisticsReportNew: {
        label: "Logistics reports",
      },
      payoutReport: {
        label: "Payout reports",
      },
      customers: {
        label: "Customers",
      },
      kitchenDisplaySystem: {
        label: "Kitchen Display System",
      },
      editPointOfSale: {
        label: "Edit Point of Sale layout",
      },
      insights: {
        label: "Insights",
      },
    },
    admin: {
      label: "Admin only",
      menuBuilder: {
        label: "Menu builder",
        menus: "Menus",
        sections: "Sections",
        products: "Products",
        modifierGroups: "Modifier groups",
        posLayouts: "POS layouts",
      },
      insights: {
        label: "Insights",
        sales: "Sales",
        products: "Products",
        promotions: "Promotions",
        customers: "Customers",
      },
      engagements: {
        label: "Marketing tools",
        promoCodes: "Promo codes",
        cashVouchers: "Cash vouchers",
        donations: "Donations",
        referrals: "Referrals",
        loyalty: "Loyalty",
        emailDrip: "Email drip",
        announcements: "Announcements",
        popUps: "Pop-ups",
        banners: "Banners",
        crossSellGroups: "Cross-sell",
      },
      couriers: {
        label: "In-house couriers",
      },
      billing: "Billing",
      connect: {
        label: "Connect",
        apps: "Atlas apps",
      },
      settings: {
        label: "Settings",
        merchant: "Merchant",
        kitchenDisplays: "Kitchen displays",
        scanToOrderChannelLinks: "Scan to order",
        outlets: "Outlets",
        brands: "Brands",
        channels: "Channels",
        channelLinks: "Channel links",
        tablesAndAreas: "Tables and areas",
        paymentMethods: "Payment processors",
        paymentProcessors: "Payment processors",
        paymentTypes: "Payment types",
        adjustmentReasons: "Void reasons",
        deliveryFeeConfigs: "Delivery fee",
        surcharges: "Surcharges",
        leadTimeConditions: "Product lead time",
        team: "Team members",
        aggregationPromotionMapper: "Aggregator discounts",
        adyenStores: "Adyen stores",
      },
      outletSettings: {
        label: "Outlet settings",
      },
      atlasAdmin: {
        label: "Atlas admin",
        features: "Feature flips",
        integrationLogs: "Aggregator logs",
        paymentDiscrepancies: "Payment discrepancies",
        logisticsMonitoring: "Logistics monitoring",
      },
    },
  },
  wysiwyg: {
    bold: "Bold text",
    italic: "Italic text",
    strike: "Strike through text",
    link: "Hyperlink",
    lineBreak: "Line break",
    undo: "Undo",
    redo: "Redo",
    label: {
      hyperlink: "Hyperlink",
    },
  },
  outletStatusSelector: {
    label: "Busy mode:",
    status: {
      open: "Open",
      busy: "Busy",
      closed: "Closed",
    },
    allChannels: "all channels",
    mixedStatus: "mixed status",
    leadTime: {
      15: "+15 mins",
      30: "+30 mins",
      45: "+45 mins",
      60: "+1 hr",
      75: "+1 hr 15 mins",
      90: "+1 hr 30 mins",
      120: "+2 hrs",
    },
    modal: {
      header: "Set outlet status for sales channels",
      body: "Set open/busy/closed states for the below channels at <strong>{{outlet}}</strong>.",
      buttons: {
        open: "Open all channels",
        busy: "Set all to busy",
        closed: "Close all channels",
      },
      success: {
        update: "Channel status updated successfully",
        updateAll: "Status of all channels updated successfully",
        updateAllAutoAdjusted:
          "Status of all channels updated successfully<br/><br/>PS: GrabFood busy timing was rounded to the nearest available option.",
      },
    },
    table: {
      columns: {
        channel: "Sales channel",
        status: "Status",
      },
    },
  },
  profileDropdown: {
    options: {
      profile: "Profile",
      reloadApp: "Reload app",
      logout: "Log out",
    },
    openUserMenuSr: "Open user menu",
  },
  login: {
    form: {
      header: "Merchant login",
      subheader:
        "The most forward-looking restaurants use Atlas to start, run and grow their restaurant online and offline.",
      placeholders: {
        email: "e.g. john@doe.com",
        password: "e.g. password",
      },
      label: {
        email: "Email",
        password: "Password",
      },
      submit: "Log in",
      forgotPassword: "I forgot my password",
    },
  },
  forgotPassword: {
    header: "Reset your password",
    subheader:
      "It’s okay, we all forget sometimes.<br/>Enter your email below to reset your password.",
    form: {
      placeholders: {
        email: "e.g. john@doe.com",
      },
      label: {
        email: "Email",
      },
      resetEmailInstructions:
        "We've sent you an email with instructions on how to reset your password.",
      forgotPasswordButton: "Reset password",
      forgotPasswordSubmitted: "Request successful",
      login: "Go back to login",
    },
    error: {
      emailRequired: "Email is required",
      invalidEmail: "Invalid email address",
    },
  },
  resetPassword: {
    header: "Choose a new password",
    subheader: "Enter a new password below and keep it safe.",
    form: {
      placeholders: {
        password: "Create new password",
        passwordConfirmation: "Confirm your password",
      },
      label: {
        password: "Password",
        passwordConfirmation: "Password confirmation",
      },
      changePassword: "Change password",
    },
    success: {
      header: "Your password reset was successful",
      subheader:
        "You can now <a class='text-atlas-pink' href='/login'>proceed to log in</a> with your new credentials.",
    },
    error: {
      passwordRequired: "Password is required",
      invalidPassword:
        "Password must contain a minimum of eight characters with a combination of numbers and letters",
      confirmPasswordRequired: "Password confirmation is required",
      passwordsNotMatch: "Passwords do not match",
    },
  },
  account: {
    header: "Account",
    form: {
      header: "Profile",
      labels: {
        name: "Name",
        username: "Username",
        email: "Email",
        password: "Password",
        passwordConfirmation: "Password confirmation",
      },
      subtitles: {
        password:
          "Must be at least 8 characters, and contain an alphabet, and a number",
      },
      success: {
        update: "Profile updated successfully",
      },
    },
  },
  features: {
    header: "Feature flips",
    restrictedAccess: "This dashboard is only accessible by the Atlas team.",
    categories: {
      null: "Uncategorised",
      paid_feature: "Paid features",
      merchant_config: "Merchant configuration",
      custom_request: "Custom requests",
      internal: "Internal",
    },
    tableColumns: {
      key: "Feature name/description",
      enabled: "Enabled?",
      merchantEnabled: "Enabled on merchant-level?",
      globalEnabled: "Enabled on global-level?",
    },
    success: {
      update: "Feature updated successfully",
    },
  },
  pointOfSale: {
    disabledMessage: {
      title: "Manage both online and offline sales under one roof",
      description:
        "Receive your dine-in, takeaway and delivery orders all under one platform. Craft world-class experiences for your customers across your online and offline channels.<br><br>Speak to your account manager or email <strong><a href='mailto:team@atlas.kitchen' target='_blank' rel='noreferrer'>team@atlas.kitchen</a></strong> to enable Atlas Point Of Sale for your store.",
    },
    pageHeader: "Point of Sale",
    outletsTitle: "Select an outlet to continue",
    selectOutlet: "Enter POS",
    login: {
      header: "Enter your 6-digit pin to enter POS",
    },
    header: {
      noLayout: "No layout found",
      exitEditLayout: "Exit layout manager",
    },
    logoutCountdown: {
      header: "Automatic log out in...",
      logout: "Log out now",
      stop: "Continue using",
    },
    logout: {
      unsentItemsWarning:
        "You have unsent items.<br/>Are you sure you want to logout?",
      confirm: "Confirm",
      cancel: "Cancel",
    },
    search: {
      placeholder: "Search for an item or an order ID",
      noResults: "No results matching your search.<br/>Try something else?",
      listItem: {
        title: {
          order: "Order #{{ identifier }}",
          cart: "Order #{{ identifier }}",
          table: ", {{tableName}}",
        },
        subtitle: {
          order: "Paid for at {{ timestamp }}",
          cart: "Unpaid order with notes: [{{notes}}]",
        },
        buttonLabel: {
          order: "View",
          cart: "Load into cart",
        },
      },
    },
    history: {
      title: "History",
      noResults: "No orders yet.",
      loadMore: "Load more orders",
      noMore: "No more orders.",
    },
    actions: {
      pairSecondaryDisplay: "Pair with customer display",
      setSecondaryDisplay: "Set as customer display",
      connectPosPrinter: "Connect to a printer",
      connectDifferentPosPrinter: "Connect to a different printer",
      noPrinterConnected: "No printer connected",
      disconnectPrinter: "Disconnect {{printerName}}",
      swapToCashierMode: "Swap to cashier mode",
      swapToPrintOnlyMode: "Swap to print-only mode",
      openCashRegister: "Open cash drawer",
      pinLogout: "Log out from {{pinLoginName}}",
      exit: "Exit POS mode",
    },
    reload: {
      label: "Reload",
      update: "Updates available",
      tapToReload: "Tap to reload",
      confirmation:
        "There is a new layout configured for this device. Would you like to update your layout?",
      confirm: "Yes, please continue",
      cancel: "Cancel update",
    },
    pair: {
      close: "Close",
      generateNewKey: "Generating new pair key...",
      secondsRemaining: "{{ remainingTime }} seconds left",
    },
    connectPrinter: {
      header: "Connect printer",
      disconnectHeader: "Disconnect {{printerName}}?",
      selectPrinter: "Select a printer",
      disconnect: "Disconnect",
      success: "Connected to printer successfully",
      disconnectSuccess: "Printer disconnected successfully",
    },
    merging: {
      tab: "Select orders to merge into #{{tabId}}",
      mergeCtaZero: "No orders selected yet",
      mergeCta: "Merge {{count}} order",
      mergeCta_plural: "Merge {{count}} orders",
      confirmationMessage:
        "Do you want to merge order {{mergedIds}} into order #{{mergingId}}",
      successMessage: "Orders were merged successfully",
    },
    mergeTableOrders: {
      title: "Which order should we merge into?",
      label: "Select order to merge into",
      formattedOption:
        "Order #{{cartId}} ({{name}}, {{paxDisplay}}, {{itemsCountDisplay}})",
      noPaxSet: "no pax set",
      itemsCountDisplay: "{{count}} item in cart",
      itemsCountDisplay_plural: "{{count}} items in cart",
      buttons: {
        merge: "Merge all orders into #{{mergingId}}",
      },
    },
    posChangeTableConfirmationModal: {
      title: "Move order to {{targetTableName}}?",
      body: "Please confirm if you would like to move order <strong>#{{orderId}}</strong> from <strong>{{sourceTableName}}</strong> to <strong>{{targetTableName}}</strong>.",
      okButtonText: "Confirm move",
      successMessage: "Table was changed successfully",
    },
    posMergeCartsConfirmationModal: {
      title: "Merge or move orders without merging?",
      body: "Would you like to merge order <strong>{{cartIds}}</strong> at <strong>{{sourceTableName}}</strong> into order <strong>#{{mergedIds}}</strong> at <strong>{{targetTableName}}</strong> or keep them as separate orders on <strong>{{targetTableName}}</strong>?",
      okButtonText: "Merge orders",
      moveButtonText: "Move without merging",
    },
    posMoveMultipleCartsConfirmationModal: {
      title: "Which order should be moved?",
      body: "Please select orders to move from <strong>{{sourceTableName}}</strong> to <strong>{{targetTableName}}</strong>.",
      form: {
        labels: {
          cartIds: "Select orders",
        },
        values: {
          all: "All orders",
          cartId: "#{{cartId}}",
          pax: "{{count}} pax",
          paxAdults: "{{count}} adult",
          paxAdults_plural: "{{count}} adults",
          paxKids: "{{count}} child",
          paxKids_plural: "{{count}} children",
          items: "{{count}} item",
          items_plural: "{{count}} items",
        },
      },
      okButtonText: "Confirm move",
      successMessage: "Orders were moved successfully",
    },
    changeTableConfirmationModal: {
      title:
        "Do you want to change assigned table from {{sourceTableName}} to {{targetTableName}}",
      successMessage: "Table was changed successfully",
    },
    posPaymentIntentModal: {
      pending: "Payment is in progress",
      success: "Payment successful",
      error: "Error occurred while initiating payment",
      cancel_error: "Error occurred while cancelling payment",
      timeout: "Network timeout",
      card_expired: "Card expired",
      declined: "Payment was declined",
      not_supported: "Payment type not supported",
      not_enough_balance: "Insufficient balance",
      busy: "Payment terminal is busy",
      cancelled_by_issuer: "Payment was cancelled by the bank/issuer",
      cancelled: "Payment was cancelled",
      okay: "Okay",
      cancel: "Cancel",
      forceCheckoutTitle:
        "Warning: Ensure that payment is successfully authorised on the terminal",
      forceCheckout: "Force checkout",
      forceCheckoutCancel: "Resume charge on terminal",
      forceCheckoutConfirm: "I understand, force checkout",
      subtitles: {
        pending:
          "Please proceed to pay or cancel this transaction on your linked payment terminal",
        forceCheckout:
          "Refer to sample screens above for successful payments. Your payment will not be captured if the terminal does not show as approved.",
        success: "Your order is currently being processed.",
        error:
          "Please try again. If the problem persists, please restart your payment terminal and try again. If that still doesn't work, please contact support.",
        cancel_error:
          "There was an error trying to cancel the payment. If payment was successful, please contact support.",
        timeout:
          "The payment service took too long to respond, please check your network or contact support if the problem persists.",
        card_expired: "Please try a different payment method.",
        declined: "Kindly check with the bank/issuer.",
        not_supported: "Please try a different payment method.",
        not_enough_balance: "Please try a different payment method.",
        busy: "Please try again after the current transaction is completed.",
        cancelled_by_issuer: "",
        cancelled: "",
      },
    },
    notes: {
      title: "Order notes for order #{{orderNumber}}",
      label: "Modify order notes",
      placeholder: "e.g. Celebrating their 30th wedding anniversary",
      buttons: {
        save: "Save order notes",
        clear: "Clear order notes",
      },
    },
    customer: {
      title: "Assign customer details",
      titleNewCart: "Create a new order",
      labels: {
        userId: "Customer",
        pax: "Number of pax",
        paxAdults: "Number of adults",
        paxKids: "Number of children",
        paxNotSet: "Not set",
        aiGeneratedNotes: "Customer notes",
      },
      placeholders: {
        userId: "Search name, email, or phone number",
      },
      helpText: {
        userId: "First time customer?",
        createCustomer: "Create a new profile",
        editUserId: "Details don't look right?",
        editCustomer: "Edit customer details",
        customerNotes: "Edit customer details",
      },
      errors: {
        paxAdults:
          "Number of adults cannot be empty if number of children is set",
        paxKids:
          "Number of children cannot be empty if number of adults is set",
      },
      customerInsights: "Customer insights",
      generatingCustomerInsights: "Generating insights...",
      generateCustomerInsights: "Generate",
      save: "Save customer details",
      saveNewCart: "Create order",
      success: "Customer details saved successfully",
    },
    fulfilment: {
      title: "Delivery details",
      takeawayTitle: "Takeaway details",
      labels: {
        servingDate: "Serving date",
        timeslotId: "Serving time",
        addressId: "Delivery address",
        isCutleryRequired: "Include cutlery?",
        deliveryFee: "Delivery fee",
        autoCalculateDeliveryFee: "Auto-calculate fees",
      },
      placeholders: {
        timeslotId: "Select a time slot",
      },
      helpTexts: {
        pastServingDate: "Note: Selected date is in the past.",
      },
      errors: {
        noAddressId: "Please select an address first",
      },
      buttons: {
        save: "Save delivery details",
        takeawaySave: "Save takeaway details",
      },
      success: "Fulfilment details saved successfully",
    },
    openItem: {
      title: {
        add: "Add open item",
        update: "Edit open item",
      },
      labels: {
        notes: "Item description",
        unitPriceFractionalOverride: "Item price",
        quantity: "Quantity",
      },
    },
    promoCode: {
      title: "Apply promo code",
      hint: "Enter promo code",
      buttons: {
        apply: "Apply",
      },
    },
    qrScanner: {
      title: "Scan promo code QR",
      buttons: {
        cancel: "Cancel scan",
      },
    },
    cashVouchers: {
      title: "Cash vouchers ({{count}} applied) - {{price}}",
      searchTitle: "Select the type of cash voucher to search for",
      listItem: "{{code}} - {{price}}",
      listItem_plural: "{{count}}x {{code}} - {{price}}",
      type: {
        unique: "Unique",
        nonUnique: "Non-unique",
      },
      enterCode: "Enter cash voucher code",
      enterReference: "Enter cash voucher reference",
      quantity: "Number of vouchers used",
      selectFromDropdown: "Select cash voucher",
      dropdownOption: "{{code}} ({{price}}) - {{label}}",
      buttons: {
        applyAnother: "Apply another cash voucher",
        apply: "Apply",
      },
    },
    openDiscount: {
      title: "Add open discount",
      form: {
        labels: {
          valueType: "Discount value type",
          discountValue: "Discount value",
          discountReason: "Discount reason",
        },
        values: {
          valueType: {
            fractional: "Fixed amount",
            percent: "Percentage",
          },
        },
        errors: {
          maxValue: "Value cannot be more than {{maxValue}}",
        },
      },
    },
    void: {
      title: "Void Order #{{cartId}}",
      actions: {
        void: "Void order",
      },
      form: {
        labels: {
          adjustmentReasonId: "Select reason for voiding",
        },
        error: {
          invalidAdjustmentReason: "Invalid void reason",
        },
        success: "Order voided successfully",
      },
    },
    cart: {
      archivedNotice: "Order #{{cartId}} is already archived",
      notes: "Order notes",
      customer: "Assign customer",
      addOpenItem: "Add open item",
      applyPromoCode: "Apply promo code",
      applyCashVoucher: "Apply cash voucher",
      addOpenDiscount: "Add open discount",
      editOpenDiscount: "Edit open discount",
      merge: "Merge orders",
      mergeTableOrders: "Merge all orders on this table",
      split: "Split order",
      void: "Void order",
      remove: "Remove order",
      removeModalTitle: "Are you sure you want to remove order #{{cartId}}?",
      cannotRemoveModalTitle:
        "Order cannot be removed because items have already been sent to kitchen. You may want to void the order instead.",
      tab: "#{{tabId}}",
      newOrderButton: {
        default: "New order",
        takeaway: "New takeaway order",
        delivery: "New delivery order",
        dineIn: "New dine-in order",
      },
      openOrders: "Orders",
      noOpenOrders: "No open orders",
      tableOrders: "{{tableName}} orders",
      paxNoBrackets: "{{pax}} pax",
      pax: "({{pax}} pax)",
      paxDisplay: "({{display}})",
      paxAdultsAndKids:
        '$t(pointOfSale.cart.paxAdults, { "count": {{adults}} }), $t(pointOfSale.cart.paxKids, { "count": {{kids}} })',
      paxAdults: "{{count}} adult",
      paxAdults_plural: "{{count}} adults",
      paxKids: "{{count}} child",
      paxKids_plural: "{{count}} children",
      inconsistentDataError: "Network issues detected. Please reload the app.",
      sortCartButton: {
        detailedView: "Detailed View",
        simpleView: "Simple View",
        sortBy: "Sort by",
        oldestFirst: "Oldest first",
        newestFirst: "Newest first",
        spendLeastMost: "Spend: Least - Most",
        spendMostLeast: "Spend: Most - Least",
      },
      fulfilmentInfo: {
        asapTimeslot: "ASAP",
        emptyCustomer: "Please select a customer",
        emptyCustomerMobileNumber: "Please enter customer phone",
        emptyTimeslotAndAddress: "Please enter serving time slot and address",
        emptyTimeslot: "Please enter serving time slot",
        emptyAddress: "Please enter delivery address",
      },
      cartItem: {
        soldOutLabel: " (sold out)",
      },
      paymentBreakdown: {
        subtotal: "Subtotal",
        excludingCartItemsDiscount: "(excl. item discount)",
        deliveryFee: "Delivery fee",
        surcharge: "Surcharge",
        cartItemsDiscount: "Item discount",
        discount: "Discount",
        discountWithReason: "Discount ({{reason}})",
        promotion: "Discount ({{promoCode}})",
        donation: "Donations",
        serviceCharge: "Service charge",
        tax: "Tax",
        cashVouchers: "Cash vouchers ({{count}} applied)",
        total: "Total",
      },
      payBy: {
        default: "Pay by",
        credit_card: "Credit card",
        cash: "Cash",
        vouchers: "Vouchers",
        pay_now: "PayNow",
        grab_pay: "Grab Pay",
        fave_pay: "Fave Pay",
      },
      paymentTypes: {
        header: {
          terminal: "Terminal",
          manual: "Manual",
        },
        credit_card: "Credit card",
        cash: "Cash",
        vouchers: "Vouchers",
        pay_now: "PayNow",
        visa: "Visa",
        master: "Mastercard",
        amex: "Amex",
        grab_pay: "Grab Pay",
        fave_pay: "Fave Pay",
        voucherOrDiscount: "Cash voucher or discount redemption",
      },
      chargeCustomer: {
        label: "Charge {{totalPrice}}",
      },
      forceCheckout: {
        label: "Force checkout",
        confirmationModal: {
          title: "IMPORTANT: CHECK YOUR PAYMENT TERMINAL",
          body: 'Make sure the payment terminal has approved the payment before selecting "OK".<br/><br/>If the payment is not approved, select "Cancel" and try again.',
          confirm: "OK",
          cancel: "Cancel",
        },
      },
      confirmAndPrint: {
        label: "Confirm and print order",
        triggered: "Print triggered successfully",
      },
      noConnectedPrinterError:
        "No printer connected. Please connect to a printer first.",
      printDynamicQrCode: {
        label: "Print scan to order QR",
        triggered: "Print QR triggered successfully",
      },
      closeVoidOrder: {
        label: "Close void order",
      },
      notesSaved: "Notes saved successfully",
      modifyItemModal: {
        title: "What would you like to do with<br/><strong>{{item}}</strong>?",
        actions: {
          fire: "Fire",
          hold: "Hold",
          fireHeld: "Fire",
          edit: "Edit",
          moveToTable: "Move to table",
          orderAgain: "Order again",
          applyItemDiscount: "Apply item discount",
          updateItemDiscount: "Update or remove item discount",
          reprint: "Reprint fire item docket",
          reprintVoid: "Reprint void item docket",
          void: "Void",
          cancelVoid: "Cancel void",
          remove: "Remove",
        },
      },
      applyItemDiscountModal: {
        title: "Apply item discount",
        form: {
          labels: {
            valueType: "Discount value type",
            value: "Discount value",
            reason: "Reason for discount",
          },
          values: {
            valueType: {
              fractional: "Fixed amount",
              percent: "Percentage",
            },
          },
          errors: {
            maxValue: "Value cannot be more than {{maxValue}}",
          },
          apply: "Apply",
        },
      },
      table: {
        noTable: "No table",
        dineIn: "Dine in",
        takeaway: "Takeaway",
        delivery: "Delivery",
        noOrders: "No orders yet",
        notFired: "Not fired",
        tableModal: {
          dineInTitle: "Order #{{orderId}} is a dine-in order",
          pickupTitle: "Order #{{orderId}} is a takeaway order",
          deliveryTitle: "Order #{{orderId}} is a delivery order",
          unassignedTableTitle:
            "Order #{{orderId}} isn't currently assigned to a table",
          assignedTableTitle:
            "Order #{{orderId}} is currently assigned to {{tableName}}",
          newOrder: {
            dineInTitle: "New order is a dine-in order",
            pickupTitle: "New order is a takeaway order",
            deliveryTitle: "New order is a delivery order",
            unassignedTableTitle:
              "New order isn't currently assigned to a table",
            assignedTableTitle:
              "New order is currently assigned to {{tableName}}",
          },
          tableLabel: "Select table to assign order",
          tablePlaceholder: "Please select a table",
          cartId: "#{{cartId}}",
          saveButton: "Update table assignment",
          changeToTakeawayButton: "Change to a takeaway order",
          changeToDeliveryButton: "Change to a delivery order",
          changeToDineInButton: "Change to a dine-in order",
          removeAssignedTableButton: "Remove assigned table",
          saveTableSuccessMessage: "Table saved successfully",
        },
      },
      splitPayment: {
        paidAmount: "({{amount}} already paid)",
        buttonLabel: "Split bill",
        splitTypeActions: {
          evenly: "Split evenly",
          manually: "Split manually",
          byItem: "Split by item",
        },
        modal: {
          title: {
            evenly: "Split your {{amount}} charge evenly",
            manually: "Split your {{amount}} charge manually",
            byItem: "Split your {{amount}} charge by item",
            charge: "Charge your {{amount}} bill",
          },
          form: {
            numberOfBillsLabel: "Number of bills to split",
            numberOfBillsPlaceholder: "Enter number of bills here",
            amountLabel: "Amount",
            amountPlaceholder: "Enter amount",
            paymentMethodLabel: "Payment method",
            paymentMethodPlaceholder: "Select payment method",
            chargeButtonLabel: "Charge {{amount}}",
            changeAmountButtonLabel: "Change {{amount}}",
            invalidTotalAmountMessage:
              "Charged amounts will need to add up to {{amount}} to proceed with charging",
            billLabel: "Bill {{label}}",
            splitItems: "Split items",
            targetBillModal: {
              title: "Which bill should these items go to?",
              targetBillPlaceholder: "Select a bill",
              createANewBill: "Create a new bill",
              okButton: "Confirm split",
            },
          },
          successfullyCharge: "Successfully charged",
          actions: {
            toCharge: "Proceed to charge",
            backToBillSplit: "Back to bill split",
          },

          confirmBillSplitModal: {
            title: "Confirm bill split?",
            content:
              "Split bills cannot be reversed once the first payment is made. Please make sure the split bill is accurate before continuing: ",
            billItem: "Bill {{billNumber}} - {{amount}}",
            okButton: "Yes, proceed to charge",
          },
        },
      },
      splitCart: {
        tab: "Select items to split from #{{tabId}}",
        noSelectedItems: "No items selected yet",
        splitSelectedItem: "Split selected items out of order",
        quantityModal: {
          title: "How many {{cartItemName}} would you like to split?",
          quantityPlaceholder: "Enter quantity",
          okButton: "Confirm quantity",
        },
        moveItemModal: {
          title: "Where would you like to send this item?",
        },
        splitCartModal: {
          title: "Where would you like to send the split items?",
          cartLabel: "Select new or existing order",
          cartPlaceholder: "Select order",
          createNewCart: "Create new order",
          tableLabel: "Select a table",
          tablePlaceholder: "Select table",
          noTable: "No table",
          okButton: "Confirm split",
          successMessage: "Items splitted successfully",
        },
      },
      printUnchargedCart: {
        fireConfirmation: {
          title: "Fire remaining {{count}} item?",
          title_plural: "Fire remaining {{count}} items?",
          body: "This order still has items in the cart that are not fired:",
          bodyAfterList: "Would you like to fire them all before printing?",
          listItemQuantity: "x{{quantity}}",
          fireAndPrint: "Yes, fire and print",
          printOnly: "No, print without firing",
        },
      },
      sendToKitchen: {
        buttonLabel: "Hold/fire",
        sendAndHoldAll: "Hold all unsent items",
        fireByCourseLabel: "Fire by course",
        selectToFireAndHold: "Select items to hold and fire",
        sendAndFireAll: "Fire all unsent items",
        sentAt: "Sent at {{timing}}",
        sentAtWithLabel:
          '{{label}} | $t(pointOfSale.cart.sendToKitchen.sentAt, { "timing": "{{timing}}" })',
        unsent: "Unsent",
        fireAll: "Fire all",
        coursing: "Coursing...",
        autoCourse: "Auto course",
        tab: "Select items to hold in kitchen",
        noSelectedItems: "Fire {{count}} item",
        noSelectedItems_plural: "Fire {{count}} items",
        selectedSomeItems: "Hold {{holdCount}} and fire {{count}} item",
        selectedSomeItems_plural: "Hold {{holdCount}} and fire {{count}} items",
        selectedAllItems: "Hold {{count}} item",
        selectedAllItems_plural: "Hold {{count}} items",
        quantityModal: {
          title:
            "How many <strong>{{cartItemName}}</strong> would you like to hold?",
          quantityPlaceholder: "Enter quantity",
          okButton: "Confirm quantity",
        },
        confirmationModal: {
          fireHeldItemsTitle: "Fire all held items?",
          sendAndHoldAllTitle: "Send and hold all items?",
          sendAndFireAllTitle: "Send and fire all items?",
          sendSelectedItemsTitle: "{{copy}}?",
        },
        fireByCourse: {
          tab: "Select course to fire in kitchen",
          courseLabel: "Course {{counter}}",
          emptyCourse: "Course is empty, hold and drag items in.",
          removeCourse: "Remove",
          addCourse: "Add course",
          noSelectedItems: "Hold {{count}} course",
          noSelectedItems_plural: "Hold {{count}} courses",
          selectedSomeItems: "Fire {{fireCount}} and hold {{count}} course",
          selectedSomeItems_plural:
            "Fire {{fireCount}} and hold {{count}} course",
          selectedAllItems: "Fire {{count}} course",
          selectedAllItems_plural: "Fire {{count}} courses",
        },
      },
    },
    updatePromoConfirmation: {
      title: "Apply {{label}} to cart?",
      confirmLabel: "Apply",
      noCartSelected: "Error: No order selected.",
    },
    updateDonationConfirmation: {
      title: "Add {{label}} to cart?",
      confirmLabel: "Add",
      noCartSelected: "Error: No order selected.",
    },
    paymentMethodSelector: {
      label: "Pick a payment method",
    },
    pagesPanel: {
      emptyLabel: "Layout is empty",
      removeConfirmation: "Are you sure you want to remove this page?",
      removeSuccess: "Page removed",
    },
    tilesPanel: {
      backLabel: "Back",
      emptyLabel: "Page is empty",
      numberOfItems: "{{count}} item",
      numberOfItems_plural: "{{count}} items",
      maxTilesError: "Max number of tiles reached",
      removeConfirmation: "Are you sure you want to remove this tile?",
      removeSuccess: "Tile removed",
      lowStock: "{{stocksLeft}} left",
      soldOut: "Sold out",
    },
    editorPanel: {
      editLayout: "Edit {{layoutName}}",
      addPage: "Add new page",
      addTile: "Add new tile",
      movePage: "Move {{label}}",
    },
    tileForm: {
      success: {
        create: "Tile created successfully",
        update: "Tile updated successfully",
      },
      type: {
        title: {
          PageTile: "Page tile",
          ItemTile: "Product tile",
          PromotionTile: "Promotion/discount tile",
          DonationTile: "Donation tile",
        },
        description: {
          PageTile: "Houses page and product tiles.",
          ItemTile: "Links to a product that can be added to the cart.",
          PromotionTile:
            "Links to a promotion/discount that can be applied to cart.",
          DonationTile: "Links to a donation that can be applied to cart.",
        },
      },
      sections: {
        tileDetails: "Tile details",
      },
      labels: {
        type: "Type",
        label: "Tile name",
        color: "Color",
        item: "Product",
        noItemSelected: "Please select a product",
        promotion: "Promotion/Discount",
        noPromotionSelected: "Please select a promotion/discount",
        selectedPromotion: "{{label}} ({{code}})",
        donation: "Donation",
        noDonationSelected: "Please select a donation",
        searching: "Searching...",
      },
      helpTexts: {
        type: "To change the tile type, please remove this tile and add a new one instead.",
      },
    },
    floorPlan: {
      noOrders: "No orders",
      notFired: "Not fired",
      isBilled: "Billed",
      legend: {
        occupiedTableNoOrders: "Occupied (No orders yet)",
        cartWithItemsNotFired: "Items in cart not fired",
      },
    },
    orderConfirmation: {
      processing: "Adding final touches to your order",
      cancelPayment: "Cancel payment",
      paymentCompleted: "Order #{{cartId}} successfully charged",
      printReceipt: "Print receipt",
      reprintReceipt: "Reprint receipt",
      loadOrder: "Load another order",
      newOrder: "Create new order",
      openFloorPlan: "Back to floor plan",
      printSuccess: "Print triggered successfully",
      reprintSuccess: "Reprint triggered successfully",
    },
    loaderMessages: {
      0: "Loading you a world of POS-sibilities",
      1: "Sending over some POS-itive vibes",
      2: "When the world is too fast, just POS",
      3: "Roses are red, violets are blue, life is beautiful, so are you!",
    },
    cashPaymentCalculatorModal: {
      title: "Balance calculator for cash payment",
      form: {
        totalDueLabel: "Total due",
        customerPaidLabel: "Customer paid by cash",
        changeLabel: "Change",
        paidExact: "Paid exact",
        enterManually: "Enter manually",
      },
      cashChangeMessage: "Change <strong>{{amount}}</strong>",
    },
  },
  secondaryDisplay: {
    atlas: "Powered by Atlas",
    enterPairingKey: "Enter pairing key",
    orderNumber: "Order #{{cartId}}",
    makeTerminalPayment:
      "Please complete your transaction on the payment terminal",
  },
  posLayouts: {
    header: "POS layouts",
    actions: {
      add: "Create new POS layout",
      assign: "Assign layouts to outlets",
      layoutManager: "Enter layout manager",
    },
    tableColumns: {
      label: "Name",
      brand: "Brand",
      outlets: "Assigned outlets",
    },
    assign: {
      header: "Assign layouts to outlets",
      placeholder: "Select a layout",
      tableColumns: {
        label: "Outlet",
        posLayout: "Layout",
      },
      success: "POS layouts assigned to outlets successfully",
    },
    form: {
      header: {
        new: "Create new POS layout",
        edit: "Edit POS layout",
      },
      labels: {
        label: "Name",
        brandId: "Brand",
      },
      placeholders: {
        label: "Name of layout",
      },
      save: {
        new: "Proceed to configuration",
        edit: "Save",
      },
      success: {
        create: "POS layout created successfully",
        update: "POS layout update successfully",
        destroy: "POS layout deleted successfully",
        duplicate: "POS layout duplicated successfully",
      },
    },
    destroyConfirmation: {
      title:
        "Are you sure you want to delete {{layoutName}}? This action cannot be undone.",
    },
    duplicateConfirmation: {
      title: "Duplicate {{layoutName}}?",
    },
  },
  stations: {
    pageHeader: "Stations",
    tableColumns: {
      nameAndFunctions: "Name/Functions",
      resources: "Resources",
    },
    resourceHeaders: {
      kiosk: "Kiosk",
      dispatcher: "Dispatcher",
      printers: "Printers",
      kdsScreen: "Kitchen display screen",
      paymentTerminals: "Payment terminals",
    },
    resourceValues: {
      kiosks: {
        none: "No kiosk config found",
      },
      printers: {
        none: "No printers found.",
        primary: "Primary",
        expeditor: "Expeditor",
      },
      paymentTerminals: {
        none: "No payment terminals found.",
      },
    },
    functions: {
      posEnabled: "Point of Sale (POS)",
      cfdEnabled: "- Customer Facing Display (CFD)",
      cashRegisterEnabled: "- Cash register",
      posPrintOnlyEnabled: "- Print-only mode",
      kdsEnabled: "Kitchen Display System (KDS)",
      kioskEnabled: "Kiosk",
      dispatchEnabled: "Dispatch Control",
    },
    noStations: "No stations",
    featureName: {
      posEnabled: "Point of Sale",
      cfdEnabled: "Customer Facing Display",
      posPrintOnlyEnabled: "Point of Sale (print-only)",
      kdsEnabled: "Kitchen Display System",
      kioskEnabled: "Kiosk",
      dispatchEnabled: "Dispatch Control",
      dispatchCallerEnabled: "Dispatch Caller",
    },
    actions: {
      addKiosk: "Create new kiosk config",
      editDispatcher: "Edit dispatcher config",
      addPrinter: "Create new station printer",
      addPaymentTerminal: "Create new payment terminal",
      success: {
        updateKioskConfig: "Updated kiosk config successfully",
        removePrinterConfig: "Printer config removed successfully",
        removeKioskConfig: "Kiosk config removed successfully",
        removePaymentTerminalConfig:
          "Payment terminal config removed successfully",
      },
    },
    form: {
      success: {
        create: "Station created successfully",
        update: "Station updated successfully",
        destroy: "Station deleted successfully",
      },
      sections: {
        stationConfig: {
          new: "New station config",
          edit: "Edit station config",
        },
      },
      labels: {
        name: "Name",
        posEnabled: "Enable Point of Sale (POS)",
        cfdEnabled: "Enable Customer Facing Display (CFD) for POS",
        cashRegisterEnabled: "Enable cash register for POS",
        posPrintOnlyEnabled: "Enable print-only mode for POS",
        kdsEnabled: "Enable Kitchen Display System (KDS)",
        kioskEnabled: "Enable Kiosk",
        dispatchEnabled: "Enable Dispatch Control",
      },
    },
    kioskQrModal: {
      header: "Kiosk QR URL",
      noChannelLink: "No configuration associated to this kiosk yet.",
      openLink: "Open in new tab",
    },
  },
  kioskConfig: {
    form: {
      headers: {
        new: "New kiosk config",
        edit: "Edit kiosk config",
      },
      labels: {
        channelIdentifier: "Channel identifier",
        channelLinkId: "Channel link ID",
      },
    },
  },
  dispatchConfig: {
    form: {
      headers: {
        new: "New dispatch config",
        edit: "Edit dispatch config",
      },
      labels: {
        tapToCompleteMode: "Tap to complete mode",
        tapToCompleteSingleQuantity: "Tap to complete single quantity",
        prepareAtLeadTimeMinutes: "Prepare at lead time minutes",
        warningLevelsLeadTimeMinutes: "Colour status (based on timestamp)",
        warningTimeType: "Timestamp type",
        showStorefrontAbbreviation: "Show storefront abbreviation",
        showCustomerNameInDispatcher: "Show customer names",
        disableAnimations: "Disable animations",
        kdsChime: "Kds chime",
        filteredFulfilmentTypes: "Fulfilment types",
        includeTags: "Include tags",
        excludeTags: "Exclude tags",
        callerTheme: "Caller theme",
        callerCardSize: "Caller card size",
        callerPreparingSectionSize: "Caller preparing section size",
      },
      placeholders: {
        filteredFulfilmentTypes: "Leave blank to show for all fulfilment types",
      },
    },
  },
  paymentTerminals: {
    form: {
      success: {
        update: "Payment terminal updated successfully",
      },
      sections: {
        paymentTerminalConfig: {
          new: "New payment terminal config for {{station}}",
          edit: "Edit payment terminal config for {{station}}",
        },
      },
      labels: {
        terminalId: "Terminal ID",
        terminalProvider: "Terminal provider",
        terminalModel: "Terminal model",
      },
    },
    types: {
      adyen: "Adyen",
      atlas_pay: "Atlas Pay",
    },
  },
  orders: {
    header: "All orders",
    actions: {
      add: "Add order",
      export: "Export to CSV",
    },
    exportModal: {
      header: "Export to CSV",
      sendToEmails:
        "This export may take several minutes to fully process. CSV will be emailed to the address(es) keyed in below when ready.",
      enterEmails: "Enter email(s)",
      export: "Export",
      cancel: "Cancel",
      invalidEmails: "One or more email addresses are invalid",
      success:
        "The CSV export will be sent to {{emails}} when your download is ready.",
    },
    filterHeaders: {
      filters: {
        button: "Filters",
        header: "Filter results",
        orderValue: "Order value ($)",
        servingTimeRange: "Serving time range",
        showOverAndUnderpaid: "Only show over/underpaid orders",
        fulfilmentType: "Fulfilment type",
        paymentTypeGroups: {
          creditCard: "Credit card",
          ecommerce: "E-commerce",
          pos: "POS",
          manual: "Manual",
        },
        creditCardPaymentTypes: {
          mc: "Mastercard",
          visa: "Visa",
          amex: "Amex",
          jcb: "JCB",
          diners: "Diners",
          cup: "China UnionPay",
          discover: "Discover",
        },
        paymentType: "Payment type",
        outlet: "Outlet",
        brand: "Brand",
        channel: "Channel",
        fulfilmentTypes: {
          delivery: "Delivery",
          dine_in: "Dine-in",
          pickup: "Pickup",
        },
        orderSource: "Order source",
        orderSources: {
          qr: "Scan to order (Legacy)",
          pos: "POS",
          web: "Online",
          scan_to_order: "Scan to order",
          kiosk: "Kiosk",
          admin: "Admin",
          grab_food: "GrabFood",
          foodpanda: "Foodpanda",
          deliveroo: "Deliveroo",
        },
        apply: "Apply filters",
        clear: "Clear all",
        validations: {
          orderValue: "Order value range is invalid",
        },
      },
      search: "Search",
      dateRange: "Date range",
      viewBy: "View by",
    },
    searchPlaceholder: "Search by ID, address, or customer",
    tableColumns: {
      identifier: "Order ID",
      state: "Status",
      servingDate: "Serving date/time",
      address: "Outlet/Address",
      customer: "Customer",
      giftRecipient: "Gift recipient",
      fulfilmentType: "Fulfilment type",
      outlet: "Outlet",
      orderItems: "Order items",
      amount: "Amount",
    },
    tableValues: {
      fulfilmentType: {
        delivery: {
          null: "Restaurant delivery",
          in_house: "Restaurant delivery",
          external: "Platform delivery",
        },
        pickup: "Pickup",
        dine_in: "Dine-in",
        table: "Table: {{tableName}}",
      },
      amountWithTip: "({{amount}} incl. tip)",
    },
    sourceLabels: {
      web: "Online",
      qr: "QR (Legacy)",
      scan_to_order: "QR / Scan-to-order",
      kiosk: "Kiosk",
      pos: "POS (Point of Sale)",
      admin: "Admin",
      grab_food: "GrabFood",
      foodpanda: "Foodpanda",
      deliveroo: "Deliveroo",
    },
    refundTypes: {
      header: "Refund type",
      order: "Order",
      item: "Items",
    },
    refundOrderItemTable: {
      headers: {
        name: "Name",
        quantity: "Qty",
        unitPrice: "Unit price",
        discount: "Discount",
        paid: "Paid",
        refunded: "Refunded",
        amountToRefund: "Amount to refund",
        total: "Total",
      },
      unpaid: "Unpaid",
    },
    orderDateTypes: {
      orderDate: "Order date",
      servingDate: "Serving date",
    },
    startDate: "Start date",
    endDate: "End date",
    new: {
      header: "New order",
    },
    edit: {
      header: "Edit order",
    },
    form: {
      orderValueChange: {
        increase: {
          header: "Order value increased by {{difference}}",
          description:
            "Order value was changed from {{previousValue}} to {{newValue}}. You may want to charge the customer the difference separately.",
        },
        decrease: {
          header: "Refund customer the difference of {{difference}}?",
          description:
            "Order value was changed from {{previousValue}} to {{newValue}}.",
        },
        refund: "Yes, open refund modal",
        doNotRefund: "No, don't refund",
      },
      updateConfirmation: {
        header: {
          cancelLogistics: "Cancel logistics booking?",
          bookLogistics: "Arrange a logistics booking?",
          replaceLogistics: "Re-book new delivery logistics?",
        },
        body: {
          cancelLogistics:
            "Your order updates do not seem to require a logistics booking anymore. Would you like to proceed with the update and cancel the booked logistics for this order?<br><br>You can also do this manually yourself on the fulfilment page.",
          bookLogistics:
            "Your order updates seem to require a logistics booking. Would you like to book logistics for this order?<br><br>You can also do this manually yourself on the fulfilment page.",
          replaceLogistics:
            "Your order updates seem to require a change in logistics booking. Would you like to cancel your existing logistics arrangement and re-book logistics for this order?<br><br>You can also do this manually yourself on the fulfilment page.",
        },
        cancelUpdate: "Cancel update",
        updateAndCancelBooking: "Update and cancel booking",
        updateAndKeepBooking: "Update and keep booking",
        updateAndBook: "Update and book",
        updateWithoutBooking: "Update without booking",
        updateAndRebook: "Update and re-book",
        updateAndKeepExisting: "Update and keep existing booking",
      },
      success: {
        create: "Order created successfully",
        update: "Order updated successfully",
        updateState: "Order state updated successfully",
        paymentLinkCancelled:
          "Order updated successfully. Previous generated payment link was cancelled as there is a change in the amount payable.",
      },
      sections: {
        orderDetails: "Order details (#{{identifier}})",
        newOrderDetails: "Order details",
        orderItems: "Order items",
        paymentDetails: "Payment details",
      },
      labels: {
        customer: "Customer",
        address: {
          generic: "Address",
          delivery: "Delivery address",
          pickup: "Pickup address",
          dine_in: "Dine-in address",
        },
        contactNumber: "Contact number",
        notes: {
          delivery: "Delivery notes",
          pickup: "Pickup notes",
          dine_in: "Dine-in notes",
        },
        isCutleryRequired: "Include cutlery?",
        isContactless: "Contactless delivery?",
        outlet: "Outlet",
        servingDate: "Serving date",
        timeslot: "Serving time",
        asapTimeslot: "ASAP ({{rangeLabel}})",
        subtotal: "Subtotal",
        discount: "Discount",
        deliveryFee: "Delivery fee",
        surcharge: "Surcharge",
        donationAmount: "Donation",
        donationLabel: "{{donationLabel}} (Donation)",
        adminDiscount: "Admin discount",
        tax: "Tax",
        totalIncludingTax: "Total",
      },
      errors: {
        timeslotId: "Serving time is a required field",
      },
      invalidContactNumber: "Invalid contact number",
      noTimeslots: "No timeslots found on {{date}}",
      noTimeslotsError:
        "There are no time slots defined for {{fulfilmentType}} for {{outletName}} on {{date}}.",
      newAddress: {
        header: "Add new address",
        add: "Add",
        searchPlaceholder: "Enter a street name or postal code",
        unitNoLabel: "Unit no",
        unitNoPlaceholder: "e.g. #01-02",
        deliveryHeader: "Select delivery address",
        pickupHeader: "Select pickup address",
      },
      orderItems: {
        menusNotLoaded:
          "Please select an outlet, serving date, and serving time first.",
        addItemsToCart: "Add items to cart",
        editCart: "Edit cart",
        showDetails: "Show details",
        hideDetails: "Hide details",
        quantity: "{{quantity}}x",
        modifierQuantity: "",
        modifierQuantity_plural: "{{count}}x",
        quantityWithUnitLabel: "{{quantity}}{{unitLabel}}",
        quantityWithUnitLabelAndPerUnitQuantity:
          "{{quantity}}{{unitLabel}} / {{perUnitQuantity}}",
        add: "Add",
        removeConfirmation: {
          title: "Are you sure you want to remove this item?",
          okText: "Remove",
          cancelText: "Cancel",
        },
      },
      cart: {
        header: "Order items",
        empty: "Your cart is empty. How about adding a few items?",
        unsavedChangesConfirmation: {
          title: "Are you sure you want to exit now?",
          body: "Looks like you haven't saved your cart. Leaving this screen now will not save your cart.",
          okText: "Leave now",
          cancelText: "Back to editing",
        },
        productEditor: {
          unsavedChangesConfirmation: {
            title: "Are you sure you want to exit now?",
            body: "Looks like you haven't saved your changes. Leaving this screen now will not save your changes.",
            okText: "Leave now",
            cancelText: "Back to editing",
          },
          quantityLabel: "Quantity of this item",
          modifierGroup: {
            selection: {
              included: "Included",
              filledCount: "({{filled}}/{{selectionRequiredMax}})",
              required:
                'Select $t(orders.form.cart.productEditor.modifierGroup.selection.item, { "count": {{selectionRequiredMax}} })',
              optional:
                "Select between {{selectionRequiredMin}} and {{selectionRequiredMax}} items",
              item: "{{count}} item",
              item_plural: "{{count}} items",
            },
          },
          add: "Add to cart",
          update: "Update",
        },
        productSelector: {
          header: "Select products",
          searchPlaceholder: "Search by name",
          searchResults:
            'Showing search results for <span class="font-semibold">"{{searchTerm}}"</span>',
          noResults: "No results found.",
          noMenuSelected: "Select a menu",
          labels: {
            allSections: "All sections",
            allSubSections: "All sub-sections",
          },
        },
        totalLabel: "Total",
        save: {
          label: "Save cart",
          error: "Cannot save cart while editing product.",
        },
      },
    },
  },
  orderDetails: {
    print: "Print receipt",
    selectStation: {
      label: "Select station to print to",
      print: "Print",
    },
    printReceipt: {
      customerReceiptOnly: "Customer receipt only",
      standard: "Include kitchen receipts",
      refundReceipt: "Refund receipt",
    },
    trackOrder: "Track order",
    pdf: "Get invoice",
    printLogs: {
      buttonLabel: "View print logs",
      header: "Print logs",
      noLogs: "No logs found.",
      summary: "{{timestamp}} - printed by <strong>{{accountEmail}}</strong>",
      noEmail: "System",
      showContent: "Show content",
      hideContent: "Hide content",
      showAdditionalInfo: "Show additional info",
      hideAdditionalInfo: "Hide additional info",
    },
    edit: "Edit",
    adjust: "Adjust",
    unauthorisedEditPastOrders:
      "Your account does not have permissions to edit past orders.",
    overPaidMessage:
      "The order was changed resulting in an overpayment made by the customer.",
    overPaidActionButtonLabel: "Refund {{amount}}",
    paymentLinkSentMessage:
      "A <a class='text-blue-800 underline hover:underline focus:underline' href='{{paymentLinkUrl}}' target='_blank'>payment link</a> has been sent to the customer's email",
    remindPaymentLinkButtonLabel: "Remind customer",
    paymentLinkErrorMessage:
      "There was an error generating a payment link ({{message}})",
    underPaidMessage:
      "The order was changed resulting in an underpayment made by the customer.",
    underpaidActionButtonLabel: "Choose payment type",
    adjustOrderModal: {
      title: "Adjustment action",
      body: "Choose an adjustment action.",
      actions: {
        edit: "Edit order",
        makeDifferentPayment: "Refund and make different payment",
        reopen: "Re-open in POS (refund required)",
      },
      errors: {
        makeDifferentPayment: {
          orderCancelled: "Making payment for cancelled order is not allowed",
          nothingToRefund: "No refundable amount available",
        },
        reopen: {
          noCart: "Order has already been re-opened before",
        },
      },
      refundModal: {
        success: "Successfully refunded {{amount}} for Order #{{identifier}}",
        makeDifferentPayment: {
          title:
            "Refund {{amount}} for Order #{{identifier}} before making different payment",
          cancel: "Cancel",
          confirm: "Refund {{amount}}",
        },
        reopen: {
          title: "Refund and re-open Order #{{identifier}} in POS",
          body: "Re-opening order in POS requires a full refund first. Refund {{amount}} for Order #{{identifier}}?",
          cancel: "Cancel",
          confirm: "Refund {{amount}} and re-open in POS",
          nothingToRefund: {
            title: "Refund and re-open Order #{{identifier}} in POS",
            body: "Order #{{identifier}} has been fully refunded. Re-open in POS?",
            cancel: "Cancel",
            confirm: "Re-open in POS",
          },
        },
      },
    },
    choosePaymentMethodModal: {
      title: "Choose payment type",
      labels: {
        paymentMethod: "Payment method",
      },
      paymentMethods: {
        creditCard: "Credit card",
        online: "Online payment link",
        inPerson: "Payment terminal",
        manual: "Manual payment",
      },
      paymentMethodDescriptions: {
        creditCard: "Send a payment link to the customer via email.",
        online: "Send a payment link to the customer via email.",
        inPerson: "Pay in-person with a connected Atlas payment terminal.",
        manual: "Manually record a payment as collected from your customer.",
      },
    },
    inPersonPaymentModal: {
      title: "In-store payment at {{outlet}}",
      subtitle:
        "Please confirm the charge details below for your in-store payment.",
      form: {
        amountLabel: "Amount to charge",
        stationLabel: "Select terminal to charge",
        paymentTypeLabel: "Payment type",
        buttonLabel: "Charge payment",
      },
      message: {
        success: "{{amount}} was successfully charged",
        successBody:
          "Payment may take up to a few minutes to be reflected. Please refresh the page to see the updated status.",
      },
    },
    markAsManualPaymentModal: {
      title: "Register a manual payment",
      subtitle:
        "Please indicate the amount paid for the order by the customer.",
      form: {
        amountLabel: "Amount received",
        paymentTypeLabel: "Payment type",
      },
      message: {
        success: "Manual payment of {{amount}} successfully recorded",
      },
    },
    cancel: "Cancel order",
    header: "Order details",
    cancelConfirmation: {
      header: "Cancel and refund order #{{orderIdentifier}}?",
      cancelAndRefund: "Cancel and open refund modal",
      cancelWithoutRefund: "Cancel without refund",
      doNotCancel: "No, don't cancel",
    },
    cancelDisabled: {
      grab_food:
        "Order cancellation is disabled for GrabFood orders. Please contact GrabFood for any cancellations.",
      foodpanda:
        "Order cancellation is disabled for Foodpanda orders. Please contact Foodpanda for any cancellations.",
      deliveroo:
        "Order cancellation is disabled for Deliveroo orders. Please contact Deliveroo for any cancellations.",
    },
    orderItems: {
      header: "Order items",
      headerLegacy: "Order items (Legacy)",
      moreDetails: "Click for more details",
      unpaid: "Unpaid",
      removed: "Removed from order",
      customIdentifierLabel: "{{identifierType}}",
      customIdentifier: "{{identifierType}}: {{identifier}}",
    },
    refundError: "Refund error",
    refundSuccessful: "Refund successful",
    refundProcessing: "Refund processing",
    printRefund: "Print refund receipt",
    printRefundTriggered: "Print refund triggered successfully",
    refundHeader:
      "{{email}}<span class='font-medium'> - {{timestamp}}</span> <span class='font-medium'> - {{refundPaymentType}}</span>",
    fulfilmentDetails: {
      id: "Order ID: {{identifier}}",
      externalOrderShortCode:
        "Order ID: {{externalOrderShortCode}} ({{identifier}})",
      servingDateAndTime: "Serving date & time",
      servingDate: "Serving date",
      servingTime: "Serving time",
      orderedOn: "Ordered on",
      createdDateAndTime: "Ordered on: {{createdDateTime}}",
      orderedOnExternal: {
        grab_food: "Ordered on GrabFood",
        foodpanda: "Ordered on Foodpanda",
        deliveroo: "Ordered on Deliveroo",
      },
      receivedFromExternal: {
        grab_food: "Received from GrabFood",
        foodpanda: "Received from Foodpanda",
        deliveroo: "Received from Deliveroo",
      },
      prepareByTiming: "Prepare by",
      deliveryTracking: "Delivery tracking",
      driverDetails: "Driver details",
      address: {
        delivery: "Delivery address",
        pickup: "Pickup from",
        dine_in: "Dine-in at",
      },
      giftRecipient: "Gift recipient",
      customerDetails: "Customer details",
      resendOrderConfirmation: {
        button: "Resend confirmation",
        message:
          "Resend confirmation for order #{{orderIdentifier}} to {{email}}?",
        send: "Send",
        success: "Order confirmation was successfully triggered to {{email}}.",
        failed: "Order confirmation was not resent. Is it unpaid or cancelled?",
      },
      isCutleryRequired: "Cutlery",
    },
    paymentDetails: {
      orderCharge: "Charge",
      total: "Total",
      orderRefunds: "Refunds",
      createPaymentLink: {
        label: "Generate payment link for {{amount}}",
      },
      remindPaymentLink: {
        label: "Send reminder",
        confirmation: "Send payment link reminder email to {{email}}?",
        success:
          "Payment link reminder email triggered successfully to {{email}}",
      },
      cancelPaymentLink: {
        label: "Cancel payment link",
        confirmation:
          "Are you sure you want to cancel the payment link for order #{{identifier}}?",
        yesLabel: "Yes, cancel payment link",
        noLabel: "No, don't cancel payment link",
        success: "Payment link cancelled successfully",
      },
      paymentLink: "Payment link:",
      refund: "Issue refund",
      fullRefund: "Full refund",
      partialRefund: "Partial refund",
      amountCaptured: "Paid amount",
      amountRefunded: "Refunded amount",
      splitPayments: "Split payments",
      amountUnpaid: "Unpaid amount",
      amountOverpaid: "Overpaid amount",
      fullyPaid: "Fully paid",
      partiallyPaid: "Partially paid",
      paymentType: "Payment type",
      paymentTypePlaceholder: "Select payment type",
      selectPaymentTypeModal: {
        title: "Select payment type",
        paymentTypeLabel: "Payment type",
        paymentTypePlaceholder: "Select payment type",
      },
      creditCardPaymentDetailLabel: "Credit card ending with {{cardLast4}}",
      amountWithTip: "({{amount}} incl. tip)",
      voucherQuantity: "{{quantity}}x ",
      voucherReference: "({{voucherReference}})",
      emptyVoucherReference: "(N/A)",
    },

    paymentBreakdown: {
      merchantLabel: "Merchant payment breakdown",
      label: "Customer payment breakdown",
      charges: "Charges",
      preTaxDeductions: "Pre-tax deductions",
      deductions: "Deductions",
      postTaxDeductions: "Post-tax deductions",
      serviceCharge: "Service charge",
      taxesAndCharges: "Taxes and charges",
      rounding: "Rounding",
      tip: "Tip",
      subtotal: "Subtotal",
      excludingLineItemsDiscount: "(excl. item discount)",
      merchantTax: "Merchant tax",
      merchantTotalIncludingTax: "Merchant total",
      platformTax: "Platform tax",
      tax: "Tax",
      totalIncludingTax: "Total",
      surcharge: "Surcharge",
      donationAmount: "Donation",
      donationLabel: "{{donationLabel}} (Donation)",
      lineItemsDiscount: "Item discount",
      discount: "Discount",
      platformDiscount: "Platform discount",
      adminDiscount: "Admin discount",
      deliveryFee: "Delivery fee",
      platformDeliveryFee: "Platform delivery fee",
      suffix: {
        discount: " ({{promoCode}})", // intentional whitespace at the beginning
        discountWithReason: " ({{reason}})", // intentional whitespace at the beginning
        openDiscount: " ({{description}})", // intentional whitespace at the beginning
        taxInclusivePrices: " (included)", // intentional whitespace at the beginning
      },
    },
    refund: {
      header: "Issue a refund",
      splitRefundHeader: "Confirm {{amount}} refund to customer",
      info: "Once confirmed, refunds will take 5-10 business days and cannot be reversed.",
      labels: {
        refundType: "Refund type",
        refundTypeOptions: {
          order: "Order",
          tip: "Tip",
        },
        amount: "Refund amount",
        reason: "Refund reason",
      },
      helpText: {
        amount: "({{maxRefund}} remaining)",
        amountPartialRefund:
          "Payment has been partially refunded ({{maxRefund}} remaining)",
      },
      error: {
        invalidAmount: "Amount must be within {{maxRefund}}",
        invalidReason: "Please enter a valid reason",
      },
      splitRefundTable: {
        refundTo: "Refund to",
        limit: "Limit",
        amount: "Refund amount",
        totalRefundAmount: "Total refund amount",
        errors: {
          limitExceeded: "Limit exceeded",
          incorrectTotalAmount:
            "Please enter accurate split of total refund amount above",
        },
        proceedByAtlasNotePre: "Refunds marked",
        proceedByAtlasNoteSuf: "are automatically processed by Atlas",
      },
      yes: "Refund",
      confirmRefund: "Confirm refund",
      no: "Cancel",
      successMessage: "Refund requested successfully",
    },
    trips: {
      header: "Logistics bookings",
      moreDetails: "Click for more details",
      empty: "No trips.",
      labels: {
        system: "System",
        bookingId: "Booking ID",
        cancelled: "Cancelled",
        trackingLink: "Tracking link",
        bookedBy: "Booked by",
        cancelledBy: "Cancelled by",
        unlinkTrip: "Unlink trip",
      },
      values: {
        bookedBy: "{{bookedBy}}, on {{formattedDate}}",
      },
      unlink: {
        label: "Unlink",
        header: "Unlinking a trip is permanent, would you like to proceed?",
        description:
          "Please confirm you would like to unlink the trip {{logisticCode}} by typing the trip ID in the input box below. Take note that this action cannot be undone.",
        form: {
          label: "Enter your trip ID to proceed",
        },
        success: "Trip {{logisticCode}} is unlinked",
      },
    },
    operationHistory: {
      label: "Operation history",
      commentLabel: "Comment",
      commentPlaceholder: "Type your comment here",
      createCommentLabel: "Create comment",
    },
    courier: {
      label: "Courier",
      courierDetails: "Courier details",
      tripDetails: "Trip details",
    },
    fulfilmentTypeLabel: "Fulfilment type",
    sourceLabel: "Source",
    outletLabel: "Outlet",
    buzzerIdentifierLabel: "Buzzer",
    buzzerIdentifier: "#{{identifier}}",
    notesLabel: {
      delivery: "Delivery notes",
      pickup: "Pickup notes",
      dine_in: "Dine-in notes",
    },
    isCutleryRequiredLabel: "Includes cutlery",
    isContactlessLabel: "Contactless delivery",
    contactNameLabel: "Customer name",
    contactNumberLabel: "Contact number",
    contactEmailLabel: "Email address",
    contactAccessCodeLabel: "Contact access code",
    orderSummaryLabel: "Order summary",
    showDetails: "Show details",
    hideDetails: "Hide details",
    void: "Void",
    cancelVoid: "Cancel void",
    amountPayable: "Amount payable",
    updateExternalOrderMessage: {
      title: "{{platformName}} order will not be synced",
      body: "Edits made to this order will not reflect on your {{platformName}} account.",
    },
  },
  fulfilment: {
    header: {
      todaysOrders: "Today's orders",
      upcomingOrders: "Upcoming orders",
    },
    actions: {
      labels: {
        search: "Search order",
        fulfilmentState: "Status",
        servingDate: "Date",
        fulfilmentType: "Delivery/Pickup/Dine-in",
        bulkAction: "Bulk action",
        bulk: {
          print: "Batch printing",
          book_logistics: "Batch booking",
          confirm: "Batch confirm",
        },
      },
      placeholders: {
        search: "Search any field",
        markAs: "Change status",
        bulk: {
          print: "Tap rows to select orders for batch printing",
          book_logistics: "Tap rows to select orders for batch booking",
          confirm: "Tap rows to select orders for batch confirming",
        },
      },
      filters: {
        fulfilmentState: {
          unassigned: "Unassigned",
          not_ready_for_pickup: "Not ready for pickup",
          assigning: "Assigning",
          assigned: "Assigned",
          dispatched: "Dispatched",
          completed: "Completed",
          ready_for_pickup: "Ready for pickup",
          cancelled: "Cancelled",
        },
      },
      print: "Print",
      bookLogistics: "Make booking",
      confirm: "Confirm orders",
      bulk: {
        print: "Print",
        book_logistics: "Choose logistics provider",
        confirm: "Confirm",
      },
      ordersCountSelected: "1 order selected",
      ordersCountSelected_plural: "{{count}} orders selected",
      markAs: {
        states: {
          unpaid: "Unconfirmed",
          confirmed: "Confirmed",
          not_ready_for_pickup: "Not ready for pickup",
          ready_for_pickup: "Ready for pickup",
          unassigned: "Unassigned",
          assigned: "Assigned",
          dispatched: "Dispatched",
          completed: "Completed",
          cancelled: "Cancelled",
        },
        errors: {
          assigned: "Can only mark as Assigned from Dispatched state.",
          cancelPastOrder:
            "Your account does not have permissions to cancel past orders.",
        },
      },
      markConfirmed: "Confirm order",
    },
    tabs: {
      confirmed: "Confirmed",
      unconfirmed: "Unconfirmed",
      dispatched_completed_cancelled: "Dispatched / Completed / Cancelled",
      cancelled: "Cancelled",
    },
    tableColumns: {
      identifier: "Order ID",
      state: "Status",
      orderItems: "Order items",
      servingDate: "Serving date",
      prepareBy: "Prepare by",
      servingTime: "Serving time",
      printed: "Printed?",
      brand: "Brand",
      serviceZone: "Service zone",
      address: "Outlet/Address",
      notes: "Notes",
      customer: "Customer",
      giftRecipient: "Gift recipient",
      fulfilmentState: "Status",
    },
    tableValues: {
      fulfilmentType: {
        delivery: {
          null: "Restaurant delivery",
          in_house: "Restaurant delivery",
          external: "Platform delivery",
        },
      },
      orderItemsCount: "1 item",
      orderItemsCount_plural: "{{count}} items",
      fulfilmentState: {
        null: "ERROR",
        unassigned: "Unassigned",
        not_ready_for_pickup: "Not ready for pickup",
        assigning: "Assigning",
        assigned: "Assigned",
        dispatched: "Dispatched",
        completed: "Completed",
        ready_for_pickup: "Ready for pickup",
        cancelled: "Cancelled",
        grab_food: "Fulfilled by GrabFood",
        foodpanda: "Fulfilled by Foodpanda",
        deliveroo: "Fulfilled by Deliveroo",
      },
      logisticsStatus: {
        status: {
          assigning: "Assigning",
          assigned: "Assigned",
          dispatched: "Dispatched",
          completed: "Completed",
          unassigned: "Unassigned",
        },
        message: {
          matching_courier: "Matching courier",
          courier_near_pickup: "Courier near pickup",
          delayed: "Delayed",
          courier_cancelled: "Courier cancelled",
          booking_expired: "Booking expired",
          courier_rejected: "Courier rejected",
        },
      },
      trackingLink: "Tracking link",
      operation: {
        makeBooking: "Make booking",
        markReady: "Mark ready",
        cancelBooking: "Cancel booking",
        dispatch: "Dispatch",
        markComplete: "Mark complete",
        markFoodpandaOrderAsPrepared: "Mark as prepared",
        markFoodpandaOrderAsPickedUp: "Mark as picked up",
      },
      foodpandaOrderPreparedLabel: "Marked as prepared",
    },
    cancelledTrips: "You have 1 cancelled delivery assignment.",
    cancelledTrips_plural: "You have {{count}} cancelled delivery assignments.",
    pickupOrdersCannotBeBooked:
      "You cannot book logistics for a pickup/dine-in order",
    newOrdersModal: {
      acknowledge: "Got it!",
      newOrdersCount: "You've got a new order!",
      newOrdersCount_plural: "You've got new orders!",
      todayOrdersCount: "{{count}} new order for today",
      preOrdersCount: "{{count}} new pre-order",
      todayOrdersCount_plural: "{{count}} new orders for today",
      preOrdersCount_plural: "{{count}} new pre-orders",
    },
    undoMarkConfirmed: "Undo confirming of orders: {{identifiers}}",
    receipt: {
      contactName: "Contact name: {{contactName}}",
      contactNumber: "Contact number: {{contactNumber}}",
      notes: "Notes:",
      items: "Items:",
      servedBy: "Served by",
    },
    printReceiptModal: {
      okay: "Okay",
      printed: "Printed",
      success: {
        header: "Success:",
        order: "Order #{{order}} printed at {{printer}}",
      },
      failed: {
        header: "Failed:",
        order: "Order #{{order}} failed to print at {{printer}} - {{error}}",
        noPrinter: "no printer",
        missingPrinter: "Please configure outlet printer.",
      },
    },
    refundReceiptModal: {
      header: "Choose the refund you wish to print",
    },
    trackingModal: {
      trackingLink: "Tracking link",
      done: "Done",
    },
    bookLogistics: {
      header: "Make a booking",
      deliveryProviders: {
        label: "Delivery providers",
        pickupp: "Pickupp",
        lalamove: "Lalamove",
        gogox: "GOGOX",
        pandago: "Pandago",
        rida: "Atlas Smart Logistics (Rida)",
        milk_run: "MilkRun",
        asl_lalamove: "Atlas Smart Logistics",
        asl_pandago: "Atlas Smart Logistics (Pandago)",
        asl_milk_run: "Atlas Smart Logistics (MilkRun)",
        asl_gogox: "Atlas Smart Logistics (GOGOX)",
        asl: "Atlas Smart Logistics",
      },
      numOrdersSelectedPrefix: "You have selected",
      ordersCount: "{{count}} order",
      ordersCount_plural: "{{count}} orders",
      numOrdersSelectedSuffix: ". Rearrange the orders to check quotation.",
      ordersTableColumns: {
        sort: "",
        identifier: "Order ID",
        servingDateAndTime: "Serving date/time",
        deliveryAddress: "Delivery address",
        serviceZone: "Service zone",
      },
      getQuotation: "Check quotation",
      exitingPageNotice:
        "You are about to exit the app. We highly recommend that you return to the app.",
      emptyQuotations:
        "Arrange the sequence of the orders according to your preference and check quotation.",
      quotationsTableColumns: {
        service: "Delivery provider",
        deliveryFee: "Delivery fee",
      },
      quotationsTableValues: {
        service: {
          lalamove: "Lalamove",
          gogox: "GOGOX",
          pickupp: "Pickupp",
        },
      },
      serviceTypes: {
        BIKE: "Bike",
        CAR: "Car",
        MINIVAN: "Minivan",
        VAN: "Van",
        MOTORCYCLE: "Motorcycle",
      },
      overrideVehicleLimit: {
        header: "Vehicle may be too small",
        item: "Some of your orders contain <strong>{{item}}</strong> which requires a <strong>{{vehicleType}}</strong>.",
        orderValue:
          "Some of your orders are valued <strong>above {{orderValue}}</strong> and should be assigned to a <strong>{{vehicleType}}</strong> instead.",
        confirmation: {
          title: "Book a smaller vehicle?",
          body: "Are you sure you would like to proceed with booking a vehicle that may be too small for this order?",
          confirm: "Book",
        },
      },
      options: {
        lalamove: {
          service: "Lalamove",
          serviceLevel: "ASAP and scheduled multi-location deliveries.",
        },
        gogox: {
          service: "GOGOX",
          serviceLevel: "ASAP and scheduled multi-location deliveries.",
        },
        pickupp: {
          service: "Pickupp",
          serviceLevel: "Deliver within 1 hour",
        },
        pandago: {
          service: "Pandago",
          serviceLevel: "ASAP single location deliveries only.",
        },
        rida: {
          service: "Atlas Smart Logistics (Rida)",
          serviceLevel:
            "ASAP and scheduled single location deliveries, multi-platform matching.",
        },
        milk_run: {
          service: "MilkRun",
          serviceLevel: "ASAP and scheduled multi-location deliveries.",
        },
        asl_lalamove: {
          service: "Atlas Smart Logistics",
          serviceLevel:
            "ASAP and scheduled single location deliveries, multi-platform matching.",
        },
        asl_pandago: {
          service: "Atlas Smart Logistics (Pandago)",
          serviceLevel: "ASAP and single location deliveries only",
        },
        asl_milk_run: {
          service: "Atlas Smart Logistics (MilkRun)",
          serviceLevel: "ASAP and scheduled multi-location deliveries",
        },
        asl_gogox: {
          service: "Atlas Smart Logistics (GogoX)",
          serviceLevel: "ASAP and scheduled multi-location deliveries",
        },
      },
      footer: {
        helpText: "Select one of the providers and click on Confirm booking.",
        confirmBooking: "Confirm booking",
      },
      bookConfirmation: {
        title: "Book {{service}} for {{orderIdentifiers}} at {{price}}?",
        confirm: "Book",
      },
      success:
        "Successfully booked {{service}} for {{orderIdentifiers}} at {{price}}",
    },
    cancelLogistics: {
      header:
        "Are you sure you want to cancel booking {{externalLogisticsId}} with {{externalLogisticsType}} for order #{{orderIdentifier}}?",
      forceCancelHeader:
        "Booking cannot be cancelled once the courier has accepted your order. $t(fulfilment.cancelLogistics.header)",
      buttons: {
        ok: "Yes, cancel booking",
        cancel: "Keep my booking",
      },
      success:
        "Successfully cancelled {{externalLogisticsId}} with {{externalLogisticsType}}.",
      forceCancelSuccess:
        "We can't cancel the booking with {{externalLogisticsType}}, but you can now make another booking for this order.",
    },
  },
  menus: {
    header: "Menu builder",
    title: "Menus",
    actions: {
      add: "Add menu",
      edit: "Edit",
      delete: "Delete",
      editExternalItemLinks: "Edit external item links",
      multiLanguageSetup: "Multi-language setup",
      syncToGrabFood: "Sync to GrabFood",
      syncToFoodpanda: "Sync to Foodpanda",
      syncToDeliveroo: "Sync to Deliveroo",
      exportToXlsx: "Export to xlsx",
      syncRevelMenus: "Sync with Revel",
      syncRevelMenusInProgress: "Sync in progress",
    },
    tabs: {
      all: "All",
      published: "Published",
      draft: "Draft",
    },
    tableColumns: {
      label: "Name",
      identifier: "ID",
      brand: "Brand",
      state: "Published on website?",
      startAndEndDate: "Start - End date",
      createdAndUpdatedDate: "Created / Updated date",
      lastSyncedDate: "Last synced date",
    },
    sync: {
      goToMenuList: "Go to menu list",
      syncedOutlets: "Menu last synced to the following outlets: ",
      outOfSync:
        "The menu was updated at {{updatedAt}}. But it is not synced to aggregators at any outlets yet.",
      syncReminder: "Please remember to re-sync this menu from your menu list",
      fromRevel: "(from Revel)",
      failed: "Menu sync failed",
      inProgress: "Menu sync in progress...",
    },
    tableValues: {
      startAndEndDate: "{{startDate}} - {{endDate}}",
      active: "Live",
    },
    menuTypes: {
      atlas: "Atlas",
      grab_food: "GrabFood",
      foodpanda: "Foodpanda",
      deliveroo: "Deliveroo",
      Revel: "Revel",
      revel: "Revel",
    },
    updateExternalItemLinksSuccess: "External item links updated successfully",
    externalItemLinks: {
      selectIntegration: "Select integration",
    },
    multiLanguageSetup: {
      title: "Multi-language settings for {{menuLabel}}",
      description:
        "Download your menu translation sheet below to view your current set up. Upload and sync your menu to ensure it is reflected on integrated platforms (currently only supported by Foodpanda).",
      filename: "{{menuLabelSnakeCase}}_translation.csv",
      lastUpdated: "Last updated {{date}}",
      form: {
        success: {
          import: "Menu translation sheet updated successfully",
          withWarnings: "Menu translation sheet updated with warnings",
          syncReminder:
            "Please remember to re-sync this menu from your menu list.",
        },
        labels: {
          file: "Update translation sheet",
        },
        subtitles: {
          file: "CSV up to 10MB",
        },
        prompts: {
          uploadFile: "Upload a file",
          uploadAnotherFile: "Upload another file",
        },
        errors: {
          invalidFileExtension: "Only supports .csv extension",
        },
        warnings: {
          header: "There was a warning with your file",
          header_plural: "There were {{count}} warnings with your file",
        },
      },
    },
    menuSyncOutletSelector: {
      label: "Outlet",
      placeholder: "Select an outlet",
      validate: "Validate",
      sync: "Sync",
      menuValidated: "{{outlet}}: Menu is validated",
      menuValidatedWithWarning:
        "{{outlet}}: Menu is validated. However, there were configuration/information changes due to provider requirements.",
      menuValidatedWithError: "{{outlet}}: Menu is invalid",
      menuSynced: "{{outlet}}: Menu is successfully synced",
      menuSyncedWithWarning:
        "{{outlet}}: Menu is successfully synced. However, there were configuration/information changes due to provider requirements.",
      menuSyncWarningsLink: "Click here to view the warnings",
      menuSyncedWithError: "{{outlet}}: Menu sync request failed",
      grabFood: {
        validateRequest: {
          success: "GrabFood menu is valid",
          successWithWarnings: "GrabFood menu is valid with warnings",
          failed: "GrabFood menu is invalid",
          warnings: "GrabFood menu warnings",
          errors: "GrabFood menu errors",
        },
        syncRequest: {
          success: "Sync to GrabFood menu requested successfully",
          successWithWarnings:
            "Sync to GrabFood menu requested successfully with warnings",
          failed: "Sync to GrabFood menu request failed",
          warnings: "GrabFood menu sync request warnings",
          errors: "GrabFood menu sync request errors",
          syncIsNotImmediateInfo:
            "Please note that menu can take up to several minutes to reflect on GrabFood, and may take even longer during peak hours.",
        },
      },
      foodpanda: {
        validateRequest: {
          success: "Foodpanda menu is valid",
          successWithWarnings: "Foodpanda menu is valid with warnings",
          failed: "Foodpanda menu is invalid",
          warnings: "Foodpanda menu warnings",
          errors: "Foodpanda menu errors",
        },
        syncRequest: {
          success: "Foodpanda menu synced successfully",
          successWithWarnings:
            "Foodpanda menu requested successfully with warnings",
          failed: "Foodpanda menu sync failed",
          warnings: "Foodpanda menu sync warnings",
          errors: "Foodpanda menu sync errors",
          syncIsNotImmediateInfo:
            "Please note that menu can take up to several minutes to reflect on Foodpanda.",
        },
      },
      deliveroo: {
        syncRequest: {
          notification: "Deliveroo menu sync is requested",
          warnings: "Deliveroo menu sync request warnings",
          errors: "Deliveroo menu sync request errors",
          failed: "Deliveroo menu sync failed",
          syncIsNotImmediateInfo:
            "Please note that menu can take up to several minutes to reflect on Deliveroo",
        },
      },
    },
    form: {
      actions: {
        saveDraft: "Save to draft",
      },
      success: {
        create: "Menu created successfully",
        update: "Menu updated successfully",
      },
      labels: {
        label: "Name",
        identifier: "ID",
        startDate: "Start date",
        endDate: "End date",
        brandId: "Brand",
        tags: {
          grab_food: "For GrabFood?",
          foodpanda: "For Foodpanda?",
          deliveroo: "For Deliveroo?",
        },
        sections: "Sections",
        section: {
          displayOrder: "Display order in menu",
        },
      },
      subtitles: {
        image: "(PNG, JPG up to 10MB)",
      },
      placeholders: {
        startDate: "Select date",
        endDate: "Select date",
      },
      prompts: {
        uploadFromUrl: "Upload from URL",
        upload: "Upload",
        uploadAnotherFile: "Upload another file",
        uploadFile: "Upload file",
        removeImage: "Remove image",
      },
      externalMenus: {
        header: "External menus",
        description:
          "Allows the items in this menu to show up on Stocks page. This does NOT automatically trigger a menu sync to the platforms.",
      },
      image: {
        header: "Deliveroo cover image",
        description:
          "This is for the cover image used for this menu on Deliveroo.",
      },
      removeImageConfirmation: {
        title: "This action cannot be undone. Confirm remove image?",
      },
      sections: {
        header: "Sections",
        menuStructure: "Menu structure",
        add: "Add a section",
        addProduct: "Add a product",
        addMenuSectionModal: {
          header: "Add a section",
          parentSection: {
            label: "Parent section",
            none: "None - set as top-level section",
            hasItems: "{{label}} (already contains products)",
          },
          createSection: "Create a new section",
          selectExistingSection: "or select an existing section below",
          warnInOtherMenus: {
            confirmation:
              'The top-level section "{{label}}" is used in 1 other menu. Changes made here will also reflect on that menu.',
            confirmation_plural:
              'The top-level section "{{label}}" is used in {{count}} other menus. Changes made here will also reflect on these menus.',
            confirm: "Yes, please continue",
            cancel: "Cancel changes",
          },
        },
        addMenuSectionProductModal: {
          header: "Add a product",
          createProduct: "Create a new product",
          selectExistingProducts: "or select existing products below",
          modifierGroups: {
            createModifierGroup: "Create a new modifier group",
            selectExistingModifierGroup:
              "or select an existing modifier group below",
          },
        },
        sectionItems: {
          highlight: "Highlight",
          makeHighlight: "Make highlight",
          removeHighlight: "Remove as highlight",
          highlightConfirmation: {
            title:
              "This will replace <strong>{{currentHighlight}}</strong> as highlight of this section. Proceed?",
            ok: "Yes, proceed",
            cancel: "Cancel",
          },
        },
      },
    },
  },
  sections: {
    header: "Sections",
    title: "Sections",
    actions: {
      add: "Add section",
    },
    tableColumns: {
      sort: "",
      labelAndIdentifier: "Name",
      description: "Description",
      parentSection: "Parent section",
      displayOrder: "Display order",
      dinerSectionLink: "",
    },
    tableValues: {
      noBrand: "No brand",
      noDescription: "No description",
      noParentSection: "No parent section",
      dinerSectionLink: {
        label: "Get link",
        success: "Link copied to clipboard.",
      },
      alreadyLinked: "Already linked",
      parentSectionOnly: "Used as a parent section in other menu(s)",
      subSectionOnly: "Already used as a sub-section",
    },
    form: {
      success: {
        create: "Section created successfully",
        update: "Section updated successfully",
      },
      sections: {
        newSection: "Add a section",
        editSection: "Edit section #{{id}}",
        subSections: "Sub-sections",
        products: "Products",
      },
      headers: {
        image: "Image",
      },
      subHeaders: {
        image: "Only used for kiosk menu navigation tab",
      },
      labels: {
        label: "Name",
        identifier: "ID",
        description: "Description",
        displayOrder: "Display order in section",
        parentSection: "Parent section",
        subSections: "Sub-sections",
        products: "Products",
        image: "Image",
      },
      prompts: {
        uploadFromUrl: "Upload from URL",
        uploadFile: "Upload file",
        upload: "Upload",
        uploadAnotherFile: "Upload another file",
        removeImage: "Remove image",
      },
      subtitle: {
        image: "(PNG, JPG up to 10MB)",
      },
      placeholders: {
        parentSection: "No parent section",
        subSections: "Add existing sub-sections to make this a parent section",
        products: "Start by adding existing products to this section",
      },
      duplicateAvailabilityConditionsFromSection:
        "Duplicate availability conditions from another section",
      duplicateAvailabilityConditionsFromItem:
        "Duplicate availability conditions from a product",
      selectSubSections: "Select sub-sections",
      addSubSection: "Add sub-section",
      selectProducts: "Select products",
      selectedProductCount: "{{count}} item selected",
      selectedProductCount_plural: "{{count}} items selected",
    },
    searchModal: {
      header: "Search for a section",
    },
  },
  printers: {
    tableValues: {
      noDescription: "No description",
      noParentSection: "No parent section",
    },
    form: {
      success: {
        create: "Printer created successfully",
        update: "Printer updated successfully",
        destroy: "Printer deleted successfully",
      },
      sections: {
        stationPrinterConfig: {
          new: "New station printer config for {{station}}",
          edit: "Edit station printer config for {{station}}",
        },
        printerDetails: "Printer details",
      },
      labels: {
        name: "Name",
        serialNumber: "SN",
        copies: "Copies",
        outletBrands: "Brands",
        tagList: "Tag list",
        excludeSubItemTagList:
          "Sub-items with matching tags to exclude in configuration",
        deliveryReceiptPrinter: "Delivery receipt printer?",
        customerReceiptPrinter: "Customer receipt printer?",
        kitchenReceiptPrinter: "Kitchen receipt printer?",
        itemReceiptPrinter: "Item receipt printer?",
        labelPrinter: "Label printer?",
        excludeTags: "Tag list should",
        matchSubItemTags:
          "Include sub-items with matching tags as a separate item?",
        primary_printer: "Primary printer?",
        expeditor_printer: "Expeditor printer?",
      },
      subtitles: {
        primary_printer: "Print out customer receipt on POS checkout",
        expeditor_printer:
          "Print out kitchen receipt on fire line items to kitchen",
      },
      placeholders: {
        tagList: "Leave blank to include all items",
      },
      values: {
        excludeTags: {
          true: "Exclude top level items with matching tags",
          false: "Include top level items with matching tags",
        },
      },
      copiesConfirmation: {
        title: "Are you sure you want to set {{copies}} copies?",
      },
    },
  },
  products: {
    type: {
      title: {
        Product: "Product",
        Component: "Component",
      },
      description: {
        Product:
          "An SKU on your menu (e.g. combo meals, party sets, cheeseburger, surf and turf platter, ice lemon tea)",
        Component:
          "Building blocks that form a product (e.g. ingredients, sizes, dietary options)",
      },
    },
    filters: {
      brands: {
        all: "All brands",
        none: "No brand",
      },
    },
    search: "#{{id}} - [{{type}}] {{label}} ({{price}})",
    header: "Products",
    title: "Products",
    actions: {
      downloadCsvTemplate: "Download import CSV template",
      importCsv: "Import CSV",
      confirmImportCsv: "Confirm import",
      add: "Add product",
      archive: {
        label: "Archive",
        confirmation: "Archive {{label}}?",
        batch: "Archive",
        batchInstructions: "Tap rows to select products for batch archiving",
        batchConfirmation: "Archive 1 product?",
        batchConfirmation_plural: "Archive {{count}} products?",
        success: "{{count}}/{{totalCount}} products archived",
        unsuccessful: {
          buttonLabel: "1 product could not be archived",
          buttonLabel_plural: "{{count}} products could not be archived",
          title:
            "The following product is in one or more active menus or POS layouts. Archiving will remove this product from these menus and POS layouts.",
          title_plural:
            "The following products are in one or more active menus or POS layouts. Archiving will remove these products from these menus and POS layouts.",
          itemLabel: "{{label}} ({{identifier}})",
          forceArchive: "Archive anyway",
          forceArchiveItemConfirmationTitle:
            "{{label}} is in one or more active menus or POS layouts",
          forceArchiveItemConfirmationBody:
            "Are you sure you want to remove {{label}} from all menus and POS layouts and archive it?",
        },
      },
      unarchive: {
        label: "Unarchive",
        confirmation: "Unarchive {{label}}?",
        batch: "Batch unarchive",
        batchInstructions: "Tap rows to select products for batch unarchiving",
        batchConfirmation: "Unarchive 1 product?",
        batchConfirmation_plural: "Unarchive {{count}} products?",
        success: "{{count}}/{{totalCount}} products unarchived",
      },
      itemsCountSelected: "1 product selected",
      itemsCountSelected_plural: "{{count}} products selected",
      noItemsSelected: "No products selected",
    },
    importCsv: {
      success: "Upload complete",
      failed: "Items could not be generated",
      invalidFileExtension: "Only supports .csv extension",
      previewItemsHeader: "Preview",
      newItemsHeader: "Successfully generated",
      warningsHeader: "Warnings",
    },
    itemStates: {
      active: "Active",
      archived: "Archived",
    },
    tabs: {
      all: "All",
      single: "Products",
      component: "Components",
    },
    tableColumns: {
      sort: "",
      horizontalImageUrl: "Image",
      labelAndIdentifier: "Name",
      label: "Name",
      identifier: "Identifier",
      brandId: "Brand ID",
      tagList: "Tags",
      type: "Type",
      description: "Description & tags",
      unitPriceFractional: "Price",
      story: "Story",
      currency: "Currency",
      dailyDefaultStock: "Daily default stock",
      select: "",
      displayOrder: "Display order",
      remove: "",
      noData: "No data",
      search: "What are you looking for?",
    },
    tableValues: {
      archivedAt: "Archived on {{archivedAt}}",
      noBrand: "No brand",
      tagTooltip: {
        kitchenTagList: "Printer/KDS/Dispatch tag",
        tagList: "Product tag",
        reportCategory: "Reporting category",
      },
    },
    form: {
      header: "Product details",
      new: "New product",
      archivedLabel:
        "Product is archived on {{archivedAt}}, click here to unarchive",
      prompts: {
        copyItemImage: "Copy from another product",
        uploadFromUrl: "Upload from URL",
        upload: "Upload",
        uploadAnotherFile: "Upload another file",
        uploadFile: "Upload file",
        removeImage: "Remove image",
      },
      success: {
        create: "Product created successfully",
        update: "Product updated successfully",
        createLeadTimeCondition:
          "Product lead time created and linked successfully",
        duplicateAvailabilityConditions:
          "Availability conditions duplicated successfully",
        noAvailabilityConditionsToDuplicate:
          "No availability conditions to duplicate",
      },
      values: {
        leadTimeConditionSources: "{{value}} {{units}} before {{type}}",
        servingDate: "12:00AM of the serving date",
        timeslot: "the serving time slot",
        createProductFirst: "Please create product first.",
      },
      sections: {
        productDetails: {
          label: "General product details",
          key: "general-product-details",
        },
        modifierGroups: {
          label: "Modifier groups",
          key: "configurations",
        },
        leadTimeConditionSources: {
          label: "Lead time",
          key: "lead-time",
        },
        availability: {
          label: "Availability",
          key: "availability",
        },
        deliverySettings: {
          label: "Delivery settings",
          key: "delivery-settings",
          add: "Add delivery vehicle requirement",
          minimumVehicleType:
            "Orders containing this item will require delivery by",
          vehicleTypes: {
            CAR: "car or larger vehicle",
            MINIVAN: "minivan or larger vehicle",
          },
        },
        nutriGrade: {
          label: "Nutri-Grade badge",
          key: "nutri-grade",
          description:
            "Display of the Nutri-Grade label is mandatory for freshly prepared beverages. Calculate your product's Nutri-Grade score <a class='{{linkClassName}}' href='https://hpb.gov.sg/healthy-living/food-beverage/nutri-grade#nutri_grade_calculator' target='_blank' rel='noreferrer'>here</a>.",
          enabled: "Show Nutri-Grade rating and sugar content",
          enabledSubtitle:
            "Display Nutri-Grade and sugar content on Atlas Online and Aggregators",
          sugarLevel: "Sugar level (%)",
          sugarLevelPlaceholder: "0.0",
          sugarLevelErrorValueRange: "Value must be between 0 and 100.",
          nutriGrade: "Nutri-Grade",
          nutriGradeHelpText: "Only available for Product type",
        },
      },
      aiDescriptionRefine:
        "Refine description with custom instructions or try again",
      aiDescriptionGenerator:
        "Generate a description automatically based on your restaurant's information",
      refineCancel: "Cancel",
      generateDescription: "Suggest",
      suggestingDescription: "Suggesting...",
      regenerateDescription: "Re-suggest",
      refineDescription: "Refine",
      refiningDescription: "Refining...",
      refineModalHeader: "Refine suggestion",
      presetRefinements: {
        funny: "Be funnier",
        professional: "More posh and elegant",
        friendly: "Make it simple and minimal",
        descriptive: "More descriptive",
        minimal: "Use a friendlier tone",
      },
      labels: {
        type: "Type",
        label: "Name",
        identifier: "SKU",
        internalName: "Internal name",
        description: "Description",
        currency: "Currency",
        brand: "Brand",
        unitPriceFractional: "Price",
        unitCostFractional: "Cost",
        unitLabel: "Unit label",
        perUnitQuantity: "Per unit quantity",
        kitchenTags: "Printer / KDS / Dispatch tags",
        showTagConnections: "Show tag connections ({{tagConnectionSummary}})",
        hideTagConnections: "Hide tag connections ({{tagConnectionSummary}})",
        noTagConnections: "No devices linked yet",
        tagConnectionSummary: {
          summary: "{{devices}}, {{outlets}}",
          empty: "No devices",
          devices: "1 device",
          devices_plural: "{{count}} devices",
          outlets: "1 outlet",
          outlets_plural: "{{count}} outlets",
        },
        tags: "Product tags",
        reportCategory: "Reporting category",
        newReportTag: "Create new category",
        horizontalImage: "Horizontal image",
        leadTimeConditionSources: "Linked lead time conditions",
        promotionalLabelText: "Promotional label text",
        promotionalLabelFontColor: "Promotional label font color",
        promotionalLabelBgColor: "Promotional label bg color",
        existingDescription: "Existing description",
        refineMagicTextDescription:
          "Refine your description with custom instructions. You can use the preset options and/or use your own below.",
        presetRefinementsLabel: "Preset refinements",
        customRefinementsLabel: "Custom refinements",
      },
      tooltips: {
        kitchenTags:
          "Tag products to be printed or displayed on the corresponding printers, KDS, or Dispatch screens",
        tags: "Tag products for targeted promotions or segmenting.",
        reportCategory:
          "Reporting categories will be reflected in your reports.",
        horizontalImage: "1:1 aspect ratio",
      },
      subtitle: {
        horizontalImage: "(PNG, JPG up to 10MB)",
      },
      placeholders: {
        noTags: "No existing tags, type to create one",
        kitchenTags: "Link this product to your devices",
        tags: "Enter a new tag or select from the list",
        reportCategory: "Add product to reporting category",
        itemModifierGroups: "No item modifier groups",
        refineDescriptionPlaceholder:
          "e.g. Make it sound festive, it's Christmas.",
      },
      newReportTagModal: {
        buttons: {
          yes: "Create",
          no: "Cancel",
        },
      },
      removeImageConfirmation: {
        title: "This action cannot be undone. Confirm remove image?",
      },
      selectModifierGroups: "Select modifier groups",
      createLeadTimeCondition: "Create lead time condition",
      duplicateAvailabilityConditionsFromSection:
        "Duplicate availability conditions from a section",
      duplicateAvailabilityConditionsFromItem:
        "Duplicate availability conditions from another product",
      createAvailabilityConditionGroup: "Create availability condition group",
      brands: {
        options: {
          unbranded: "No brand",
        },
      },
    },
    searchModal: {
      header: "Search for a product",
    },
    configurationModal: {
      modifierGroupsHeader: "Modifier groups",
      noModifierGroups: "No modifier groups.",
      noModifiers: "No modifiers.",
      included: "Included",
      selection:
        "Selection required - Min: {{min}}; Max: {{max}} | Max qty per modifier: {{maxQuantityPerModifier}}",
      unlimitedQuantityPerModifier: "Unlimited",
    },
  },
  itemModifierGroups: {
    tableColumns: {
      sort: "",
      id: "ID",
      label: "Name",
      identifier: "Modifier group identifier",
      selectionRequiredMin: "Required min",
      selectionRequiredMax: "Required max",
      parentModifierId: "Parent ID",
      isCustomisable: "Customisable?",
      displayOrder: "Display order",
      showThumbnails: "Thumbnails?",
    },
    tooltips: {
      stitching: "The parent modifier group of this item modifier group",
    },
  },
  modifierGroups: {
    title: "Modifier groups",
    filters: {
      brands: {
        all: "All brands",
        none: "No brand",
      },
    },
    actions: {
      downloadCsvTemplate: "Download import CSV template",
      importCsv: "Import CSV",
      add: "Add modifier group",
      confirmImportCsv: "Confirm import",
      archive: {
        label: "Archive",
        confirmation: "Archive {{label}}?",
        batch: "Archive",
        batchInstructions:
          "Tap rows to select modifier groups for batch archiving",
        batchConfirmation: "Archive 1 modifier group?",
        batchConfirmation_plural: "Archive {{count}} modifier groups?",
        success: "{{count}}/{{totalCount}} modifier groups archived",
        unsuccessful: {
          buttonLabel: "1 modifier group could not be archived",
          buttonLabel_plural: "{{count}} modifier groups could not be archived",
          title:
            "The following modifier group is in one or more active menus or POS layouts. Archiving will remove this modifier group from these menus and POS layouts.",
          title_plural:
            "The following modifier groups are in one or more active menus or POS layouts. Archiving will remove these modifier groups from these menus and POS layouts.",
          itemLabel: "{{label}} ({{identifier}})",
          forceArchive: "Archive anyway",
          forceArchiveItemConfirmationTitle:
            "{{label}} is in one or more active menus or POS layouts",
          forceArchiveItemConfirmationBody:
            "Are you sure you want to remove {{label}} from all menus and POS layouts and archive it?",
        },
      },
      unarchive: {
        label: "Unarchive",
        confirmation: "Unarchive {{label}}?",
        batch: "Batch unarchive",
        batchInstructions:
          "Tap rows to select modifier groups for batch unarchiving",
        batchConfirmation: "Unarchive 1 modifier group?",
        batchConfirmation_plural: "Unarchive {{count}} modifier groups?",
        success: "{{count}}/{{totalCount}} modifier groups unarchived",
      },
      itemsCountSelected: "1 modifier group selected",
      itemsCountSelected_plural: "{{count}} modifier groups selected",
      noItemsSelected: "No modifier groups selected",
    },
    modifierGroupStates: {
      active: "Active",
      archived: "Archived",
    },
    importCsv: {
      success: "Upload complete",
      failed: "Modifier groups could not be generated",
      invalidFileExtension: "Only supports .csv extension",
      previewItemsHeader: "Preview",
      newItemsHeader: "Successfully generated",
      warningsHeader: "Warnings",
    },
    duplicate: {
      confirmation: "Duplicate {{label}}?",
      success: "Modifier group duplicated successfully",
    },
    tableColumns: {
      id: "ID",
      label: "Name",
      identifier: "Identifier",
      type: "Type",
      selectionRequired: "Selection required",
      parentModifierId: "Parent modifier ID",
      maxQuantityPerModifier: "Max quantity per modifier",
      printable: "Print on receipt",
    },
    tableValues: {
      selectionRequired: "{{selectionRequiredMin}} - {{selectionRequiredMax}}",
      unlimitedQuantityPerModifier: "No limit",
    },
    form: {
      new: "New modifier group",
      labels: {
        selectionRequiredMinOverride: "Override min selection required",
        selectionRequiredMaxOverride: "Override max selection required",
        isCustomisable: "Customisable",
        showThumbnails: "Show thumbnails",
        type: "Type",
        label: "Name",
        identifier: "ID",
        selectionRequiredMin: "Min selection required",
        selectionRequiredMax: "Max selection required",
        maxQuantityPerModifier: "Max quantity per modifier",
        parentModifierGroup: "Parent item modifier group",
        itemModifierGroupIdPlaceholder: "Select a parent modifier to stitch to",
        printable: "Print on receipt",
        printLargeModifiers: "Print large modifiers",
      },
      subtitles: {
        isCustomisable:
          "Can customers adjust the selection on this modifier group?",
        showThumbnails:
          "Should thumbnails show when customers configure the modifier group?",
        printable: "Should this modifier group print on Atlas receipts?",
        printLargeModifiers:
          "Should selected modifiers be printed larger on receipt?",
      },
      sections: {
        itemModifierGroupDetails: "Item modifier group details",
        modifierGroupDetails: "Modifier group details",
        modifiers: "Modifiers",
      },
      success: {
        create: "Modifier group created successfully",
        update: "Modifier group updated successfully",
      },
      errors: {
        nestedConfigurationModifier:
          "Max selection required must be less than or equal to 1 when your modifiers contain nested configurations ({{modifierLabel}})",
        disallowConfigurableModifiers:
          "Modifier group max selection required must be less than or equal to 1 to contain nested configurations ({{modifierLabel}})",
      },
      change: {
        label: "Change modifier group",
        undo: "Undo",
        saveNeededBeforeChanges:
          "You have to save the current changes before you are able to make changes to the modifier group.",
        saveNeededBeforeModifierChanges:
          "You have to save the current changes before you are able to make changes to the modifiers.",
      },
      selectModifiers: "Select modifiers",
    },
  },
  modifiers: {
    tableColumns: {
      sort: "",
      label: "Name",
      identifier: "Item SKU",
      upsellLabel: "Upsell label",
      addUpsellLabel: "Add label",
      unitPriceFractional: "Price",
      defaultQuantity: "Default quantity",
      displayOrder: "Display order",
      hideThumbnail: "Hide thumbnail",
    },
    form: {
      labels: {
        unitPriceFractionalOverride: "Override price",
        defaultQuantity: "Default quantity",
        item: "Product or Component",
        hideThumbnail: "Hide thumbnail",
      },
      placeholders: {
        item: "Add a product or component",
      },
      sections: {
        modifierDetails: "Modifier details",
      },
      selectItemModal: {
        header: "Select a modifier",
        addItem: "Create a new product or component",
        selectExistingItem: "or select an existing product or component below",
      },
      success: {
        update: "Modifier updated successfully",
      },
      errors: {
        itemIdRequired: "Item field cannot be empty",
      },
    },
    upsellLabelForm: {
      modalTitle: "Add upsell label for self-ordering experiences",
      modalSubTitle:
        "Persuade your customers to select this modifier by adding an upsell label when ordering through self-service channels such as Atlas Online, Atlas Scan to Order, and Atlas Kiosks.",
      form: {
        upsellLabelLabel: "Choose a label from below or create your own.",
        backgroundColorLabel: "Label background color",
        backgroundColorPlaceholder: "Choose a color",
        textColorLabel: "Label text color",
        textColorPlaceholder: "Choose a color",
      },
      objectToDeleteLabel: {
        label: "label",
      },
    },
  },
  leadTimeConditions: {
    header: "Product lead time",
    title: "Product lead time",
    actions: {
      add: "Add product lead time",
    },
    form: {
      sections: {
        leadTimeConditionDetails: "Product lead time details",
      },
      success: {
        create: "Product lead time successfully created",
        update: "Product lead time successfully updated",
      },
      labels: {
        value: "Duration",
        units: "Unit of time",
        leadTimeConditionType: "Reference point",
      },
      placeholder: {
        value: "e.g. 24",
        units: "Select one",
        leadTimeConditionType: "Select one",
      },
      subtitle: {
        value: "The value of the duration.",
        units: "The unit of time of which the duration is in.",
        leadTimeConditionType:
          "The reference point from which we will apply the lead time",
      },
      type: {
        servingDate: "12:00AM of the serving date",
        timeslot: "Serving time slot",
        days: "Days",
        hours: "Hours",
        minutes: "Minutes",
      },
      value: {
        description: "{{value}} {{units}} before {{type}}",
        servingDate: "12:00AM of the serving date",
        timeslot: "the serving time slot",
      },
    },
    tableColumns: {
      id: "ID",
      description: "Description",
    },
  },
  availabilityConditionGroups: {
    header: "Availability",
    form: {
      labels: {
        availableByDefault: {
          title: {
            false: "Available if",
            true: "Unavailable if",
          },
          description: {
            false: "Only available when below conditions are met.",
            true: "Always available except when below conditions are met.",
          },
        },
        hasDateRange: "Only apply for a certain date range?",
        dateRange: "Date range",
        startDate: "Start date",
        endDate: "End date",
        hidden: "Hide item when unavailable?",
        disabledReason: "Reason to display to customer for unavailability",
      },
      placeholders: {
        startDate: "Select a date",
        endDate: "Select a date",
        disabledReason: "e.g. Only available on weekends.",
      },
    },
    availabilityConditions: {
      form: {
        addAndCondition: "And includes a new condition",
        addOrCondition: "Or meets a new condition",
        labels: {
          or: "OR",
          is: "is",
          isBetween: "is between",
          type: {
            OUTLET_IDS: "Outlet",
            FULFILMENT_TYPES: "Fulfilment type",
            DAYS_OF_WEEK: "Serving day of week",
            TIME_RANGE: "Serving time slot",
          },
        },
        placeholders: {
          type: {
            empty: "Select a condition type",
            OUTLET_IDS: "Select at least one outlet",
            FULFILMENT_TYPES: "Select at least one fulfilment type",
            DAYS_OF_WEEK: "Select at least one day of the week",
            TIME_RANGE: "Select a time",
          },
        },
        values: {
          noOutlet: "No outlet",
        },
      },
    },
  },
  stocks: {
    header: "Stocks",
    today: "today ({{ servingDate }})",
    search: {
      label: "Search for item",
      placeholder: "Enter a product name or SKU",
    },
    noLimit: "No limit",
    filters: {
      servingDate: "Date",
      outletId: "Outlet:",
      brandId: "Brand:",
      menuId: "Menu:",
      status: "Status:",
      platforms: "Platforms:",
      type: "Type:",
      placeholders: {
        outlet: "Select outlet",
        brand: "Select brand",
        menu: "Select menu",
        status: "Select status",
        platforms: "Select platform",
        type: "Select type",
        date: "Select date",
      },
      options: {
        status: {
          blocked: "Blocked",
          available: "Available",
          sold_out: "Sold out",
        },
        platforms: {
          in_house: "Atlas",
          grab_food: "GrabFood",
          foodpanda: "Foodpanda",
          deliveroo: "Deliveroo",
        },
        types: {
          Component: "Component",
          Product: "Product",
        },
      },
      reset: "Clear all",
    },
    statuses: {
      available: "Available",
      sold_out: "Sold out",
      blocked: "Blocked",
    },
    syncToGrabFood: "Sync to GrabFood",
    syncToFoodpanda: "Sync to Foodpanda",
    tabs: {
      all: "All",
      single: "Singles",
      component: "Components",
    },
    services: {
      entire: "ENTIRE DAY",
      breakfast: "Breakfast",
      lunch: "Lunch",
      offPeak: "Off-peak",
      happyHour: "Happy hour",
      dinner: "Dinner",
      supper: "Supper",
    },
    tableColumns: {
      label: "Item",
      type: "Type",
      outlet: "Outlet",
      serviceTimings: "Service",
      committed: "Committed",
      quantityLeft: "Quantity left",
      total: "Total",
      isBlocked: "Blocked",
      blockToFuture: "Block to future",
      status: "Status",
      stockRemaining: "Stock remaining",
      menuTypes: "Platforms",
    },
    messages: {
      indefinitely: "Indefinitely",
      today: "today ({{ servingDate }})",
      fromDate: "From {{ servingDate }}",
      untilDate: "Until {{ servingDate }}",
    },
    actions: {
      block: "Block",
      unblock: "Unblock",
      setQuantity: "Set quantity",
      updateQuantity: "Update quantity",
      today: "Block today ({{ servingDate }})",
      later: "Block only on {{ servingDate }}",
      indefinitely: "Block indefinitely",
      untilADate: "Block until a date",
      success: {
        update: "Stock quantity for {{ itemLabel }} is updated",
        blocked: "{{ itemLabel }} is blocked",
        unblocked: "{{ itemLabel }} is unblocked",
        blockedSyncIntegrations:
          "Blocked items have also been sent across to integrated platforms, if any.",
        unblockedSyncIntegrations:
          "Unblocked items have also been sent across to integrated platforms, if any.",
      },
    },
    quantityModal: {
      header: "Set stock for {{product}}",
      committedStocksHeader: "Stocks have been committed for these dates",
      committedStocksBody:
        "1 unit of {{product}} have been pre-sold for the date range selected below",
      committedStocksBody_plural:
        "{{count}} units of {{product}} have been pre-sold for the date range selected below",
      quantityTypeOptions: {
        remaining: "Set remaining quantity left",
        total: "Set total quantity",
      },
      quantityTypeDescriptions: {
        remaining:
          "Set the remaining quantity left to be sold for this period.",
        total:
          "Set the total quantity to be sold for this period, including any pre-sold stocks",
      },
      startEndDatePickerLabel: "Date range",
      quantityLabel: "Quantity",
      quantityLeftLabel: "{{quantityLeft}} left",
      minus10: "-10",
      minus1: "-1",
      plus1: "+1",
      plus10: "+10",
      cancel: "Cancel",
      unset: "Unset",
      set: "Set",
      update: "Update",
    },
    blockModal: {
      header: "Block a date range",
      subheading:
        'Set a period range for which the component "{{product}}" should be blocked.',
    },
  },
  customers: {
    header: "Customers",
    title: "All customers",
    actions: {
      add: "Add customer",
      export: "Export to CSV",
    },
    searchPlaceholder: "Search by name, email, or mobile number",
    tabs: {
      all: "All",
      registered: "Registered",
      guest: "Guest",
    },
    tableColumns: {
      id: "ID",
      title: "Title",
      email: "Email",
      name: "Name",
      mobileNumber: "Mobile number",
      age: "Age",
      isMobileVerified: "Mobile verified?",
      registered: "Registered?",
      marketingConsent: "Marketing consent?",
      ordersCount: "Order count",
      totalSpend: "Total spend",
    },
    form: {
      success: {
        create: "Customer created successfully",
        update: "Customer updated successfully",
      },
      sections: {
        newCustomer: "Create a new customer",
        customerDetails: "Customer details",
      },
      labels: {
        editRestrictedHeader: "Member Atlas ID profile edits are restricted",
        editRestrictedBody:
          "This Atlas ID is used across all Atlas-powered brands and restaurants. Customers can edit their own profiles on your Atlas Online website or Atlas Scan To Order. Users may also contact hello@atlas.kitchen for any Atlas ID profile change requests.",
        isGuest: "Guest registration?",
        title: "Title",
        name: "Name",
        email: "Email",
        mobileNumber: "Mobile number",
        dob: "Date of birth",
        password: "Password",
        showPassword: "Show password",
        autoGeneratePassword: "Generate a password?",
        tags: "Tags",
        marketingConsent: "Marketing consent",
      },
      subtitles: {
        isGuest: "Removes password requirement.",
        marketingConsent:
          "Sign this customer up for marketing emails (requires their consent)",
      },
      placeholders: {
        title: "Select title",
        tags: "Enter a new tag or select from the list",
      },
      errors: {
        email: "Email is required",
        invalidEmail: "Please enter a valid email address",
        mobileNumber: "Mobile number is required",
        guestEmail: "Email or mobile number is required",
        invalidMobileNumber: "Please enter a valid mobile number",
      },
    },
    searchSelect: {
      emptyName: "No name",
      emptyEmail: "No email",
      emptyMobileNumber: "No number",
    },
  },
  promotions: {
    header: "Promo codes",
    title: "All promo codes",
    actions: {
      add: "Add promo code",
    },
    tabs: {
      all: "All",
      promotions: "Promo codes",
      referrals: "Referrals",
    },
    searchPlaceholder: "Search by code",
    tableColumns: {
      labelAndIdentifier: "Name",
      description: "Description",
      code: "Code",
      paymentType: "Payment type",
      promotionType: "Discount type",
      startAndEndDate: "Start / End date",
      isApplicable: "Applicable?",
      value: "Value",
      maxClaims: "Usage",
      promotionConditions: "Conditions",
    },
    tableValues: {
      noLabelAndDescription: "No name or description",
      defaultPaymentType: "Marketing",
      valueCap: "(Capped at {{valueCap}})",
      maxClaimsPerUser: "(Max {{count}} per user)",
      noConditions: "No conditions",
      redemptionCsv: "Redemption CSV",
    },
    form: {
      header: {
        new: "New promotion code",
        edit: "Edit promotion code #{{id}}",
      },
      sections: {
        code: "Promotion code",
        valueAndUsage: "Promotion value & usage",
        conditions: "Promotion conditions",
      },
      values: {
        duplicatedCode: "COPY_OF_{{code}}",
      },
      labels: {
        code: "Promo code",
        startDate: "Start date",
        endDate: "End date",
        label: "Name",
        description: "Description",
        valueType: "Value type",
        valueTypeOptions: {
          fractional: "Fixed amount",
          percent: "Percentage",
        },
        value: "Promotion value",
        valueCap: "Value cap",
        yValue: "Y value",
        nValue: "N value",
        zValue: "Z value",
        yValue_discount_last_y_items_for_every_n_items: "Last Y items",
        nValue_discount_last_y_items_for_every_n_items: "Every N times",
        promotionType: "Promotion type",
        promotionTypeOptions: {
          subtotal_discount: "Sub-total discount",
          total_discount: "Total discount",
          cash_voucher: "Cash voucher",
          delivery_fee_discount: "Delivery fee discount",
          item_discount: "Per eligible item discount",
          lowest_eligible_item_discount: "Lowest eligible item discount",
          discount_last_y_items_for_every_n_items:
            "Discount last Y items every N items",
        },
        promotionTypeDescriptions: {
          subtotal_discount:
            "does not include delivery fees, surcharges, and tax",
          total_discount: "includes delivery fees and surcharges, but not tax",
          cash_voucher: "includes delivery fees, surcharges, and tax",
          item_discount:
            "must pair with condition type '$t(promotions.form.conditions.types.CART_ITEMS_OR)'",
          lowest_eligible_item_discount:
            "must pair with condition type '$t(promotions.form.conditions.types.CART_ITEMS_OR)'",
          discount_last_y_items_for_every_n_items:
            "must pair with condition type '$t(promotions.form.conditions.types.TAGS)'",
        },
        maxClaims: "Max uses in total",
        maxClaimsPerUser: "Max uses per customer",
        maxRedemptions: "Max number of redemptions per use",
        discountModifiers: "Discount modifiers?",
        discountMoreThanItem: "Discount more than item?",
        paymentTypeEnabled: "Assign payment type for full discounts",
      },
      subtitles: {
        discountModifiers:
          "Should the discount be applied to modifiers under this product?",
        discountMoreThanItem:
          "Can discount amount be more than item subtotal amount?",
        paymentTypeEnabled:
          "Orders entirely discounted by this promo will be tagged to a specific payment type",
      },
      placeholders: {
        code: "e.g. 5OFF",
        label: "e.g. 5% off your first purchase",
        value: "e.g. 10",
        valueCap: "No cap",
        maxClaims: "e.g. 100",
        maxClaimsPerUser: "e.g. 1",
        maxRedemptions: "e.g. 2",
      },
      helpText: {
        code: "* Customers will enter this code at checkout.",
        maxClaims: "* Number of times this promo code can be used in total.",
        maxClaimsPerUser:
          "* Number of times this promo code can be used per customer.",
      },
      conditions: {
        add: "Add condition",
        removeConfirmation: "Are you sure you want to remove this condition?",
        types: {
          USER_ID: "User is",
          USER_REGISTERED: "User registered?",
          ORDER_COUNT: "Number of orders is less than",
          CART_ITEMS_OR: "Cart items include either one of",
          CART_ITEMS_AND: "Cart items include all of",
          CART_ITEMS_NOT: "Cart items exclude all of",
          BRAND: "Website is",
          OUTLET: "Outlet is",
          CART_SUBTOTAL: "Cart subtotal (before discount) is at least",
          BIRTHDAY_MONTH:
            "Order serving date is calendar month of customer's birthday",
          SERVING_DATE: "Order serving date is between",
          DAYS_OF_WEEK: "Order serving day of week is",
          SERVING_TIME: "Order serving time is between",
          FULFILMENT_TYPE: "Fulfilment type (delivery, pickup, or dine-in)",
          TAGS: "User or product tags include (case-sensitive)",
        },
        labels: {
          promotionConditionType: "Type of condition",
          error: "Error message",
          startDate: "Start date",
          endDate: "End date",
          daysOfWeek: "Days of week",
          startAndEndTime: "Start and end time",
        },
        placeholders: {
          promotionConditionType: "Select type of condition",
          error: "e.g. This promotion requires minimum order of $15",
          comparison: {
            USER_ID: "Search by name or email",
            ORDER_COUNT: "e.g. 1",
            CART_ITEMS_OR: "Search by name",
            CART_ITEMS_AND: "Search by name",
            CART_ITEMS_NOT: "Search by name",
            BRAND: "Search by name",
            OUTLET: "Search by name",
            CART_SUBTOTAL: "e.g. 30",
            SERVING_DATE: "Select a date",
          },
          minimumCharacters: "Please enter 3 or more characters",
        },
        helpText: {
          error:
            "* Customers will see this messsage if they do not meet the condition.",
          comparison: {
            SERVING_DATE: "* Includes selected dates",
          },
        },
      },
      success: {
        create: "Promotion created successfully",
        update: "Promotion updated successfully",
      },
      error: {
        requiredStartDate: "Start date is required",
        requiredEndDate: "End date is required",
        requiredPromotionConditionType: {
          CART_ITEMS_OR:
            "Selected promotion type must pair with condition type '$t(promotions.form.conditions.types.CART_ITEMS_OR)'",
          TAGS: "Selected promotion type must pair with condition type '$t(promotions.form.conditions.types.TAGS)'",
        },
      },
    },
  },
  cashVouchers: {
    header: "Cash vouchers",
    title: "All cash vouchers",
    actions: {
      add: "Add cash voucher",
    },
    tabs: {
      unique: "Unique",
      nonUnique: "Non-unique",
    },
    searchPlaceholder: "Search by code",
    tableColumns: {
      label: "Name",
      code: "Code",
      value: "Value",
      redemptionsCount: "Redemptions",
      expiresAfter: "Expires after",
      outlets: "Eligible outlets",
    },
    form: {
      success: {
        create: "Cash voucher created successfully",
        update: "Cash voucher updated successfully",
      },
      cashVoucherDetails: "Cash voucher details",
      error: {
        expiresAfter: "Expires after date is required",
      },
      labels: {
        label: "Name",
        description: "Description",
        unique: "Unique?",
        code: "Code",
        value: "Value",
        expiresAfter: "Expires after",
        outlets: "Eligible outlets",
      },
      subtitle: {
        outlets:
          "If no outlets are specified, it will be available to all outlets",
      },
      values: {
        outlets: "{{label}} (#{{id}})",
      },
      placeholders: {
        expiresAfter: "Select date",
        outlets: "All outlets",
      },
    },
  },
  donations: {
    header: "Donations",
    title: "Donations",
    searchPlaceholder: "Search by header",
    tableColumns: {
      headerAndDescription: "Header / Description",
      backgroundImageUrl: "Background image",
      startAndEndDateTime: "Start / End date",
      isActive: "Active?",
      defaultAdded: "Added by default?",
      defaultValue: "Default amount",
      editable: "Amount editable by customer?",
    },
  },
  salesInsights: {
    header: "Sales insights",
  },
  promotionsInsights: {
    header: "Promotion insights",
  },
  productsInsights: {
    header: "Product insights",
  },
  customersInsights: {
    header: "Customer insights",
  },
  salesReport: {
    header: "Sales report",
    exportToCSV: "Export to CSV",
    reportCards: {
      orderCount: "Order count",
      totalOrderValue: "Order value",
      totalDiscounts: "Discounts",
      totalRefunds: "Refunds",
      totalNetSales: "Net sales",
      totalDeliveryFees: "Delivery fees",
      totalGrossSalesPreTax: "Gross sales pre tax",
      totalServiceCharges: "Service charges",
      totalTax: "Tax",
      totalGrossSales: "Gross sales",
    },
    tabs: {
      orders: "Orders",
      payment_types: "Payment types",
      brands: "Brands",
    },
    brands: {
      comingSoon: "Coming soon",
    },
    tableHeaders: {
      orderId: "Order #",
      outlet: "Outlet",
      brand: "Brand",
      paymentType: "Payment type",
      fulfilmentType: "Fulfilment type",
      orderValue: "Order value",
      grossTotalPreTax: "Gross sales (pre-tax)",
      grossTotal: "Gross sales",
      deliveryFee: "Delivery fee",
      deliveryFees: "Delivery fees",
      discount: "Discount",
      discounts: "Discounts",
      refunds: "Refunds",
      serviceCharge: "Service charge",
      tax: "Tax",
      netSales: "Net sales",
      state: "State",
      servingDate: "Serving date and time",
      orderDate: "Order date",
    },
    reportDateTypes: {
      orderDate: "Order date",
      servingDate: "Serving date",
    },
    filters: {
      allOutlets: "All outlets",
      allStorefronts: "All websites",
      sources: {
        in_house: "In-house orders",
        grab_food: "GrabFood",
        foodpanda: "Foodpanda",
        deliveroo: "Deliveroo",
        all: "All orders",
      },
      placeholders: {
        brand: "Brand",
        outlet: "Outlet",
        paymentTypes: "Payment types",
      },
    },
    errors: {
      ios: {
        header: "Please update to iOS 16 to view this page",
        description:
          "This page does not work on Apple devices running iOS15 or lower, please perform a system update to iOS 16 to view this page.",
      },
    },
  },
  fulfilmentReport: {
    header: "Logistics report",
    exportToCSV: "Download",
    filters: {
      servingDate: "Serving date",
      outlet: "Outlets",
      logisticsService: "Logistics service",
    },
    reportCards: {
      orderCount: "Total orders",
      totalSales: "Total net sales",
      totalDeliveryFees: "Total delivery fees",
      totalDeliverySales: "Total delivery sales",
      totalDeliveryCost: "Total delivery cost",
      totalLogisticsCost: "Total logistics cost",
      totalDeliveryFee: "Total customer delivery fee",
      totalDifference: "Logistics cost - Customer delivery fee",
      onTimeDeliveries: "% of on-time deliveries",
      totalTrips: "Bookings",
      totalCancellations: "Cancellations",
      tooltip: {
        totalTrips: "Total number of drivers that were booked.",
        totalCancellations: "Total number of drivers that were cancelled.",
        totalDeliverySales: "Total order sales that were delivered with Atlas.",
        totalLogisticsCost: "Total amount spent on driver bookings.",
        totalDeliveryFee:
          "Total amount paid by customers for the concept of delivery fee.",
        totalDifference:
          "Net amount that will be born for logistics costs. The percentage is compared to the total delivery sales.",
        onTimeDeliveries:
          "Number of deliveries that were delivered within the time slot the customer selected divided by the total of delivery orders.",
      },
    },
    tableHeaders: {
      bookedAt: "Booked at",
      bookedWith: "Booked with",
      orderId: "Order ID",
      orderValue: "Order subtotal",
      outletName: "Outlet name",
      dropOffAddress: "Drop-off address (distance)",
      distance: "Distance",
      logisticsCost: "Logistics cost",
      cancellations: "Cancellations",
      customerDeliveryFee: "Customer delivery fee",
      feeToCostDifference: "Logistics cost - Customer delivery fee",
      pickupTime: "Pickup Time",
      dropOffTime: "Drop off time",
      vehicleType: "Vehicle type",
      orderDate: "Order date",
      servingDate: "Serving date",
      deliveryService: "Delivery service",
      tripDescription: "Trip description",
      orderSource: "Order source",
    },
    form: {
      startDate: "Start date",
      endDate: "End date",
      order: "Order: #",
      notApplicable: "N/A",
      orderSource: {
        web: "Online",
        admin: "Admin",
        grab_food: "GrabFood",
        foodpanda: "Foodpanda",
        deliveroo: "Deliveroo",
      },
      distance: " ({{distance}} Km)",
    },
  },
  dailySummaryReport: {
    header: "Daily summary report",
  },
  adjustmentReport: {
    header: "Adjustment report",
  },
  logisticsMonitoring: {
    header: "Logistics monitoring",
  },
  payoutReport: {
    header: "Payout report",
    disabledMessage: {
      title: "View and see all finance payouts",
      description:
        "Track all payouts processed through Atlas. Contact us to enable this feature.",
    },
  },
  logisticsReportNew: {
    header: "Logistics report",
  },
  customReports: {
    header: "Custom reports",
  },
  integrationLogs: {
    header: "Aggregator logs",
  },
  aggregatorPromotions: {
    header: "Aggregator promotions",
  },
  paymentDiscrepancies: {
    header: "Payment discrepancies",
    loadNextPage: "Load more",
    noNextPage: "All logs loaded",
    selectLog: "Please select a log first.",
    logId: "ID #{{logId}}",
    orderIdentifier: "Order {{orderIdentifier}}",
    orderPaymentId: "Order payment #{{orderPaymentId}}",
    amountWithTip: "({{total}} + {{tip}} tip)",
    status: {
      open: "Open",
      closed: "Closed",
    },
    discrepancyType: {
      authorisation_not_recorded: "Authorisation not recorded",
      missing_order_payment: "Missing order payment",
      order_payment_not_captured: "Order payment not captured",
    },
    cardLast4: " ({{cardLast4}})",
    openOrder: "View order",
    openOrderPayment: "View order payment",
    openOrderPayments: "View all order payments",
    orderPayments: {
      title: "Payments for order {{orderIdentifier}}",
    },
    actions: {
      header: "Action for resolution",
      labels: {
        resolve_order_payment:
          "Create new order payment or override existing order payment",
        reverse_payment: "Release or refund payment",
        capture_payment: "Capture payment",
        mark_as_closed: "Mark as closed",
      },
      closedActionLabel: "Action taken",
      closedReasonLabel: "Reason",
      closedAtLabel: "Closed at",
      closedByEmailLabel: "Closed by",
      confirmation: {
        title: "{{action}}?",
        no: "Go back",
      },
      success: "Payment discrepancy resolved successfully",
    },
  },
  referrals: {
    header: "Referrals",
    title: "Referrals",
    upsell:
      "Contact us at <strong><a href='mailto:team@atlas.kitchen' target='_blank' rel='noreferrer'>team@atlas.kitchen</a></strong> to enable Referrals today!",
    actions: {
      add: "Add referral",
    },
    tableColumns: {
      id: "ID",
      storefront: "Website",
      label: "Title",
      description: "Long description",
      shortDescription: "Short description",
      sidebarLabel: "Sidebar label",
      referrerReward: "Referrer reward",
      refereeReward: "Referee reward",
    },
    tableValues: {
      reward: {
        fractional: "{{value}} off",
        percent: "{{value}}% off",
        percentWithCap: "{{value}}% off (capped at {{valueCap}})",
      },
    },
    form: {
      sections: {
        referralDetails: "Set up your referral program",
        referralDetailsDescription:
          "Define what your user sees on their account's referral page below.",
        referrerRewards: "Referrer reward",
        referrerRewardsDescription:
          "This is what your customer receives when they successfully get a friend to place their first order with their referral code.",
        refereeRewards: "Referee reward",
        refereeRewardsDescription:
          "This is what a new customer gets when using an existing customer's referral code.",
      },
      labels: {
        storefront: "Website",
        label: "Title",
        description: "Long description",
        shortDescription: "Short description",
        sidebarLabel: "Sidebar label",
      },
      placeholders: {
        label: "e.g. Share the burger love!",
        description:
          "e.g. Share this code with your friends to receive discounts during your next meal!",
        sidebarLabel: "e.g. Refer a friend",
      },
      subtitle: {
        label: "Give your referral campaign a catchy name.",
        description:
          "Let your customers know what your referral program is all about including the terms and conditions of the promo, this will show in their account pages.",
        shortDescription:
          "Give your customers a short blurb to attract them to refer a friend, this will show in their order confirmation page and email.",
        sidebarLabel:
          "This is the link label your users will see on the account page.",
      },
      rewards: {
        labels: {
          valueType: "Value type",
          valueTypeOptions: {
            fractional: "Fixed amount",
            percent: "Percentage",
          },
          value: "Referral value",
          valueCap: "Value cap",
        },
        placeholders: {
          valueCap: "No cap",
        },
      },
      success: {
        create: "Referral created successfully",
        update: "Referral updated successfully",
      },
    },
  },
  loyalty: {
    header: "Loyalty",
  },
  installedApps: {
    header: "Installed apps",
    configure: "Configure",
    configuration: "Configuration",
    save: "Save",
    update: {
      success: "Successfully updated configuration",
    },
  },
  dripCampaigns: {
    header:
      "Email drip <span class='inline-flex font-medium bg-blue-100 text-blue-800 ml-2 relative top-[-2px] py-1 px-2 rounded-full text-xs leading-4'>BETA</span>",
    inactive: {
      header: "Increase repeat orders by up to 50% with automated email drips",
      body: "<div>Email drips are automatically triggered e-mails, segmented to your different customers to drive more purchases, and to reactivate customers that have not ordered for some time.</div><div>Speak to your account manager or email <a class='font-semibold' href='mailto:team@atlas.kitchen'>team@atlas.kitchen</a> to enable automated email drips for $199/month.</div>",
    },
    active: {
      header: "Your email drips are working their magic",
      body: "<div>Your account manager will be sharing your monthly email drip reports with you.</div><div>Have more ideas on how we can make this better? <a class='underline hover:underline' target='_blank' href='mailto:team@atlas.kitchen' rel='noreferrer'>Let us know.</div>",
    },
  },
  loyaltyProgram: {
    header:
      "Loyalty <span class='inline-flex font-medium bg-blue-100 text-blue-800 ml-2 relative top-[-2px] py-1 px-2 rounded-full text-xs leading-4'>BETA</span>",
    inactive: {
      header: "10X your customer retention with our loyalty program",
      body: "<div>Increase brand love and retain your customers by rewarding and motivating them when they make purchases and engage with you on a continued basis.</div><div>Speak to your account manager or email <a class='font-semibold' href='mailto:team@atlas.kitchen'>team@atlas.kitchen</a> to enable our loyalty program for $199/month.</div>",
    },
  },
  outlets: {
    header: "Outlets",
    title: "All outlets",
    tableColumns: {
      identifier: "ID",
      label: "Outlet name",
      labelForPickup: "Label for pickup",
      address: "Address",
      contact: "Contact",
    },
    tableValues: {
      archived: "Archived",
    },
    actions: {
      add: "Add outlet",
      archive: {
        label: "Archive",
        confirmation: "Archive {{label}}?",
      },
      unarchive: {
        label: "Unarchive",
        archivedLabel: "Archived, click here to unarchive",
        confirmation: "Unarchive {{label}}?",
      },
    },
    form: {
      success: {
        create: "Outlet created successfully",
        update: "Outlet updated successfully",
      },
      header: "Outlet details",
      sections: {
        headers: {
          outletDetails: "General details",
          operatingHours: "Operating hours",
          receipts: "Receipts",
          serviceCharges: "Service charges",
          deliverySettings: "Delivery settings",
          orderValueSettings: "Large orders",
          stations: "Stations",
          printers: "Printers",
          paymentTypes: "Payment types",
          payments: "Payments",
          channels: "Channels",
        },
        byline: {
          serviceCharges: "Configure dine in service charges for your brands",
        },
        serviceCharges: {
          noBrandsAdded: "No brands added",
          message:
            "There are currently no brands attached to this outlet, please add a brand under channels at the bottom of this page.",
          headers: {
            brand: "Brand",
            enabled: "Enabled",
            serviceCharge: "Service charge %",
          },
        },
        operatingHours: {
          headers: {
            regularOperatingHours: "Regular operating hours",
            specialHours: "Special hours",
          },
          specialHours: {
            tabs: {
              past: "Past dates",
              upcoming: "Upcoming dates",
            },
            type: {
              OpenWithSpecialHours: "Open with special hours",
              ClosedDate: "Closed",
              AsUsualHours: "As usual",
            },
            defaultMessage: "We are closed today.",
            defaultMessageCode: "CLOSED",
          },
        },
        orderValueSettings: {
          form: {
            success: {
              create: "Large order configured successfully",
              update: "Large order configuration updated successfully",
              destroy: "Large order configuration deleted successfully",
            },
          },
          headers: {
            orderValueSettingLeadTime: "Large order lead time",
          },
          sections: {
            orderAmount: "Orders above",
            additionalLeadTime: "will require an additional",
          },
        },
        printers: {
          success: "Printer updated successfully",
          tableColumns: {
            outletBrand: "Brand",
            name: "Name",
            serialNumber: "Serial number",
            copies: "No. of copies",
            type: "Type",
            config: "Config",
          },
          tableValues: {
            deliveryReceiptPrinter: "Delivery receipt",
            customerReceiptPrinter: "Customer receipt",
            kitchenReceiptPrinter: "Kitchen receipt",
            itemReceiptPrinter: "Item receipt",
            labelPrinter: "Label",
            isPosLinked: "Print on POS checkout?",
            isOnlineLinked: "Print on online checkout?",
            includeTagList: "Included tags:",
            excludeTagList: "Excluded tags:",
            matchSubItemTags:
              "Include sub items with matching tags as a separate item?",
            excludeSubItemTagList:
              "Sub items with matching tags to exclude in configuration:",
          },
        },
        outletBrandConfigs: {
          headers: {
            add: "Add brand to outlet",
          },
          actions: {
            add: "Add",
            addBrand: "+ Add brand",
          },
        },
        stations: {
          edit: "Edit",
          noStations: "No stations",
        },
      },
      error: {
        contactNumber: "Invalid phone number",
      },
      labels: {
        label: "Outlet name",
        uen: "UEN",
        brn: "BRN",
        description: "Description",
        labelForPickup: "Label for pickup",
        address: "Address",
        postalCode: "Postal code",
        longitude: "Longitude",
        latitude: "latitude",
        contactNumber: "Contact number",
        email: "Email",
        isAutoPrintEnabled: "Auto-print enabled?",
        autoPrintLeadTime: "Auto print lead time",
        deliveryFeeConfigId: {
          header: "Delivery fee",
          id: "ID",
          baseDistance: "Base distance (km)",
          baseRate: "Base rate ($)",
          perKmRate: "Per km rate ($/km)",
          cartValueSubsidyPercentage: "Subsidy off total cart value (%)",
          deliveryFeeWaiverCartValue: "Free delivery at ($)",
          minimumOrderForSubsidy: "Min cart value for subsidy ($)",
        },
        showContactNumberInReceipt:
          "Show outlet contact number on customer receipts",
        customPrintedReceiptFooter: "Custom printed receipt footer text",
        customDigitalReceiptFooter: "Custom digital receipt footer text",
        outletPaymentProcessors: "Payment processors",
        outletPaymentMethods: "Payment processors",
        outletPaymentMethodsLegacy: "Payment processors (LEGACY)",
        outletPaymentTypes: "POS Payment types",
        ecommercePaymentTypes: "E-commerce Payment types",
        serviceZone: "Service zone",
      },
      subtitle: {
        label: "This is the outlet’s name that your customers will see.",
        uen: "Your outlet's Unique Entity Number",
        brn: "Your outlet's Business Registration Number",
        description:
          "This is the outlet’s description that your customers will see.",
        labelForPickup:
          "This will be displayed on the confirmation email sent to your customers.",
        contactNumber:
          "This is the contact number your customers will call you at.",
        email: "This is the email address your customers will email you at.",
        address:
          "Your outlet’s address. All orders will originate from the address specified here.",
        postalCode: "Your outlet’s postal code.",
        deliveryFeeConfig:
          "All orders from this outlet will be subjected to the delivery charges as specified here.",
        customPrintedReceiptFooter:
          "Custom text to display at the end of your printed receipts",
        customDigitalReceiptFooter:
          "Custom text to display at the end of your digital email receipts",
        outletPaymentProcessors:
          "Your customers will be able to purchase your products using the payment processors specified here.",
        outletPaymentMethods:
          "Your customers will be able to purchase your products using the payment processors specified here.",
        outletPaymentMethodsLegacy:
          "[This will be deprecated, do not use this if using Atlas Pay] Your customers will be able to purchase your products using the payment processors specified here.",
        outletPaymentTypes:
          "Select payment types that are available for the outlet POS. All globally available POS payment types will be available if none are selected below.",
        ecommercePaymentTypes:
          "Select payment types that are available for the outlet E-commerce (STO/Online). All globally available E-commerce payment types will be available if none are selected below.",
      },
      placeholder: {
        label: "e.g. Valley Hungry",
        uen: "e.g. 201888888A",
        description: "e.g. Gourmet burgers and more",
        labelForPickup: "e.g. Valley Hungry @ Changi Airport T2, L3",
        address: "e.g. 25 Valley Way Ave 3",
        postalCode: "e.g. 239924",
        email: "e.g. burgers@valleyhungry.com",
        deliveryFeeConfig: "Select one",
        outletPaymentMethods: "Select at least one",
        outletPaymentProcessors: "Select at least one",
        outletPaymentTypes: "Select payment types",
      },
      values: {
        deliveryFeeConfigId: {
          details:
            "[ID: #{{id}}] ${{baseRate}} for first {{distanceKm}}km, ${{perKmRate}}/km thereafter; Cart subsidy: {{cartValueSubsidyPercentage}}% of order value; Free delivery above: ${{deliveryFeeWaiverCartValue}}; Min cart value for subsidy: ${{minimumOrderForSubsidy}}",
          id: "{{id}}",
          baseDistance: "{{baseDistance}} km",
          baseRate: "${{baseRate}}",
          perKmRate: "${{perKmRate}}/km",
          cartValueSubsidyPercentage: "{{cartValueSubsidyPercentage}}%",
          deliveryFeeWaiverCartValue: "${{deliveryFeeWaiverCartValue}}",
          noDeliveryFeeWaiver: "N/A",
          minimumOrderForSubsidy: "${{minimumOrderForSubsidy}}",
        },
        noDeliveryFeeWaiver: "N/A",
        outletPaymentProcessors: "{{label}} (ID:#{{id}})",
        outletPaymentMethods: "[{{type}}] {{label}} (ID:#{{id}})",
        createOutletFirst: "Create outlet first.",
        createServiceZone: "Create service zone",
        editServiceZone: "Edit service zone",
        createNewDeliveryFeeConfig: "Create new delivery fee",
        createNewPrinter: "Create new printer",
        createNewStation: "Create new station",
        createNewPaymentProcessor: "Create new payment processor",
        createNewPaymentMethod: "Create new payment processor",
        createNewPaymentMethodLegacy: "Create new payment processor (LEGACY)",
        outletPaymentProcessorRequired:
          "Payment processor is required to receive orders for this outlet.",
        outletPaymentMethodRequired:
          "Payment processor is required to receive orders for this outlet.",
        createDeliveryFeeConfigsFirst: "Create delivery fee first",
        objectToDeleteLabel: {
          specialHours: "Special hours: #{{id}}",
          printers: "the printer <strong>{{label}}</strong>",
          printerConfigs:
            "the printer config linked to <strong>{{label}}</strong>",
          kioskConfigs: "the kiosk config linked to <strong>{{label}}</strong>",
          paymentTerminalConfigs:
            "the payment terminal config linked to <strong>{{label}}</strong>",
        },
        noBrandsCreatedYet:
          "There is currently no brands associated with this outlet. Please contact us at team@atlas.kitchen to enable this.",
        createOrderValueSettingLeadTime: "Create large order lead time",
      },
      errors: {
        characterLimit: "Max character limit of {{limit}}",
      },
    },
  },
  timeRanges: {
    actions: {
      addNewServiceHours: "Add new service hours",
    },
    tableColumns: {
      dayOfWeek: "",
    },
    tableValues: {
      summary: "{{startTime}} - {{endTime}}",
      closed: "Closed",
      noData: "--",
      activeDaysOfWeek: {
        0: "Sunday",
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
      },
      dateRange: "{{startDate}} - {{endDate}}",
      startDateOnly: "{{startDate}} onwards",
      endDateOnly: "Until {{endDate}}",
      rangeLabel: "({{startTime}} - {{endTime}})",
      duration: "{{durationInMinutes}} min time slots",
      interval: "{{intervalInMinutes}} min interval",
      leadTime: "{{leadTimeInMinutes}} min lead time",
    },
    form: {
      success: {
        create: "Operating hours created successfully",
        update: "Operating hours updated successfully",
        destroy: "Operating hours deleted successfully",
      },
      timeRangeDetails: "Time range details",
      labels: {
        fulfilmentType: {
          "delivery,pickup": "General (delivery and pickup)",
          delivery: "Delivery",
          pickup: "Pickup",
          dine_in: "Dine-in",
        },
        label: "Name",
        timeRange: "Time range",
        interval: "Time slot interval",
        duration: "Time slot duration",
        leadTime: "Lead time cut off",
        operatingDays: "Operating days",
      },
      subtitles: {
        label: "The name of this group of time slots.",
        timeRange: "Timing your first and last orders can be pushed out.",
        interval: "Gap between the start time of each time slot, in minutes.",
        duration:
          "Length of each time slot, in minutes (30 minutes minimum recommended).",
        leadTime:
          "Amount of buffer time for orders to come in, in minutes (includes time to cook and pack the food).",
      },
      placeholders: {
        interval: "e.g. 15",
        duration: "e.g. 30",
        leadTime: "e.g. 30",
      },
      removeConfirmation: "Are you sure you want to remove this time range?",
      preview: {
        header: "Preview if current time is",
        for: "for",
        fulfilmentType: {
          delivery: "delivery",
          pickup: "pickup",
          dine_in: "dine-in",
        },
        error: "Please fill up all the fields first",
        noteForDelivery:
          "Delivery time slots automatically include an additional 30 minutes lead time for couriers to deliver.",
      },
    },
  },
  specialHours: {
    actions: {
      add: "Add new special hours",
      addTimeRange: "Add time range",
    },
    form: {
      success: {
        create: "Special hours created successfully",
        update: "Special hours updated successfully",
        destroy: "Special hours deleted successfully",
      },
      labels: {
        name: "Name",
        dateRange: "Date range",
        type: "Status",
      },
      placeholder: {
        name: "e.g. National Day 2021",
        startDate: "e.g. 9 Aug 2021",
        endDate: "e.g. 9 Aug 2021",
      },
      errors: {
        dates: "Please select a start date and end date",
        type: "Please select a status",
      },
    },
  },
  deliveryFeeConfigs: {
    header: "Delivery fee settings",
    title: "Delivery fee",
    actions: {
      add: "Add delivery fee",
    },
    form: {
      deliveryFeeConfigDetails: "Delivery fee details",
      success: {
        update: "Delivery fee successfully updated",
        create: "Delivery fee successfully created",
      },
      labels: {
        baseDistance: "Base distance (km)",
        baseRate: "Base rate ($)",
        perKmRate: "Per km rate ($/km)",
        cartValueSubsidyPercentage: "Cart value subsidy (%)",
        deliveryFeeWaiverCartValue: "Free delivery at ($)",
        minimumOrderForSubsidy: "Min cart value for subsidy ($)",
        isTiersStackable: "Stack delivery fee tiers?",
      },
      subtitle: {
        baseDistance: "The starting distance that the base rate will cover",
        baseRate: "The rates for the base distance",
        perKmRate: "The rates per km after the base distance",
        cartValueSubsidyPercentage: "Subsidized rates based on cart value",
        deliveryFeeWaiverCartValue:
          "The cart value after which delivery is free",
        minimumOrderForSubsidy:
          "The minimum cart value before the subsidy percentage is applied",
      },
      placeholder: {
        baseDistance: "e.g. 8",
        baseRate: "e.g. 8",
        perKmRate: "e.g. 0.8",
        cartValueSubsidyPercentage: "e.g. 5",
        deliveryFeeWaiverCartValue: "e.g. 100",
        minimumOrderForSubsidy: "e.g. 50",
      },
    },
    tableColumns: {
      id: "ID",
      baseDistance: "Base distance (km)",
      baseRate: "Base rate ($)",
      perKmRate: "Per km rate ($/km)",
      cartValueSubsidyPercentage: "Cart value subsidy (%)",
      deliveryFeeWaiverCartValue: "Free delivery at ($)",
      minimumOrderForSubsidy: "Min cart value for subsidy ($)",
    },
    tableValues: {
      baseDistance: "{{baseDistance}} km",
      baseRate: "{{baseRate}}",
      perKmRate: "{{perKmRate}}/km",
      cartValueSubsidyPercentage: "{{cartValueSubsidyPercentage}}%",
      deliveryFeeWaiverCartValue: "{{deliveryFeeWaiverCartValue}}",
      noDeliveryFeeWaiver: "N/A",
      minimumOrderForSubsidy: "{{minimumOrderForSubsidy}}",
    },
    deliveryFeeTiers: {
      header: "Delivery fee tiers (view only)",
      tableColumns: {
        baseDistance: "Tier minimum distance (km)",
        maxDistance: "Tier maximum distance (km)",
        baseRate: "Base rate ($)",
        perKmRate: "Per km rate ($/km)",
      },
      tableValues: {
        baseDistance: "{{baseDistance}} km",
        maxDistance: "{{maxDistance}} km",
        baseRate: "{{baseRate}}",
        perKmRate: "{{perKmRate}}/km",
      },
    },
    deliveryFeeSubsidyTiers: {
      header: "Delivery fee subsidy tiers (view only)",
      tableColumns: {
        minimumOrderValue: "Tier minimum cart value ($)",
        maximumOrderValue: "Tier maximum cart value ($)",
        subsidyValue: "Subsidy",
        subsidyValueType: "Subsidy type",
      },
      tableValues: {
        minimumOrderValue: "{{minimumOrderValue}}",
        maximumOrderValue: "{{maximumOrderValue}}",
        subsidyValue: {
          fractional: "{{subsidyValue}}",
          percentage_cart: "{{subsidyValue}}%",
          percentage_delivery_fee: "{{subsidyValue}}%",
        },
        subsidyValueType: {
          fractional: "Fixed amount",
          percentage_cart: "Percentage of cart",
          percentage_delivery_fee: "Percentage of delivery fee",
        },
      },
    },
  },
  couriers: {
    header: "In-house couriers",
  },
  "service-zones": {
    header: "Service zones",
  },
  serviceZones: {
    header: "Service zones",
    title: "Service zones",
    form: {
      serviceZoneDetails: "Service zone details",
      success: {
        update: "Service zone successfully updated",
        create: "Service zone successfully created",
      },
      labels: {
        islandwide: "Apply islandwide delivery?",
        islandwideName: "Islandwide",
        name: "Name",
        radius: "Set service radius, in KM",
        radiusUnit: "KM",
      },
      subtitles: {
        islandwide:
          "Islandwide delivery toggle is not stored in the database, but applied",
        radius: "Radius is not stored in the database, but applied",
      },
      errors: {
        name: "Name is a required field",
        radius: "Radius is a required field",
      },
    },
    tableColumns: {
      outlet: "Outlet",
      serviceZones: "Service zones",
    },
    tableValues: {
      serviceZones: "{{name}} (ID:#{{id}})",
    },
  },

  "business-days": {
    header: "Business days",
  },
  paymentMethods: {
    header: "Payment processors (Legacy)",
    title: "All payment processors",
    tableColumns: {
      id: "ID",
      data: "Config",
      name: "Name",
      payoutSchedule: "Payout schedule",
    },
    actions: {
      add: "Add payment processor",
    },
    tableData: {
      accountId: "Account ID:",
      accountHolderCode: "Account holder code:",
      storeId: "Store ID:",
      bankAccountUuid: "Bank account UUID:",
      mdr: "Merchant discount rate (MDR):",
      none: "None",
      cloudScheduled: "Cloud scheduler",
      scheduled: "Scheduled",
      unscheduled: "Unscheduled",
      createSchedule: "Create",
      deleteSchedule: "Remove",
      na: "N/A",
      paymentUen: "Payment UEN:",
      paymentMobileNumber: "Payment mobile number:",
      payoutStartDate: "Payout start date:",
      payoutCaptureDelay: "Payout capture delay:",
      payoutFrequency: "Payout frequency:",
    },
    fixAdyenAccount: {
      title:
        "Please finish setting up this payment processor. Adyen will suspend this account if verification is not passed within 72 days.",
      description: {
        beforeAction: "",
        action: "Click here",
        afterAction:
          " to complete the onboarding process for this payment processor. An incomplete setup may cause issues when accepting payments from your customers.",
      },
    },
    form: {
      paymentMethodDetails: "Payment processor details",
      labels: {
        label: "Name of payment processor",
        account_id: "Account ID",
        store_id: "Store ID",
        account_holder_code: "Account holder code",
        bank_account_uuid: "Bank account UUID",
        processing_fee: "Processing fee (%)",
        processing_fee_in_cents: "Processing fee (Cents)",
        payment_uen: "Payment UEN",
        payment_mobile_number: "Payment mobile number",
        outlets: "Linked outlets",
        type: "Payment type",
        api_public_key: "API public key",
        account_type: "Account type",
        payout_start_date: "Payout start date",
        payout_capture_delay: "Payout capture delay",
        payout_frequency: "Payout frequency",
      },
      subtitle: {
        label: "Name of your company",
        account_id: "The identification number of your account.",
        store_id: "The store ID on Adyen",
        account_holder_code: "The account holder code of your account",
        bank_account_uuid: "The bank account UUID on Adyen",
        processing_fee: "The percentage of processing fee charged to customers",
        processing_fee_in_cents:
          "An additional processing fee in cents per transaction",
        payment_uen: "The PayNow payment UEN.",
        payment_mobile_number:
          "The mobile number used for your PayNow account.",
        outlets:
          "Outlets selected here will have access to this payment processor.",
        type: "The payment processor provider.",
        api_public_key:
          "The Omise API public key. You can obtain this from your Omise account.",
        account_type: "The type of your merchant account (Ecommerce or POS).",
        payout_start_date:
          "When the payout should start collating payments from",
        payout_capture_delay:
          "Days to wait after payment capture to be considered captured in the payout",
        payout_frequency: "How many days between each payout",
      },
      placeholder: {
        label: "Valley Hungry",
        account_id: "Enter your account ID here.",
        store_id: "Enter your store ID here.",
        account_holder_code: "Enter your account holder code here.",
        bank_account_uuid: "Enter your bank account UUID here",
        processing_fee: "Enter your processing fee in percentage here.",
        processing_fee_in_cents: "Enter your processing fee in cents here.",
        payment_uen: "Enter your payment UEN here.",
        payment_mobile_number: "+65 91234567",
        outlets: "Select at least one",
        type: "Select one",
        api_public_key: "Enter your API public key here.",
        payout_capture_delay: "Enter payout capture delay in days",
        payout_frequency: "Enter payout frequency in days",
      },
      success: {
        create: "Payment processor successfully created",
        createSchedule: "Payouts scheduler created",
        deleteSchedule: "Payouts scheduler removed",
        update: "Payment processor successfully updated",
      },
      values: {
        stripePaymentMethod: "Stripe",
        omisePaymentMethod: "Omise",
        payNowPaymentMethod: "PayNow",
        adyenPaymentMethod: "Adyen",
        outlets: "{{label}} (#{{id}})",
        adyenEcommerce: "Ecommerce",
        adyenPos: "POS",
      },
      sections: {
        linkedOutlets: "Linked outlets",
      },
      error: {
        contactNumber: "Invalid contact number",
      },
    },
  },
  paymentProcessors: {
    header: "Payment processors",
    title: "All payment processors",
    tableColumns: {
      id: "ID",
      label: "Label",
      storeProcessorId: "Store processor ID",
      outlets: "Outlets",
    },
    actions: {
      add: "Add payment processor",
    },
    form: {
      paymentProcessorDetails: "Payment processor details",
      labels: {
        label: "Name of payment processor",
        storeProcessorId: "Store processor ID",
        apiKey: "API Key",
        apiSecret: "API Secret",
        outlets: "Linked outlets",
      },
      subtitle: {
        label: "Name of your company",
        storeProcessorId: "The store processor UUID in Atlas Pay",
        apiKey: "The API key for your payment processor",
        apiSecret: "The API secret for your payment processor",
        outlets:
          "Outlets selected here will have access to this payment processor.",
      },
      placeholder: {
        label: "Adyen Feast",
        storeProcessorId: "Enter your store processor ID here.",
        apiKey: "Enter your API key here.",
        apiSecret: "Enter your API secret here.",
        outlets: "Select at least one",
      },
      success: {
        create: "Payment processor successfully created",
        update: "Payment processor successfully updated",
      },
      values: {
        outlets: "{{label}} (#{{id}})",
      },
      sections: {
        linkedOutlets: "Linked outlets",
      },
      error: {
        contactNumber: "Invalid contact number",
      },
    },
  },
  brands: {
    header: "Brands",
    title: "All brands",
    actions: {
      add: "Add brand",
      archive: {
        label: "Archive",
        confirmation: "Archive {{label}}?",
      },
      unarchive: {
        label: "Unarchive",
        archivedLabel: "Archived on {{archivedAt}}, click here to unarchive",
        confirmation: "Unarchive {{label}}?",
      },
    },
    tableColumns: {
      logoUrl: "Logo",
      id: "ID",
      label: "Name",
      description: "Description",
      minimumOrderValue: "Min order value ($)",
      type: "Storefront?",
      archivedAt: "Archived?",
    },
    form: {
      success: {
        create: "Brand created successfully",
        update: "Brand updated successfully",
        website: "Website created successfully",
        customDomain: "Custom domain created successfully",
      },
      brandDetails: "Brand details",
      brandPersonality: "Brand personality",
      labels: {
        identifier: "Identifier",
        label: "Label",
        description: "Description",
        dinerBaseUrl: "Website menu URL",
        dinerCustomDomain: "Website custom domain",
        type: "Storefront?",
        minimumOrderValue: "Min order value ($)",
        headerText: "Header text color",
        headerBackground: "Header background color",
        logoUrl: "Logo",
        taxRates: "Tax rates",
        taxInclusivePrices: "Tax-inclusive prices?",
        serviceChargeRate: "Service charge rate",
        cuisine: "Cuisine",
        tone: "Tone",
        pricePoint: "Price point",
      },
      pricePoints: {
        affordable: "Affordable",
        midrange: "Mid-range",
        highEnd: "High end",
      },
      subtitles: {
        identifier:
          "The unique reference for this brand that is only visible to merchant admin. E.g. atlas-restaurant",
        label:
          "Name of the brand that is visible to everyone, e.g. Atlas Restaurant",
        dinerBaseUrl: "E.g. https://my-restaurant.atlas.kitchen",
        defaultColor: "Default: {{defaultColor}}",
        serviceChargeRate: "This rate applies for dine-in orders only.",
      },
      values: {
        taxRates: "{{key}}: {{value}}%",
      },
      errors: {
        dinerBaseUrl: "Invalid format. $t(brands.form.subtitles.dinerBaseUrl).",
        dinerCustomDomain:
          "Invalid format. $t(brands.form.subtitles.dinerBaseUrl).",
        missingHex: "Missing # prefix, e.g. #fffaaa",
        invalidColor: "Format must follow #fff, #fffaaa, or #fffaaa99",
      },
      actions: {
        createOnlineWebsite: "Create online website",
        createCustomDomain: "Create custom domain",
      },
    },
  },
  kitchenDisplays: {
    header: "Kitchen displays",
    title: "All kitchen displays",
    actions: {
      add: "Add kitchen display",
    },
    tableColumns: {
      id: "ID",
      name: "Name",
      outletId: "Outlet",
      includeTagList: "Include tags",
      excludeTagList: "Exclude tags",
    },
    form: {
      success: {
        create: "Kitchen display created successfully",
        update: "Kitchen display updated successfully",
      },
      kitchenDisplayDetails: "Kitchen display details",
      labels: {
        id: "ID",
        name: "Name",
        outletId: "Outlet",
        stationId: "Station",
        filteredFulfilmentTypes: "Fulfilment types",
        viewingMode: "Viewing mode",
        includeTags: "Include tags",
        excludeTags: "Exclude tags",
      },
      placeholders: {
        filteredFulfilmentTypes: "Leave blank to show for all fulfilment types",
      },
    },
  },
  tileDisplay: {
    actions: {
      view: "View",
      search: "Search",
      cancel: "Cancel",
      clear: "Clear",
      setIframeUrl: "Set iframe URL",
      reload: "Reload",
    },
    labels: {
      empty: {
        title: "Great job!",
        subtitle: "There is nothing left to prepare.",
      },
      recallPrompt: "Recall completed orders here",
      kdsSettings: "Make changes to your KDS here",
      searchPlaceholder: "Search for order ID",
    },
    order: {
      cancelled: "Cancelled",
      voided: "Voided",
      cutleryNotRequired: "No cutlery",
      buzzerIdentifier: "Buzzer {{buzzerIdentifier}}",
    },
    orderItem: {
      customIdentifier: "Bowl {{customIdentifier}}",
    },
    settings: {
      headers: {
        dispatcher: "Dispatcher settings",
        kds: "Kitchen display settings",
      },
      labels: {
        filteredFulfilmentTypes: "Fulfilment types",
        viewingMode: "Viewing mode",
        showProductionSummary: "Show production summary",
        showCustomerNameInDispatcher: "Show customer name",
        includeTagList: "Include tags",
        excludeTagList: "Exclude tags",
        includeTags: "Include tags",
        excludeTags: "Exclude tags",
        fontSize: "Card size",
        tapToCompleteSingleQuantity: "Tap on tile behaviour",
        tapToCompleteMode: "Tap on tile behaviour",
        showStorefrontAbbreviation: "Show brand before order ID",
        disableAnimations: "Animations",
        kdsChime: "Tone / Chime",
        callerTheme: "Caller theme",
        callerCardSize: "Caller card size",
        callerPreparingSectionSize: "Caller preparing section size",
        prepareAtLeadTimeMinutesKDS: 'Lead time to display items on "Now" tab',
        prepareAtLeadTimeMinutesDispatch:
          'Lead time to display items on "Dispatching" tab',
        prepareAtLeadTimeMinutesSuffix: "mins before prepare by time",
        prepareAtLeadTimeMinutesDescription:
          'Items beyond this lead time will remain in the "Later today" tab',
        warningTimeType: "Timestamp type",
        warningLevelsLeadTimeMinutes: "Colour status (based on timestamp)",
        warningLevelsLeadTimeMinutesPrefix: "Level {{level}}",
        warningLevelsLeadTimeMinutesPrefixMobile: "Lv {{level}}",
        warningLevelsLeadTimeMinutesSuffix: "minutes elapsed",
        warningLevelsLeadTimeMinutesSuffixMobile: "mins",
        orderState: "Order state",
        orderPrepareByTiming: "Order prepare by timing",
        orderSentAtTiming: "Order sent at timing",
      },
      values: {
        tapToCompleteSingleQuantity: {
          true: "Each tap completes one quantity at a time",
          false: "Tap completes all quantities of that tile",
        },
        tapToCompleteMode: {
          single: "Each tap completes one quantity at a time",
          max: "Tap completes all quantities of that tile",
          all: "Tap completes all tiles in the order",
        },
        viewingMode: {
          card: "Card",
          row: "Row",
        },
        showStorefrontAbbreviation: {
          true: "Show",
          false: "Hide",
        },
        showCustomerNameInDispatcher: {
          true: "Show",
          false: "Hide",
        },
        showProductionSummary: {
          true: "Enable",
          false: "Disable",
        },
        disableAnimations: {
          true: "Disable",
          false: "Enable",
        },
        kdsChime: {
          consequence: "Order jingle",
          chimeBell: "Chime bell",
        },
        warningTimeType: {
          orderPrepareBy: "Prep by time",
          sentAt: "Count-up timer",
        },
        callerTheme: {
          black: "Black",
          dark: "Dark",
          light: "Light",
        },
        callerCardSize: {
          regular: "Regular",
          large: "Large",
          extraLarge: "X-Large",
        },
        callerPreparingSectionSize: {
          hidden: "Hidden",
          small: "Small (1/3)",
          large: "Large (1/2)",
        },
      },
      fontSize: {
        13: "Small (13px)",
        14: "Medium (14px)",
        16: "Large (16px)",
        19: "Extra large (19px)",
        22: "Huge (22px)",
      },
      preview: {
        header: "Preview",
      },
    },
  },
  kitchenDisplaySystem: {
    header: "Kitchen Display System",
    views: {
      NOW: "Now",
      LATER: "Later today",
      PRE_ORDERS: "Upcoming days",
      RECALL: "Completed",
    },
    actions: {
      view: "View",
      settings: "Settings",
      search: "Search",
      cancel: "Cancel",
      clear: "Clear",
      exit: "Exit",
      reload: "Reload",
    },
    errors: {
      outOfSync:
        "{{error}} occurred and the KDS is out of sync. Please reload the page.",
      graphql:
        '$t(kitchenDisplaySystem.errors.outOfSync, { "error": "A server error" })',
      network:
        '$t(kitchenDisplaySystem.errors.outOfSync, { "error": "A network error" })',
    },
    productionSummary: {
      header: "Production summary",
      earliestPrepareBy: "Earliest prep:",
    },
    order: {
      withTableName: "{{orderDisplayId}} ({{orderTableName}})",
      actions: {
        updateOrderNotes: "Update order note",
        completeAllItems: "Complete all items",
        moveCard: "Move card",
      },
      orderNotesModal: {
        title: "Order notes for #{{order}}",
        labels: {
          notes: "Modify order notes",
        },
        placeholders: {
          notes: "e.g. Celebrating their 30th wedding anniversary",
        },
        actions: {
          clear: "Clear",
        },
        success: "Order notes updated successfully",
      },
      completeAllItemsConfirmation: {
        title: "Complete all items for #{{order}}?",
        body: "Are you sure you would like to complete all items on this card for #{{order}}?",
        yes: "Complete",
        no: "Cancel",
      },
      moveBar: {
        moveForwardOne: "Move forward",
        moveForwardAll: "Move to first",
        moveBackwardOne: "Move backward",
        moveBackwardAll: "Move to last",
        done: "Done",
        cancel: "Cancel",
      },
    },
  },
  dispatchControl: {
    headers: {
      readyForCollection: "Ready for collection",
    },
    views: {
      DISPATCHING: "Dispatching",
      COMPLETED: "Completed",
    },
    actions: {
      dismiss: "Dismiss",
      call: "Call",
      complete: "Complete",
      recall: "Recall",
      markCollected: "Mark collected",
      confirm: "Confirm",
    },
  },
  dispatchCaller: {
    headers: {
      preparing: "Preparing now",
      readyForCollection: "Ready for collection",
    },
  },
  orderTileHeader: {
    prepBy: "Prep by:",
    completedAt: "Completed:",
  },
  paymentTypes: {
    header: "Payment types",
    title: "All payment types",
    subTypes: {
      manual: "Manual",
      credit_card: "Credit card",
      paynow_pos: "PayNow",
      grabpay_pos: "GrabPay",
      alipay: "AliPay",
      alipay_hk: "AliPay (HK)",
      wechatpay_pos: "WeChat Pay",
    },
    headers: {
      eCommerce: "E-commerce payment types",
      terminal: "Terminal payment types",
    },
    subHeaders: {
      eCommerce:
        "Select the payment types you want to enable on your Atlas Online and Atlas Scan To Order websites",
      terminal:
        "Select the payment types you want to enable on your Atlas POS and Atlas Kiosk terminals. Note that only integrated payments will be shown on your Atlas Kiosk.",
    },
    actions: {
      add: "Add manual payment type",
    },
    tableColumns: {
      identifier: "ID",
      label: "Name",
      paymentTypeImageUrl: "Thumbnail",
      enabled: "Enabled",
      openCashRegister: "Triggers cash register",
    },
    label: {
      category: {
        manual: "Manual",
        reserved: "Reserved",
        integrated: "Integrated",
      },
    },
    form: {
      success: {
        create: "Payment type created successfully",
        update: "Payment type updated successfully",
      },
      paymentTypeDetails: "Payment type details",
      labels: {
        identifier: "Identifier",
        label: "Label",
        paymentTypeImageUrl: "Thumbnail",
        paymentTypeImage: "Image",
        subType: "Sub-type",
        enabled: "Enabled?",
        openCashRegister: "Opens cash register?",
      },
      subtitle: {
        paymentTypeImage: "(PNG, JPG up to 10MB)",
      },
      prompts: {
        uploadAnotherFile: "Upload another file",
        uploadFile: "Upload file",
      },
    },
  },
  surcharges: {
    header: "Surcharges",
    title: "All surcharges",
    actions: {
      add: "Add surcharge",
    },
    tableColumns: {
      id: "ID",
      surchargeLabel: "Label",
      resourceableId: "Brand",
      outlet: "Outlet",
      brand: "Brand",
      fulfilmentTypes: "Fulfilment types",
      integerValue: "Surcharge amount",
      startDatetime: "Start date time",
      endDatetime: "End date time",
    },
    form: {
      success: {
        create: "Surcharge created successfully",
        update: "Surcharge updated successfully",
      },
      surchargeDetails: "Surcharge details",
      labels: {
        id: "ID",
        fulfilmentTypes: "Fulfilment types",
        surchargeLabel: "Label",
        resourceableId: "Brand",
        startDatetime: "Start date time",
        endDatetime: "End date time",
        outletId: "Outlet",
        integerValue: "Surcharge amount",
      },
    },
  },
  adjustmentReasons: {
    header: "Void reasons",
    actions: {
      add: "Add void reason",
    },
    tableColumns: {
      id: "ID",
      label: "Label",
    },
    form: {
      success: {
        create: "Void reason created successfully",
        update: "Void reason updated successfully",
      },
      adjustmentReasonDetails: "Void reason details",
      labels: {
        label: "Label",
      },
    },
  },
  outletBrands: {
    form: {
      header: "Outlet brand details",
      success: {
        update: "Outlet brand updated successfully",
        create: "Added brand to outlet successfully",
      },
      labels: {
        brandId: "Brand",
        outletId: "Outlet",
        createChannelLink: "Create link",
        channelLinked: "Channel linked",
        channelNotLinked: "Channel not linked",
        atlas: "Atlas Online",
        web: "Atlas Online",
        qrOrdering: "Takeaway QR code",
        qrUrl: "Takeaway QR code URL",
        tableQrOrdering: "Table QR codes",
        tableName: "Table name",
        tableQrUrl: "Table QR code URL",
        grab_food: "GrabFood",
        grabFoodMerchantId: "GrabFood merchant ID",
        grabFoodStockSyncEnabled: "GrabFood auto stock sync enabled?",
        grabFoodOrderSyncEnabled: "GrabFood order sync enabled?",
        foodpanda: "Foodpanda",
        foodpandaRemoteId: "Foodpanda remote ID",
        foodpandaMerchantId: "Foodpanda merchant ID",
        foodpandaStockSyncEnabled: "Foodpanda auto stock sync enabled?",
        foodpandaOrderSyncEnabled: "Foodpanda order sync enabled?",
        foodpandaExcludeUnavailableItemsFromCategories:
          "Exclude unavailable items from categories during menu sync?",
        deliveroo: "Deliveroo",
        deliverooBrandId: "Deliveroo brand ID",
        deliverooSiteId: "Deliveroo site ID",
        deliverooStockSyncEnabled: "Deliveroo auto stock sync enabled?",
        deliverooOrderSyncEnabled: "Deliveroo order sync enabled?",
      },
      subtitles: {
        grabFoodMerchantId: "The merchant ID on GrabFood to link to",
        grabFoodStockSyncEnabled:
          "Determines if stocks on GrabFood should automatically sync up every new day, or when an order is made",
        grabFoodOrderSyncEnabled:
          "Determines if orders on GrabFood should be synced to Atlas. Menu sync via Atlas MUST have been completed first.",
        foodpandaRemoteId:
          "The remote ID on Foodpanda to link to (for order sync)",
        foodpandaMerchantId:
          "The merchant ID (chain code) on Foodpanda to link to",
        foodpandaStockSyncEnabled:
          "Determines if stocks on Foodpanda should automatically sync up every new day, or when an order is made",
        foodpandaOrderSyncEnabled:
          "Determines if orders on Foodpanda should be synced to Atlas. Menu sync via Atlas MUST have been completed first.",
        deliverooBrandId: "The brand ID on Deliveroo to link to",
        deliverooSiteId: "The site ID on Deliveroo to link to",
        deliverooStockSyncEnabled:
          "Determines if stocks on Deliveroo should automatically sync up every new day, or when an order is made",
        deliverooOrderSyncEnabled:
          "Determines if orders on Deliveroo should be synced to Atlas. Menu sync via Atlas MUST have been completed first.",
      },
    },
    createChannelLinkConfirmationModal: {
      title:
        "Create channel link with {{channel}} for <strong>{{outlet}}</strong> (<strong>{{brand}}</strong>)?",
      channels: {
        Website: {
          null: "$t(outletBrands.createChannelLinkConfirmationModal.channels.Website.web)",
          web: "<strong class='text-atlas'>Atlas Online</strong>",
          scan_to_order:
            "<strong class='text-atlas'>Atlas Scan to Order</strong>",
          kiosk: "<strong class='text-atlas'>Atlas Kiosk</strong>",
        },
        WalkIn: {
          pos: "<strong class='text-atlas'>Point of Sale</strong>",
          scan_to_order:
            "<strong class='text-atlas'>Atlas Scan to Order</strong>",
          kiosk: "<strong class='text-atlas'>Atlas Kiosk</strong>",
        },
        ChannelApp: {
          grab_food: "<strong class='text-grab-food'>GrabFood</strong>",
          foodpanda: "<strong class='text-foodpanda'>Foodpanda</strong>",
          deliveroo: "<strong class='text-deliveroo'>Deliveroo</strong>",
        },
      },
      success: "Channel link created successfully",
    },
  },
  team: {
    header: "Team members",
    search: {
      label: "Search for a team member",
      placeholder: "Enter a name or email",
    },
    tableColumns: {
      name: "Team member",
      role: "Role",
      isPinAssigned: "Pin code",
      access: "Outlet/Brand access",
    },
    tableValues: {
      you: "You",
      admin: "Admin",
      fullAccess: "Full access",
      noAccess: "No access granted",
      allBrands: "All brands",
    },
    actions: {
      add: "Add to team",
      edit: "Update role",
      addOutletAccess: "Update outlet/brand access",
      setPinCode: "Update pin code",
      remove: "Remove team member",
    },
    roles: {
      server: "Server",
      cashier: "Cashier",
      manager: "Manager",
      finance: "Finance",
      admin: "Admin",
      super_admin: "Super admin",
      subtitles: {
        server: "- take orders but not allowed to collect any payment",
        cashier:
          "- take orders, collect payment and apply preset discounts/vouchers",
        manager:
          "- process refunds/voiding, open discounts/items, manage menu and outlet",
        finance: "- manage refunds, payment processors, and discounts/vouchers",
        admin: "- full access, including team and role management",
        super_admin: "- for Atlas team only",
      },
    },
    rolePermissions: {
      header: "Role permissions",
      accessLevels: {
        full: "Full access",
        partial: "Partial access",
        none: "No access",
      },
      tableHeaders: {
        permission: "Permission",
      },
      tableItems: {
        accessPos: "Point of Sale (POS) access",
        accessKds: "Kitchen Display System (KDS) access",
        stocks: "Stock management",
        chargeAndConfirmOrders: "Charge payments and confirm orders",
        presetDiscounts: "Apply preset discounts",
        openDiscounts: "Apply open discounts",
        openItems: "Add open-priced items",
        refunds: "Issue refunds",
        voidItems: "Void items in orders",
        editOrders: "Edit confirmed orders",
        cancelOrders: "Cancel confirmed orders",
        managePastOrders: "Manage past orders",
        insights: "Insights dashboards",
        outlets: "Outlet management",
        stations: "Outlet station management",
        menus: "Menu management",
        updateKds: "Kitchen Display System (KDS) configuration",
        posLayouts: "Point of Sale (POS) configuration",
        team: "Team and access management",
        marketing: "Marketing tools",
        adminOrders: "Create admin orders",
        paymentTypes: "Payment types management",
        voidReasons: "Adjustment reason management",
        aggregatorDiscountSettings: "Aggregator discount settings",
        payoutReports: "Payout reports",
        customers: "View customers",
        salesReport: "View sales reports",
        logisticsReport: "View logistics reports",
        logisticsReportNew: "View logistics reports",
        updatePosCartTable: "Update POS cart tables",
        editOrderStatus: "Edit order status",
        viewOrders: "View orders",
        printOrders: "Print order receipts",
        createKds: "Kitchen Display System (KDS) creation",
        connect: "Atlas connect apps",
        featureFlips: "Feature flip management",
        logs: "Activity logs",
        createOutlets: "Outlet creation",
        outletPaymentProcessors: "Outlet payment processor assignment",
        viewPaymentProcessors: "View payment processors",
        managePaymentProcessors: "Manage payment processors",
        viewBrands: "View brands",
        manageBrands: "Manage brands",
        printers: "Printer configuration",
        leadTimes: "Order lead time configuration",
        deliveryFees: "Delivery fee configuration",
        scanToOrder: "Scan to order management",
        channels: "Channel management",
      },
    },
    form: {
      header: {
        add: "Add team member",
        createAccount: "Enter new team member details",
        edit: "Edit {{accountEmail}}",
        pinCode: "Set up a pin code for {{accountEmail}}",
      },
      success: {
        create: "Team member added successfully",
        updateAccess: "Account access updated successfully",
        updatePinCode: "Pin code set successfully",
        destroy: "Team member removed successfully",
      },
      labels: {
        accountEmail: "Account email",
        role: "Role",
        name: "Name",
        email: "Email",
        mobileNumber: "Mobile number",
        password: "Password",
        showPassword: "Show password",
        autoGeneratePassword: "Generate a password?",
        pin: "6-digit pin code",
        pinConfirm: "Reconfirm 6-digit pin code",
      },
      subtitles: {
        role: "Limit access to certain features for different users.",
        viewRolePermissions: "View role permissions.",
      },
      placeholders: {
        accountEmail: "e.g. account@email.com",
      },
      errors: {
        accountExists: "Account is already added to the team",
        email: "Email is required",
        mobileNumber: "Mobile number is required",
        invalidMobileNumber: "Please enter a valid mobile number",
        pinFormat: "Pin code must be 6 digits (no letters)",
        pinNotMatch: "Pin codes do not match",
      },
    },
    removeOutletAccessConfirmation:
      "Remove access to <strong>{{outlet}}</strong> from <span class='font-bold whitespace-nowrap'>{{email}}</span>?",
    outletForm: {
      header: {
        create: "Assign outlet access for {{email}}",
        update: "Edit outlet access for {{email}}",
      },
      success: {
        create: "Outlet access created successfully",
        update: "Outlet access updated successfully",
        destroy: "Outlet access removed successfully",
      },
      labels: {
        outletId: "Assign outlet",
        brandIds: "Assign brands",
      },
      placeholders: {
        brandIds: "All brands",
      },
      helpText: {
        brandIds:
          "Leave blank to grant access to all brands for the selected outlet",
      },
    },
    removeTeamMemberConfirmation: {
      title: "Are you sure you want to remove access for {{email}}?",
    },
  },
  enableAudioModal: {
    title: "You need to enable audio on page refresh",
    acknowledge: "Okay",
  },
  imageInput: {
    addImageLabel: "Add image",
    replaceImageLabel: "Replace image",
    noSelectedImage: "No image uploaded",
  },
  timeRangePicker: {
    errors: {
      endTimeBeforeStartTime: "End time must be after start time",
    },
  },
  announcements: {
    labels: {
      title: "Title",
      message: "Message",
      type: "Type",
      storefront: "Storefront",
      channelLink: "Channel link",
      channel: "Channel",
      destionationUrl: "Destination URL",
      startTime: "Start time",
      endTime: "End time",
    },
    validation: {
      outletIds: "At least 1 outlet must be selected",
      storefrontIds: "At least 1 storefront must be selected",
      channelLinkIds: "At least 1 channel link must be selected",
      channelIds: "At least 1 channel must be selected",
    },
  },
  banners: {
    labels: {
      name: "Name",
      outletIds: "Outlet",
      storefrontId: "Storefront",
      channelId: "Channel",
      headline: "Headline",
      description: "Description",
      logoImage: "Logo / Title image",
      logoImageWidth: "Logo / Title image width",
      buttonText: "Button text",
      buttonUrl: "Button URL",
      desktopImage: "Desktop background image",
      desktopBackgroundImagePosition: "Desktop background position",
      mobileImage: "Mobile background image",
      mobileBackgroundImagePosition: "Mobile background position",
      desktopContentBoxPosition: "Desktop content box position",
      desktopContentAlignment: "Desktop content alignment",
      desktopSpaceAboveContent: "Desktop space above content",
      desktopSpaceBelowContent: "Desktop space below content",
      mobileContentAlignment: "Mobile content alignment",
      mobileSpaceAboveContent: "Mobile space above content",
      mobileSpaceBelowContent: "Mobile space below content",
      startsAt: "Start time",
      endsAt: "End time",
    },
    validation: {
      errors: {
        isActive: "This field must be checked",
        isAllOutlets: "This field must be checked",
        outletIds: "At least 1 outlet must be selected",
      },
    },
  },
  popUps: {
    labels: {
      name: "Name",
      type: "Type",
      startTime: "Start time",
      endTime: "End time",
      buttonText: "Button text",
      buttonUrl: "Button URL",
      audienceTargeting: "Audience targeting",
      triggerValue: "Trigger value",
      frequencyValue: "Frequency value",
      outlet: "Outlet",
      storefront: "Storefront",
      channel: "Channel",
      channelLink: "Channel link",
      desktopBackgroundImage: "Desktop background image",
      mobileBackgroundImage: "Mobile background image",
    },
    validation: {
      outletIds: "At least 1 outlet must be selected",
      storefrontIds: "At least 1 storefront must be selected",
      channelIds: "At least 1 channel must be selected",
      channelLinkIds: "At least 1 channel link must be selected",
    },
  },
  engagement: {
    header: "Engagement",
    announcements: {
      header: "Announcements",
      createButtonLabel: "Create new announcement",
      createButtonShortLabel: "Create announcement",
      tabs: {
        ChannelAnnouncement: "Channel announcements",
        StorefrontAnnouncement: "Website announcements",
      },
      type: {
        title: {
          ChannelAnnouncement: "Channels (New)",
          StorefrontAnnouncement: "Websites",
        },
        description: {
          ChannelAnnouncement: "This applies only to Scan to order websites.",
          StorefrontAnnouncement: "This applies only to Atlas Online websites.",
        },
      },
      searchPlaceholder: "Search any field",
      active: "Active",
      duration: "Duration",
      form: {
        newHeader: "Create new announcement",
        editHeader: "Edit announcement",
        duplicateHeader: "Duplicate announcement",
        titleLabel: "Title",
        titlePlaceholder: "Enter title here",
        messageLabel: "Message",
        messagePlaceholder: "Enter message here",
        outletLabel: "Outlet",
        outletPlaceholder:
          "Select one or more outlets to display this announcement",
        allOutlets: "All outlets",
        storefrontLabel: "Website",
        storefrontPlaceholder:
          "Select one or more storefronts to display this announcement",
        allStorefronts: "All websites",
        channelLinksLabel: "Channel links",
        channelLinksPlaceholder:
          "Select one or more outlets to display this announcement",
        allChannelLinks: "All channel links",
        channelLabel: "Channel",
        channelPlaceholder:
          "Select one or more channels to display this announcement",
        allChannels: "All channels",
        backgroundColorLabel: "Background color",
        backgroundColorPlaceholder: "eg. #000000",
        textColorLabel: "Text color",
        textColorPlaceholder: "eg. #FFFFFF",
        dismissibleLabel: "Dismissible?",
        dismissibleSubtitle: "Can a user close this announcement?",
        linkToAUrlLabel: "Link to a URL",
        linkToAUrlSubtitle:
          "Will clicking on this announcement link users to a page?",
        destinationUrlLabel: "Destination URL",
        destinationUrlPlaceholder: "https://atlas.kitchen",
        startLabel: "Start time",
        endLabel: "End time",
        activeBannerLabel: "Activate banner",
        activeBannerSubtitle:
          "An active banner will be displayed according to the start and end times.",
        previewTitle: "Announcement preview",
        success: {
          create: "Announcement created successfully",
          update: "Announcement updated successfully",
          delete: "Announcement deleted successfully",
        },
        aiPromptRequired: "Please enter your request",
        aiPrompt: {
          description:
            "Quickly create announcements by telling us what you want to announce. Our AI will fill up this page based on your prompt.",
          label: "PROMPT",
          placeholder: "10% off all orders above $150",
          characterCount: "{{count}}/150",
          generateButton: "Suggest",
          generatingButton: "Suggesting...",
        },
      },
      objectToDeleteLabel: {
        announcement: "Announcement: #{{label}}",
      },
    },
    popUps: {
      header: "Pop-ups",
      createButtonLabel: "Create new pop-up",
      createButtonShortLabel: "Create pop-up",
      searchPlaceholder: "Search any field",
      active: "Active",
      duration: "Duration",
      tabs: {
        StorefrontPopup: "Website pop-ups",
        ChannelPopup: "Channel pop-ups",
      },
      type: {
        title: {
          ChannelPopup: "Channels (New)",
          StorefrontPopup: "Websites",
        },
        description: {
          ChannelPopup: "This applies only to Scan to order websites.",
          StorefrontPopup: "This applies only to Atlas Online websites.",
        },
      },
      form: {
        newHeader: "Create new pop-up",
        editHeader: "Edit pop-up",
        duplicateHeader: "Duplicate pop-up",

        popUpDetailSectionLabel: "Pop-up details",
        screenVariantsSectionLabel: "Screen variants",
        previewSectionLabel: "Pop-up preview",

        nameLabel: "Name",
        namePlaceholder: "Enter name here",
        outletLabel: "Outlet",
        outletPlaceholder: "Select one or more outlets to display this pop-up",
        allOutlets: "All outlets",
        storefrontLabel: "Website",
        storefrontOverridden:
          "One or more storefronts assigned to this pop-up has been overridden by",
        storefrontPlaceholder:
          "Select one or more websites to display this pop-up",
        allStorefronts: "All websites",
        channelLabel: "Channel",
        channelPlaceholder: "Select a channel to display this banner",
        allChannels: "All channels",
        channelLinksLabel: "Channel links",
        channelLinksPlaceholder:
          "Select one or more outlets to display this announcement",
        allChannelLinks: "All channel links",
        startLabel: "Start time",
        endLabel: "End time",
        includeButtonLabel: "Include button",
        includeButtonSubtitle: " Include a call-to-action button on the pop up",
        buttonHorizontalAlignmentLabel: "Button horizontal alignment",
        buttonHorizontalAlignmentPlaceholder:
          "Select button horizontal alignment",
        buttonTextLabel: "Button text",
        buttonTextPlaceholder: "Enter button text here",
        buttonUrlLabel: "Button URL",
        buttonUrlPlaceholder: "Enter button URL here",
        buttonUrlHint: "Leave URL blank to dismiss pop-up on click",
        audienceTargetingLabel: "Audience targeting",
        audienceTargetingPlaceholder: "Select audience targeting",
        triggerLabel: "Trigger",
        triggerSubtitle: "When should this pop-up appear?",
        triggerArrivalLabel: "When a visitor arrives on the page.",
        triggerDelayLabel1: "After a delay of",
        triggerDelayLabel2: "seconds.",
        triggerPageExitLabel: "When a visitor tries to exit the page.",
        triggerPageScrollLabel1: "When a visitor scrolls",
        triggerPageScrollLabel2: "percent of the way down the page.",

        frequencyLabel: "Frequency",
        frequencySubtitle: "How often will this pop-up appear?",
        frequencyEqual1Label:
          "Show only once per visitor, until they close and relaunch their browser.",
        frequencyEvery1Label: "Show on every visit.",
        frequencyEqualNLabel: "Show only on visit number",
        frequencyEveryNLabel1: "Show every",
        frequencyEveryNLabel2: "visits.",
        frequencyGreaterThan1Label:
          "Don’t show on first visit, show on all return visits.",

        activePopUpLabel: "Activate pop-up",
        activePopUpSubtitle:
          "An active pop-up will be displayed according to the start and end times below (if any).",

        screenVariants: {
          desktop: "Desktop",
          mobile: "Mobile",
          backgroundImageLabel: "Background image",
          backgroundImageDesktopHint:
            "* Please upload a 1600px (width) by 1200px (height) image",
          backgroundImageMobileHint:
            "* Please upload a 800px (width) by 1000px (height) image",
        },

        success: {
          create: "Pop-up created successfully",
          update: "Pop-up updated successfully",
          delete: "Pop-up deleted successfully",
        },
      },
      objectToDeleteLabel: {
        popUp: "Pop-up: #{{label}}",
      },
    },
    banners: {
      header: "Banners",
      createButtonLabel: "Create new banner",
      createButtonShortLabel: "Create banner",
      searchPlaceholder: "Search any field",
      active: "Active",
      duration: "Duration",
      default: "Default",
      tabs: {
        StorefrontBanner: "Website banners",
        ChannelBanner: "Channel banners",
      },
      type: {
        title: {
          ChannelBanner: "Channels (New)",
          StorefrontBanner: "Websites",
        },
        description: {
          ChannelBanner: "This applies only to Scan to order websites.",
          StorefrontBanner: "This applies only to Atlas Online websites.",
        },
      },
      form: {
        newHeader: "Create new banner",
        editHeader: "Edit banner",
        duplicateHeader: "Duplicate banner",

        bannerDetailSectionLabel: "Banner details",
        screenVariantsSectionLabel: "Screen variants",
        previewSectionLabel: "Banner preview",

        nameLabel: "Name",
        namePlaceholder: "Enter name here",
        outletLabel: "Outlet",
        outletPlaceholder: "Select one or more outlets to display this banner",
        allOutlets: "All outlets",
        storefrontLabel: "Website",
        storefrontOverridden:
          "One or more websites assigned to this banner has been overridden by",
        storefrontPlaceholder:
          "Select one or more websites to display this banner",
        channelLabel: "Channel",
        channelPlaceholder: "Select a channel to display this banner",
        allStorefronts: "All websites",
        allChannelLinks: "All channel links",
        headlineLabel: "Headline",
        headlinePlaceholder: "Enter headline here",
        descriptionLabel: "Description",
        descriptionPlaceholder: "Enter description here",
        logoTitleImageLabel: "Logo / Title image",
        logoTitleImageLabelHint:
          "* Minimum recommended size is 100px (width) by 100px (height)",
        logoTitleImageWidthLabel: "Logo / Title image width",
        logoTitleImageWidthPlaceholder: "Enter a value",

        startLabel: "Start time",
        endLabel: "End time",
        includeButtonLabel: "Include button",
        includeButtonSubtitle: " Include a call-to-action button on the banner",
        buttonTextLabel: "Button text",
        buttonTextPlaceholder: "Enter button text here",
        buttonUrlLabel: "Button URL",
        buttonUrlPlaceholder: "Enter button URL here",

        activeBannerLabel: "Activate banner",
        activeBannerSubtitle:
          "An active banner will be displayed according to the start and end times below (if any).",
        defaultBannerLabel: "Set as default",
        defaultBannerSubtitle:
          "The default banner is displayed when there are no other banners active.",

        screenVariants: {
          desktop: "Desktop",
          mobile: "Mobile",
          backgroundImageLabel: "Background image",
          backgroundImageDesktopHint:
            "* Minimum recommended size is 1400px (width) by 720px (height)",
          backgroundImageMobileHint:
            "* Minimum recommended size is 640px (width) by 420px (height)",
          backgroundPositionLabel: "Background position",
          backgroundPositionPlaceholder: "Select background position",
        },

        preview: {
          desktop: "Desktop",
          mobile: "Mobile",
          contentBoxWidthLabel: "Content box width",
          contentBoxWidthPlaceholder: "Select content box width",
          contentBoxPositionLabel: "Content box position",
          contentBoxPositionPlaceholder: "Select content box position",
          contentAlignmentLabel: "Content alignment",
          contentAlignmentPlaceholder: "Select content alignment",
          spaceAboveContentLabel: "Space above content",
          spaceAboveContentPlaceholder: "Select space above content",
          spaceBelowContentLabel: "Space below content",
          spaceBelowContentPlaceholder: "Select space below content",
          hideLogoDesktopLabel: "Hide logo for desktop",
          hideLogoDesktopSubtitle:
            "Hide the logo image when viewed on larger screens",
          hideLogoMobileLabel: "Hide logo for mobile",
          hideLogoMobileSubtitle:
            "Hide the logo image when viewed on smaller screens",
        },

        success: {
          create: "Banner created successfully",
          update: "Banner updated successfully",
          delete: "Banner deleted successfully",
        },

        defaultBannerOverridden:
          'Would you like to replace your {{storefrontName}} default banner "{{oldBannerName}}" with "{{newBannerName}}"?',
      },
      objectToDeleteLabel: {
        banner: "Banner: #{{label}}",
      },
    },
  },
  reloadPrompt: {
    optional: {
      title: "New update available",
      subTitle: "To ensure the best experience, please refresh this page.",
      okText: "Reload",
      cancelText: "Dismiss",
    },
    mandatory: {
      title: "Update required",
      subTitle:
        "To ensure that the application runs smoothly, please update now.",
      okText: "Reload",
    },
  },
  crossSellGroups: {
    header: "Cross-sell",
    createButtonLabel: "Create new cross-sell group",
    tableHeaders: {
      name: "Name",
      description: "Description",
      triggerProductCount: "Number of trigger products",
      crossSellProductCount: "Number of cross-sell products",
      active: "Active",
    },
    xMoreProducts: "and {{count}} more products",
    objectToDeleteLabel: {
      crossSellGroup: "Cross-sell group: #{{id}}",
    },
    autoFill: "Auto-fill",
    suggest: "Suggest",
    form: {
      newHeader: "Create cross-sell group",
      editHeader: "Edit cross-sell group",

      crossSellGroupDetailSectionLabel: "Cross-sell group details",
      nameLabel: "Name",
      namePlaceholder: "Enter name here",
      idLabel: "ID",
      idPlaceholder: "Enter ID here",
      descriptionLabel: "Description",
      descriptionPlaceholder: "Enter a short description",
      activeLabel: "Activate",
      activeSubtitle: "Activate this cross-sell group",

      triggerProductsSectionLabel: "Select trigger products",
      triggerProductsSectionSubLabel:
        "Select products to trigger a cross-sell via a pop up and in their shopping cart when a diner adds it to their basket.",
      triggerProductsLabel: "Choose products to trigger cross-sell",
      triggerProductsPlaceholder: "No trigger products selected yet",
      chooseTriggerProductsButton: "Choose products to trigger cross-sell",

      promotedProductsSectionLabel:
        "Select products to promote when cross-selling",
      promotedProductsSectionSubLabel:
        "Select products to be promoted to the diner. We recommend a maximum of 3 items for better conversion rates.",
      choosePromotedProductsButton: "Choose products to cross-sell",
      promotedProductsTableHeaders: {
        order: "Order",
        thumbnail: "Thumbnail",
        crossSellProducts: "Cross-sell products",
      },
      selectProductsModal: {
        triggerProductsTitle: "Choose products to trigger cross-sell",
        triggerProductsSubtitle:
          "Choose one or more products to trigger a cross-sell.",
        promotedProductsTitle: "Choose products to cross-sell",
        promotedProductsSubtitle:
          "Choose one or more products to promote as a cross-sell.",
      },
      conflictedProductModal: {
        title: "Reassign cross-sell group",
        content:
          "The following products already triggered <strong>{{oldCrossSellGroupName}}</strong>. Do you want to reassign it to trigger <strong>{{newCrossSellGroupName}}</strong> instead?",
        okText: "Reassign",
        cancelText: "Keep in existing group",
      },
      swapToTriggerProductModal: {
        title: "Product already promoted as a cross-sell",
        content:
          'The following products are already promoted as a cross-sell for <strong>"{{crossSellGroupName}}"</strong>. Do you want to swap them into a trigger product instead?',
        okText: "Swap to trigger product",
        cancelText: "Keep existing assignment",
      },
      swapToPromotedProductModal: {
        title: "Product already used as a trigger",
        content:
          'The following products are already used as a trigger for <strong>"{{crossSellGroupName}}"</strong>. Do you want to swap them into a promoted product instead?',
        okText: "Swap to promoted product",
        cancelText: "Keep existing assignment",
      },
      generateAISuggestions: "Generate AI Suggestions",
      aiPromptRequired: "Please enter your request",
      aiPrompt: {
        description:
          "Quickly create cross-sell groups by telling us what you want to sell. Our AI will fill up this page based on your prompt.",
        label: "Prompt",
        placeholder: "Upsell wine with every main course",
        characterCount: "{{count}} characters",
        generateButton: "Suggest",
        generatingButton: "Suggesting...",
      },
      generateSuccess: "Suggestions generated successfully!",
      generateError: "Error generating suggestions. Please try again.",
    },
    success: {
      create: "Cross-sell group created successfully",
      update: "Cross-sell group update successfully",
      destroy: "Cross-sell group deleted successfully",
    },
  },
  commonProductEditor: {
    add: "Add",
    update: "Update",
    remove: "Remove",
    reprint: "Reprint",
    fire: "Fire",
    hold: "Hold",
    void: "Void",
    cancelVoid: "Cancel void",
    dishNotes: "Dish notes",
    openItem: {
      title: "Open item",
      labels: {
        notes: "Item description",
        unitPriceFractionalOverride: "Item price",
      },
      editingDisabled: "Editing of open item is disabled here.",
    },
    labels: {
      notes: "Special instructions",
      quantity: "Quantity",
    },
    placeholders: {
      notes: "Add notes",
    },
    confirmationModal: {
      fireTitle: "Fire item?",
      holdTitle: "Hold item?",
    },
    modifierGroups: {
      modifiers: {
        soldOut: "Sold out",
        unsupportedNestingLevel:
          "Unsupported nesting - product contains third-level modifiers.",
      },
    },
  },
  voidLineItemModal: {
    title: "Void {{lineItem}}",
    actions: {
      void: "Void items",
    },
    success: "Order item voided successfully",
    labels: {
      adjustedQuantity: "Select quantity to void",
      adjustmentReasonId: "Select reason for voiding",
    },
    error: {
      invalidAdjustmentReason: "Invalid void reason",
    },
  },
  cancelVoidConfirmationModal: {
    title: "Cancel void for {{lineItem}}?",
    success: "Void cancelled successfully",
  },
  tables: {
    header: "Tables",
    createButtonLabel: "Create new table",
    form: {
      newHeader: "Create new table",
      editHeader: "Edit table",
      nameLabel: "Name",
      namePlaceholder: "Enter name here",
      areaLabel: "Area",
      areaPlaceholder: "Select one area to display this table",
      displayOrderLabel: "Display order",
      displayOrderPlaceholder: "Enter display order here",
      success: {
        create: "Table created successfully",
        update: "Table updated successfully",
        delete: "Table deleted successfully",
      },
    },
    objectToDeleteLabel: {
      table: "Table: #{{label}}",
    },
  },
  areas: {
    header: "Tables and areas",
    createButtonLabel: "Create new area",
    form: {
      newHeader: "Create new area",
      editHeader: "Edit area",
      nameLabel: "Area name",
      namePlaceholder: "Enter name here",
      displayOrderLabel: "Display order",
      displayOrderPlaceholder: "Enter display order here",
      backToAreas: "Back to areas",
      colorLabel: "POS tile colour",
      colorPlaceholder: "Select POS tile colour",
      tablesSections: {
        title: "Tables",
        addTable: "Add table",
        actionsMenu: {
          rename: "Rename",
          drawOnFloorPlan: "Draw on floor plan",
          drawFromFloorPlan: "Remove from floor plan",
          delete: "Delete",
          resize: "Move and resize",
        },
      },
      success: {
        create: "Area created successfully",
        update: "Area updated successfully",
        delete: "Area deleted successfully",
        sortTables: "Tables sorted successfully",
      },
    },
    tableItem: {
      notDrawn: "Not drawn",
    },
    floorPlan: {
      drawBlock: "Draw block",
      drawTable: "Draw table",
      drawingShapes: {
        rectangle: "Rectangle",
        circle: "Circle",
        polygon: "Polygon",
      },
      drawingGuidelineMessage:
        "Use your cursor to draw your table on the grid.",
      resizingGuidelineMessage: "Drag and resize the block to reposition it.",
      removeTableFromFloorPlanConfirmation:
        "Do you really want to remove table #{{tableId}} from your floor plan? You can draw the table again from your table list.",
      deleteTableConfirmation:
        "Do you really want to permanently delete table #{{tableId}}? This action cannot be undone.",
    },
    tableCountLabel: "Number of tables",
    objectToDeleteLabel: {
      area: "Area: #{{label}}",
    },
  },
  merchant: {
    header: "Merchant",
    config: {
      header: "Merchant config",
      labels: {
        merchantId: "Merchant ID",
        merchantIdentifier: "Merchant identifier",
        taxRates: "Tax rates",
        taxInclusivePrices: "Tax-inclusive prices?",
        serviceChargeRate: "Service charge rate",
        serviceChargePlatforms: "Platforms to apply service charge",
        isAutoPrintEnabled: "Auto-print enabled?",
        autoPrintLeadTime: "Auto print lead time",
      },
      subtitles: {
        serviceChargeRate: "This rate applies for dine-in orders only.",
        serviceChargePlatforms: "This rate applies for dine-in orders only.",
      },
      values: {
        taxRates: "{{key}}: {{value}}%",
      },
    },
  },
  channels: {
    header: "Channels",
    type: {
      Website: {
        label: "Website",
        null: "$t(channels.type.Website.web)",
        web: "Atlas Online",
        scan_to_order: "Atlas Scan to Order (Legacy)",
        kiosk: "Atlas Kiosk (Legacy)",
      },
      WalkIn: {
        label: "Walk-in",
        null: "$t(channels.type.WalkIn.pos)",
        pos: "Atlas Point of Sale",
        kiosk: "Atlas Kiosk",
        scan_to_order: "Atlas Scan to Order",
      },
      ChannelApp: {
        label: "ChannelApp",
        grab_food: "GrabFood",
        foodpanda: "Foodpanda",
        deliveroo: "Deliveroo",
      },
      atlas: "Atlas Online",
      web: "Atlas Online",
      scan_to_order: "Atlas Scan to Order",
      kiosk: "Atlas Kiosk",
      pos: "Atlas POS",
      grab_food: "GrabFood",
      foodpanda: "Foodpanda",
      deliveroo: "Deliveroo",
    },
    actions: {
      add: "Add channel",
    },
    tableColumns: {
      label: "Name",
      enabled: "Enabled?",
    },
    form: {
      success: {
        create: "Channel created successfully",
        update: "Channel updated successfully",
      },
      sections: {
        channelSection: "Channel details",
      },
      labels: {
        label: "Name",
        type: "Channel type",
        subType: "Channel sub-type",
        enabled: "Enabled?",
      },
      placeholders: {
        subType: "Please select a type first",
      },
    },
  },
  channelLinks: {
    header: "Channel links",
    actions: {
      add: "Add channel link",
    },
    tableColumns: {
      channel: "Channel",
      outlet: "Outlet",
      brand: "Brand",
      menus: "Menus",
    },
    form: {
      success: {
        create: "Config created successfully",
        update: "Config updated successfully",
        generateDynamicQr: "Dynamic QR code generated successfully",
      },
      sections: {
        headers: {
          new: "New channel link",
          generalSettings: "General details",
          splashSettings: "Splash page settings",
        },
      },
      labels: {
        channelId: "Channel",
        outletId: "Outlet",
        brandId: "Brand",
        menus: "Menus",
        thumbnailPlaceholderUrl: "Thumbnail placeholder URL",
        faviconUrl: "Favicon URL",
        googleFontUrl: "Google Font URL",
        showFulfilmentSelectorSplash: "Show fulfilment type selector splash",
        splashImageUrl: "Splash image URL",
        showSplashForTableQr:
          "Show fulfilment type selector splash for table QR only",
        dinerNameConfiguration: "Name collection",
        dinerContactNumberConfiguration: "Contact number collection",
        dinerEmailConfiguration: "Email collection",
        dinerConfigurationHidden: "Hidden",
        dinerConfigurationOptional: "Optional",
        dinerConfigurationRequired: "Required",
        authenticationEnabled: "Atlas ID authentication enabled",
        dineInAndTakeaway: "Dine-in and takeaway",
        dineInOnly: "Dine-in only",
        takeawayOnly: "Takeaway only",
      },
      placeholders: {
        outletId: "Please select a channel first",
      },
      subtitles: {
        showFulfilmentSelectorSplash:
          "Allow customers to choose their fulfilment type when loading for the first time",
        showSplashForTableQr:
          "Allow customers to choose between takeaway or dine-in for table QR, but not for takeaway QR (will always be takeaway)",
        authenticationEnabled:
          "Allow diners to log in and sign up via OTP on this channel",
        dineInAndTakeaway:
          "Allow diners to choose between a dine-in or takeaway order",
        dineInOnly: "All kiosk orders will be for dine-in",
        takeawayOnly: "All kiosk orders will be for takeaway",
      },
      helpText: {
        authenticationEnabled:
          "Email cannot be hidden when Atlas ID authentication is enabled",
      },
    },
  },
  scanToOrderChannelLinks: {
    header: "Scan to order",
    actions: {
      add: "Set up new scan to order outlet",
      create: "Proceed to configuration",
    },
    form: {
      sections: {
        headers: {
          new: "Set up new scan to order outlet",
          qrSettings: "QR code type",
          paymentSettings: "Payment",
          dinerSettings: "Diner information collection",
          menuSettings: "Menu settings",
          confirmationSettings: "Confirmation page settings",
          recommendationSettings: "Chef's recommendations",
          callStaffSettings: "Call staff settings",
        },
      },
      labels: {
        staticTableQr: "Permanent QR codes to pay before submitting order",
        dynamicTableQr: "Dynamic QR codes to pay at the end of meal",
        displayStaticQrDisabled: "Please save your changes first.",
        generateDynamicQrDisabled: "Please save your changes first.",
        outletQr: "Takeaway QR code",
        copyQrUrl: "Copy URL",
        copied: "Copied!",
        downloadQrCode: "Download QR",
        tableQr: "Table QR codes",
        downloadAllQrCodes: "Download all QR codes",
        holdOnSentToKitchen: "Hold orders",
        addPaddingAbove: "Add space above QR strip",
        dynamicQr: "Generate dynamic QR code",
        selectDynamicQr: "Select a QR code to generate",
        generateDynamicQr: "Generate QR code",
        noDynamicQrGenerated: "No QR code generated yet.",
        payAtCounter: "Allow pay at counter option",
        callForBill: "Allow calling for bill",
        payThroughPhone: "Allow pay through phone option",
        enableOrderNotes: "Enable order notes",
        enableItemInstructions: "Enable item instructions",
        showTwoColumnProducts: "Show 2 column products layout",
        vendingMachineMode: "Vending machine mode",
        confirmationCustomMessage: "Custom message on confirmation page",
        confirmationSuccessConfetti: "Show success confetti?",
        recommendationEnabled: "Enable recommendations module for diners",
        recommendationProducts: "Products",
        callStaffEnabled: "Allow diners to call staff from their phone",
        callStaffNotifyMerchantPortal:
          "Show notification on merchant portal and POS when diner requests assistance",
        callStaffPrintReceiptNotification:
          "Print a short receipt notification when diner requests assistance",
        callStaffPrinters: "Choose printers",
        defaultCallStaffOptions: {
          serviceRequest: "Request for service",
          cutleryRequest: "Need more cutlery",
          recommendationsRequest: "I need recommendations",
          photoRequest: "Help take a picture for me",
        },
        defaultNewCallStaffOption: "New service request {{counter}}",
      },
      subtitles: {
        staticTableQr:
          "Stick permanent QR codes to your tables. Your diners will have to pay before their orders are sent to the kitchen.",
        dynamicTableQr:
          "Print a new QR code for each table seating. Your diners will be able to send orders to the kitchen and only pay at the end of their meal.",
        outletQr:
          "Display the following QR code to allow diners to place takeaway orders.",
        tableQr: "View and download QR codes for each of your tables.",
        holdOnSentToKitchen:
          "Instead of firing items ordered by diners, hold them for the staff to fire instead.",
        addPaddingAbove:
          "Adds additional space above the QR code print-out so it can be clipped from the top",
        dynamicQr:
          "Use the tool below to select a QR code to be generated for preview.",
        payAtCounter:
          "Diners can walk to the counter to pay before confirming their orders.",
        callForBill:
          "Diners can ask a staff to bring their bill to the table for payment.",
        payThroughPhone:
          "Diners can self-checkout and pay for their order on their phone.",
        enableOrderNotes:
          "Diners will be able to add notes regarding their order",
        enableItemInstructions:
          "Diners will be able to add custom instructions for products",
        showTwoColumnProducts:
          "Non-highlighted products will show up 2 per row",
        vendingMachineMode: "Checkout only 1 item at a time",
        confirmationSuccessConfetti:
          "Show a confetti animation when order is completed",
        recommendationEnabled:
          "Choose up to 5 products from your menus to present it to your diners as<br/>We suggest you to upload videos for the products so that our recommendation system will present them better to the diners!",
        callStaffEnabled:
          "Define up to 5 options for diners to indicate service requests to their table. Here are a few examples for you - “Request for service”, “Refill water please”, “Need more cutlery”, “I need recommendations”, “Help take a picture for me”",
        callStaffNotifyMerchantPortal:
          "Diners can keep place an order first, then have the meal and pay at the end of the meal when they are ready. Take note that this comes with potential cases where diners might forget to pay and leave the outlet.",
      },
      tooltips: {
        recommendationEnabled:
          "<ul class='ml-4' style='list-style:disc'><li>If product contains one video, we will present that video only to the diners chef recommended view. The photos won’t be used</li><li>If product contains more than one video, we will play both videos, one after the other.</li><li>If product contains only images, we will show the images as a slideshow, one after the other.</li></ul>",
      },
      values: {
        selectDynamicQr: {
          pickup: "Takeaway",
          dine_in: "No table",
        },
      },
      tableQrTableColumns: {
        area: "Areas in outlet",
        tables: "Number of table QR codes",
      },
      editAreaFormModal: {
        header: "Table QR codes",
      },
      chooseRecommendationProductsHeader:
        "Choose up to {{count}} more recommendation product",
      chooseRecommendationProductsHeader_plural:
        "Choose up to {{count}} more recommendation products",
      selectedProductCount: "{{count}} item selected",
      selectedProductCount_plural: "{{count}} items selected",
    },
  },
  kioskChannelLinks: {
    header: "Kiosk",
    actions: {
      add: "Set up new kiosk outlet",
      create: "Proceed to configuration",
    },
    form: {
      sections: {
        headers: {
          new: "Set up new kiosk outlet",
          kioskSettings: "Kiosk",
          paymentSettings: "Payment",
          dinerSettings: "Diner information collection",
          confirmationSettings: "Confirmation page settings",
          recommendationSettings: "Chef's recommendations",
        },
      },
      labels: {
        splashImageUrl: "Splash image URL",
        payThroughKiosk: "Allow pay through Kiosk itself",
      },
      subtitles: {
        payThroughKiosk: "Allow payments to be made through the Kiosk.",
      },
      tooltips: {
        recommendationEnabled:
          "<ul class='ml-4' style='list-style:disc'><li>If product contains one video, we will present that video only to the diners chef recommended view. The photos won’t be used</li><li>If product contains more than one video, we will play both videos, one after the other.</li><li>If product contains only images, we will show the images as a slideshow, one after the other.</li></ul>",
      },
      chooseRecommendationProductsHeader:
        "Choose up to {{count}} more recommendation product",
      chooseRecommendationProductsHeader_plural:
        "Choose up to {{count}} more recommendation products",
      selectedProductCount: "{{count}} item selected",
      selectedProductCount_plural: "{{count}} items selected",
    },
  },
  adyenStores: {
    header: "Adyen stores",
    actions: {
      add: "Add store",
    },
    tableColumns: {
      storeName: "Store name",
      shopperInteraction: "Shopper interaction",
      status: "Status",
      webUrl: "Web URL",
      categoryCode: "Category code",
      store: "Store ID",
    },
    form: {
      adyenStoreDetails: "Adyen store details",
      labels: {
        adyenStoreId: "Adyen account holder code",
        storeName: "Store name",
        storeHouseNumber: "Store house number",
        postalCode: "Postal code",
        outlet: "Outlet",
        shopperInteractionType: "Shopper interaction type",
        url: "Web URL",
        merchantCategoryCode: "Merchant category code",
      },
      shopperInteractionType: {
        POS: "POS",
        Ecommerce: "E-commerce",
      },
      merchantIdentifier: "Merchant identifier: {{merchantId}}",
      categoryCodeFooter: "*5814 is for fast food restaurants",
      merchantCategoryCodeList: "Merchant category code list",
      success: {
        create: "Adyen store is created successfully",
      },
    },
  },
  permissions: {
    unauthorised:
      "Your account does not have sufficient permissions to perform this action.",
  },
};
